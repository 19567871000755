//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2009, 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

TSCUtils.subscribe("ajaxRequestInitiated", incrementNumAjaxRequest);
TSCUtils.subscribe("ajaxRequestCompleted", decrementNumAjaxRequest);
TSCUtils.subscribe("ajaxRequestCompleted", initializeInactivityWarning);

/**
 * @fileOverview This file provides the common functions which are specific to the Aurora store.
 * This JavaScript file is used by StoreCommonUtilities.jspf.
 */
// Handle Order error.
TSCUtils.subscribe("OrderError", function (errorObj){
    if(errorObj.errorCode == 'CMN4512I'){
        //CMN4512I is OrderLocked error. So refresh miniCart.
        setDeleteCartCookie();
        //Since cart cookie is deleted, we don't need to pass currency/lang. It will be fetched from server
        loadMiniCart(null,null);
    }
});

/** This variable indicates whether the dropdown is shown or not. */
var showDropdown = false;

/** This variable stores the current dropdown dialog element. */
var dropDownDlg = null;

/** This variable stores the current product added dropdown dialog element. */
var productAddedDropDownDlg = null;

/** This variable is used to store the width of the mini shopping cart on page load. It is used when shopper's browser is IE6. */
var originalMiniCartWidth = 0;

/** This variable indicates whether the browser used is Internet Explorer or not. */
var isIE = (document.all) ? true : false;

/** Initializes the variable to false. **/
    var correctBrowser = false;

/**
 * This variable indicates whether a request has been submitted or not.
 * The value is initialized to true and resets to false on full page load.
 */
var requestSubmitted = true;

/**
 * This variable stores the id of the element (ex: button/link) which the user clicked.
 * This id is set when the user clicks an element which triggers an Ajax request.
 */
var currentId = "";
/**
 * This variable stores the parent id of the element (ex: button/link) which the user clicked.
 * This id is set when the user clicks an element which triggers an Ajax request.
 */
var parentEleId = "";
/**
 * This variable keeps track of the number of active ajax requests currently running on the page
 * The value is initialized to 0.
 */
var numAjaxRequests = 0,

widgetsList = [];

/**
 * Variable to save whether a tab or shift-tab was pressed
 */
var tabPressed = false;
/**
 * Variable to check neighbor club check box
 */
var checkFlag=0;

/** This variable is used to keep track of the quick info/compare touch events */
var currentPopup = '';

/** This variable indicates whether Android is used or not */
var android = null;

/** This variable indicates whether iOS is used or not */
var ios = null;
/**
 * Div element for getting HTML entities of respective encoded special characters
 */
var divElement = document.createElement('div');
/**
 * Regex for identifying restricted special characters from allowed character list
 */
var restrictedCharRegex = /[&<>/'"]/gi;
/**
 * Regex for identifying restricted special characters from allowed character list in address fields
 */
var restrictedAddressCharRegex = /[^A-Z0-9- ]/gi;
/**
 * Regex for identifying emojis
 */
var smileyRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
/**
 * Regex for finding characters entered outside following range ( 0-9,A-z,All special characters that can be entered via key board )
 * This condition is used for finding smileys/Phonetic characters/Symbols which are copy pasted.
 */
var matchRestrictedSymbolsRegex  = /[^\x20-\x7E]/gi;
/*
 * variable to hold the progressbar status. 
 */
var progressBarCloseable = false;
/*
 * variable to hold js source names to be loaded asynchronously.
 */
var asyncJavascriptSource = [];
var progressBarTimeout;
//is FBT Flow
var isFBTatc = false;
/*
 * variable to hold feature flag value for New Sign In page.
 */
var isNewSignInPageEnabled = false;
/*
 * This variable stores whether current user is remembered user.
 */
var isRememberedUser = false;

/**
 * Method to decode string containing special characters in encoded format
 */
function decodeSpecialCharacters(str) {
	if(str && typeof str === 'string') {
		this.divElement.innerHTML = str;
		str = this.divElement.textContent;
		this.divElement.textContent = '';
	}
	return str;
}

/**
 * This variable contains the cookie domain for current store cookies.
 * Modify the cookie domain once here and it will be applied everywhere else for the particular store.
 */
var cookieDomain = "";

/** Base Text Direction Preference */
var _BTD_PREFS = "WC_Base_Text_Direction",

detectedLocale = Utils.getLocale();

/**
 * Initialize the client side inactivity warning dialog, this function is called at every page load and at
 * every the time when ajax request completed.  Be default, 30 seconds before the session timeout, a dialog
 * will popup and display a warning to let the user to extend the time.  The timing of when the dialog
 * will be displayed can be modified with "inactivityWarningDialogBuffer" variable in CommonJSToInclude.jspf
 */
function initializeInactivityWarning() {

    // only set timer if user is not guest, in a full-auth session, and is able to retrieve inactivityTimeout from server
    if (storeUserType != "G" && inactivityTimeout != 0 && document.cookie.indexOf("WC_USERACTIVITY_") > 0) {
        // Reset the inactivity timer dialog
        if (inactivityTimeoutTracker != null) {
            clearTimeout(inactivityTimeoutTracker);
        }

        // setup the inactivity timout tracker
        inactivityTimeoutTracker = setTimeout(showInactivityWarningDialog, inactivityTimeout - inactivityWarningDialogBuffer);
    }
}

/**
 * Show the inactivity warning dialog, the dialog will be closed in 20 seconds.  The timing of when the dialog
 * will be closed can be modified with "inactivityWarningDialogDisplayTimer" variable in CommonJSToInclude.jspf
 */
function showInactivityWarningDialog() {
    $("#inactivityWarningPopup").modal();
    if (dialogTimeoutTracker != null) {
        clearTimeout(dialogTimeoutTracker);
    }
    dialogTimeoutTracker = setTimeout(hideInactivityWarningDialog, inactivityWarningDialogDisplayTimer);
}

/**
 * Hide the inactivity warning dialog
 */
function hideInactivityWarningDialog() {
    $("#inactivityWarningPopup").modal('hide');
}

/**
 * validates the logon id and password fields and submits the form
 * @param form
 *
 */
function validateandSubmitLogonFields(form , isWishlistReskin) {
	clearErrorMessages(form);
	$('#account-lockout-error-message').hide();
	$('#logonIdLabel').removeClass('hide');
	$('#logonPasswordLabel').removeClass('hide');
	$("#UserSignInErrorMessage").html('');	
	var isNewSignInPageFlow = false;
	
	if(isNewSignInPageEnabled && $("#pageName").val() == "SignInRedesign"){  
		isNewSignInPageFlow = true;
	}  
	if(document.getElementById('logonErrorMessage') != null){
		if(document.getElementById('logonErrorMessage').textContent != ""){
		document.getElementById('logonErrorMessage').textContent="";
		}
	}
	if(!isNewSignInPageFlow){
		if(form.logonId != null) {
			form.logonId.value = trim(form.logonId.value);
		} 
	
		if(form.logonId == null || form.logonId.value == ""){
			$('#logonIdLabel').addClass('hide');
			formatValidationErrorMessage(form.logonId,MessageHelper.messages["ERROR_LogonIdEmpty"]);
			return;
	    } 
		if(form.logonPassword == null || form.logonPassword.value == ""){
			$('#logonPasswordLabel').addClass('hide');
			formatValidationErrorMessage(form.logonPassword,MessageHelper.messages["ERROR_PasswordEmpty"]);
			return;
		} 
	
		if("Logon"==form.id){
			if(Logon.logonId.value!=null){
				try{
				utag.link({'event_type' : 'registration', 'customer_id' : Logon.logonId.value,'customer_email' : Logon.logonId.value});
				} catch(e){
					console.log('exception in handling utag'+e.stack);
				}
			}
		}
		if("Logon_wishList"==form.id){
			if(Logon_wishList.logonId.value!=null){
				try{
				utag.link({'event_type' : 'registration', 'customer_id' : Logon_wishList.logonId.value,'customer_email' : Logon_wishList.logonId.value});
				} catch(e){
					console.log('exception in handling utag'+e.stack);
				}
			}
		}
	}

    var pageParam = "";
    if(document.getElementById('pageParam') != null) {
        pageParam = document.getElementById('pageParam').value;
    }
    if(isNewSignInPageFlow){    
    	pageParam	= 'deliveryPickup';
    }
    var params = {};
    if(isWishlistReskin){
    	var form = document.forms['Logon_wishList'];    	
    }else if("LogonPasswordLess"==form.id){
    	var form = document.forms['LogonPasswordLess']; 
    }else if(isNewSignInPageFlow){
    	var form = document.forms['signInPasswordForm']; 
    }
    else{
    	var form = document.forms['Logon'];
    }
	var action = form['action'].value;
	params["storeId"] 					= form["storeId"].value;
    params["catalogId"] 				= form["catalogId"].value;
    params["URL"] 						= form["URL"].value;
    params["logonId"] 					= form["logonId"].value;
    params["logonPassword"] 			= form["logonPassword"].value;
    params["pageParam"] 				= pageParam;
    if(form["channel"] != null && form["channel"].value != null){
    	 params["channel"] = form["channel"].value;
  	}
  	      
    var isExtendLoginTimeOutFeatureEnabled = ($("#isExtendLoginTimeOutEnabled") != null && $("#isExtendLoginTimeOutEnabled").val() == "true") ? true : false;
    if(isExtendLoginTimeOutFeatureEnabled){
    	/*if more than one element with name rememberMe*/
    	if(($("#isRemoveStockyardStayInSign").val() && TSCUtils.isNullObj(getCookie("inStoreOrder")) && form.rememberMe.length > 1) || (!$("#isRemoveStockyardStayInSign").val() && form.rememberMe.length > 1)){
    		if($("#WC_AccountDisplay_FormInput_rememberMe_In_Logon_2").prop("checked")){
    			params["rememberMe"] = "true";
    		}
  	  	}
    	else{
    	    if(($("#isRemoveStockyardStayInSign").val() && TSCUtils.isNullObj(getCookie("inStoreOrder")) && form.rememberMe.checked == true) || (!$("#isRemoveStockyardStayInSign").val() && form.rememberMe.checked == true)){
		  	  	params["rememberMe"] = "true";			  	 
			} 
        }
    }  
    else{
	    if(($("#isRemoveStockyardStayInSign").val() && TSCUtils.isNullObj(getCookie("inStoreOrder")) && form.rememberMe.checked == true) || (!$("#isRemoveStockyardStayInSign").val() && form.rememberMe.checked == true)){
	  	  	params["rememberMe"] = "true";
		} 
    }   
    if(isNewSignInPageFlow){
    	params["rememberMe"] = form.rememberMe.value;
	}

    if ((pageParam == 'deliveryPickup' || pageParam == 'payment' || pageParam == 'ATCInterstitial') && !isNewSignInPageFlow) {
    	displayProgressBar();
    	params["authToken"] = form['authToken'].value;
    	if(isSecureCaptchaEnabled('EnableUserLoginRecaptchaV3')) {
			grecaptcha.enterprise.ready(function() {
				grecaptcha.enterprise.execute($("#gcSiteKey").val(),{action:form.id}).then(function(token) {
					console.log("token : " + token);
					params["g-recaptcha-response"] = token;
					logonFromCheckout(params);
				});
			});                 
    	} else {
    		if (isNewSignInPageFlow){
    			invokeLoginService(params);
    		}
    		else{
    			logonFromCheckout(params);
    		} 
    	}		

    } else{
    	if(isSecureCaptchaEnabled('EnableUserLoginRecaptchaV3')) {
			grecaptcha.enterprise.ready(function() {
				grecaptcha.enterprise.execute($("#gcSiteKey").val(),{action:form.id}).then(function(token) {
					console.log("token : " + token);
					params["g-recaptcha-response"] = token;
					invokeLoginService(params);
				});
			});                 
    	} else {
    		invokeLoginService(params);
    	}
    }
}

/**
 * Declares an AJAX service that is used to Logon a customer from SignIn/ Cart
 * page. After successful login, Order item move call will be invoked and
 * redirect to redirectUrl received from service response.
 */
function invokeLoginService(params) {
	var requestURL = location.protocol + "//" + location.host
			+ "/wcs/resources/store/" + WCParamJS.storeId
			+ "/loginidentity/login?responseFormat=json&updateCookies=true";
	if(params["rememberMe"] == "true") {
		requestURL = requestURL + "&rememberMe=true";
	}
    $.ajax({
        url : requestURL,
        type : 'POST',
        cache: false,
        data: JSON.stringify(params),
        contentType: 'application/json',
        dataType: 'json',
        success : function(serviceResponse) {
			var redirectUrl = "";
			var invokeOrderCalculate = true;
			if (!TSCUtils.isNullObj(serviceResponse.primaryDeliveryZip)) {
				TSCUtils.setCookie("deliveryZipcode", serviceResponse.primaryDeliveryZip, {
			        path: '/'
			    });
				//ECOM-71505:To update the Global ship zipcode value in the header(React), WCS will be invoking window.TriggerGlobalZipcodeChange() method.
				var isEnabledGlobalDeliveryZipcode = $("#isEnabledGlobalDeliveryZipcode").val();
				var globalShipZipCookie = $("#globalZipCodeCookie").val();
				var lpDeliveryZipCodeNum = TSCUtils.getCookie("lpDeliveryZipCodeNum");
				if((isEnabledGlobalDeliveryZipcode == "true") && !TSCUtils.isNullObj(lpDeliveryZipCodeNum) && (lpDeliveryZipCodeNum != globalShipZipCookie)){
					try {
						window.TriggerGlobalZipcodeChange();
					} catch (error) {
					    console.error("An error occurred while triggering the REACT method for zipcode change in global header:", error);
					}
				}
			}
			TSCUtils.setCookie("isRegisteredUser", "Y", {
				path: '/'
			});
			deletePersonalizationCookies();
			if (serviceResponse.userId != null
					&& serviceResponse.redirectUrl != null) {
				if(isNewSignInPageEnabled && $("#pageName").val() == "SignInRedesign"){ 
					setSuccessfullLogin("my account:password", serviceResponse);
				}
				else{
					setSuccessfullLogin("my account", serviceResponse);
				}
				
				checkLoyaltyCookieValue("Y");

				redirectUrl = serviceResponse.redirectUrl;
				if (redirectUrl.indexOf("TSCGuestOrderDetailsView") > 0 || redirectUrl.indexOf("UserRegistrationForm") > 0) {
					redirectUrl = "/AccountDashboardView?catalogId=" + WCParamJS.catalogId + "&langId=" + WCParamJS.langId+ "&storeId=" + WCParamJS.storeId + "&topNav=purchaseOrder";
				}
				else if ($("#isPDP").length && $("#isPDP").val() == "true") {
					redirectUrl = window.location.pathname;
		        }
				else if($("#isPLP").length && $("#isPLP").val() === "true"){
					redirectUrl = window.location.href;		
				}
				else if (redirectUrl.indexOf("URL=") != -1) {
					redirectUrl = redirectUrl.replace(/amp;/g, "");
					redirectUrl = redirectUrl.substring(redirectUrl
							.indexOf("URL=") + 4);
					if($('#isShoppingCart').val() == "true") {
						invokeOrderCalculate = false;
          			}	
					if(isNewSignInPageEnabled && $("#pageName").val() == "SignInRedesign"){ 
						 var pendingCartFlag = (serviceResponse.pendingCartFlag != undefined && serviceResponse.pendingCartFlag == 'true') ? true : false;
						 if(pendingCartFlag){
							 redirectUrl = getAbsoluteURL() + "TSCShoppingCartView?storeId="
							 + WCParamJS.storeId + "&langId=" + WCParamJS.langId
							 + "&catalogId=" + WCParamJS.catalogId;
						 }	
					}
				}
				else {
					redirectUrl = getAbsoluteURL() + "TSCShoppingCartView?storeId="
							+ WCParamJS.storeId + "&langId=" + WCParamJS.langId
							+ "&catalogId=" + WCParamJS.catalogId;
				}
				
				var form = document.forms['Logon'];
				form['URL'].value = redirectUrl
				var params = [];
				params["URL"] = redirectUrl;
				params["storeId"] = WCParamJS.storeId;
				params['pageParam'] = "login";
				params['invokeOrderCalculate'] = invokeOrderCalculate;
				params['responseFormat'] = "json";
				wcService.invoke("AjaxTSCRESTOrderItemMove", params);
			}		
			cursor_clear();
		},
        error : function(data) {
        	var errorObj = data.responseJSON;
        	var form = document.forms['Logon'];
        	var isCartWishListRedesignFeature= $("#isCartWishListRedesignFeature").val();
			if (undefined != errorObj.errors && form != null) {
				errorObj= errorObj.errors[0];
				if (errorObj != null
						&& errorObj.errorMessage != null) {	
					if(!(isNewSignInPageEnabled && $("#pageName").val() == "SignInRedesign")){ 
						setFailedLogin(errorObj);
					}
					var pageParam = '';
					if(document.getElementById('pageType') != null) {
				        pageParam = document.getElementById('pageType').value;
				    }
					// Changes to display error in overlay and avoid redirecting to login page.
					if(isCartWishListRedesignFeature === "true" && pageParam=='ShoppingCart' && errorObj.errorKey==="ERR_INVALID_CRED"){
						$('#UserSignInErrorMessage').html(MessageHelper.messages["ERROR_UserSignInErrorMessage"]);
					} else if (!TSCUtils.isNullObj(pageParam) && pageParam=='ShoppingCart') {
						$('#UserSignInErrorMessage').html(errorObj.errorMessage);
					}else if (isNewSignInPageEnabled && $("#pageName").val() =='SignInRedesign' && errorObj.errorKey==="ERR_INVALID_CRED") {
						$("#logonPasswordErrMsg").removeClass('hide');
						$("#signInPasswordForm").addClass('has-error');
						formatValidationErrorMessage(form.logonPassword,MessageHelper.messages["SIGNIN_REDESIGN_ERR_INVALID_PASSWORD"]);
						LogonForm.setLoginErrorEvents(MessageHelper.messages["SIGNIN_REDESIGN_ERR_INVALID_PASSWORD"]);
					}else if (isNewSignInPageEnabled && $("#pageName").val() =='SignInRedesign' && (errorObj.errorKey==="ERR_INVALID_CRED_REMAINING_ATTEMPT" || errorObj.errorKey==="ERR_LOGIN_NOT_ALLOWED_NOW" || errorObj.errorKey==="_ERR_PERSON_ACCOUNT_LOCKED_OUT" || errorObj.errorKey==="ERR_PERSON_ACCOUNT_DISABLED")){
						$('#logonErrorMessageSR').html(errorObj.errorMessage);
						$("#logonErrorSectionSR").removeClass('hide');
						$("#signInPasswordForm").addClass('has-error');
						formatValidationErrorMessage(form.logonPassword,MessageHelper.messages["SIGNIN_REDESIGN_ERR_PROVIDE_PASSWORD"]);
						LogonForm.setLoginErrorEvents(MessageHelper.messages["SIGNIN_REDESIGN_ERR_PROVIDE_PASSWORD"]);
						$("#logonPasswordErrMsg").removeClass('hide');
						$(form.logonPassword).val('');
					}else {
						var logonId = form["logonId"].value;
						 var reLogonURL = "LogonForm";
						 if(form["reLogonURL"] != null && form["reLogonURL"].value == "OrderLookUpView") {
							 reLogonURL = form["reLogonURL"].value;
						 }					 
						document.location.href=getAbsoluteURL() + reLogonURL +"?storeId="+WCParamJS.storeId+"&langId="+WCParamJS.langId+"&catalogId="+WCParamJS.catalogId + "&logonId=" + encodeURIComponent(logonId) +"&errMsg=" + errorObj.errorMessage;
					}
				}
				cursor_clear();
			}
        }
    });
}
/**
 * validates the logon id and password fields and submits the form
 * @param form
 *
 */
function validateandSubmitLogonFieldsForOrderStatus(form) {
	clearErrorMessages(form);
	//Changes for replacing the label with error message
	$('#logonIdLabel').removeClass('hide');
	$('#logonPasswordLabel').removeClass('hide');
	if(document.getElementById('logonErrorMessage') != null){
		if(document.getElementById('logonErrorMessage').textContent != ""){
		document.getElementById('logonErrorMessage').textContent="";
		}
	}
	trimFormData(form.name,"logonId", form.logonId.value);
	if(form.logonId == null || form.logonId.value == ""){
		$('#logonIdLabel').addClass('hide');
		formatValidationErrorMessage(form.logonId,MessageHelper.messages["ERROR_LogonIdEmpty"]);
		return;

    }
	trimFormData(form.name,"logonPassword", form.logonPassword.value);
	if(form.logonPassword == null || form.logonPassword.value == ""){
		$('#logonPasswordLabel').addClass('hide');
		formatValidationErrorMessage(form.logonPassword,MessageHelper.messages["ERROR_PasswordEmpty"]);
		return;
	}
	if(!submitRequest()){
		return;
	}
	try{
		   utag.link({'event_type' : 'registration', 'customer_id' : Logon.logonId.value,'customer_email' : Logon.logonId.value});
			} catch(e){
				console.log('exception in handling utag'+e.stack);
			}
	console.debug("form.action == "+form.action);
    var params = {};
	var form = document.forms['Logon'];
	params["storeId"] 					= form["storeId"].value;
    params["catalogId"] 				= form["catalogId"].value;
    params["URL"] 						= form["URL"].value;
    params["logonId"] 					= form["logonId"].value;
    params["logonPassword"] 			= form["logonPassword"].value;
    
    if(($("#isRemoveStockyardStayInSign").val() && TSCUtils.isNullObj(getCookie("inStoreOrder")) && form.rememberMe.checked == true) || (!$("#isRemoveStockyardStayInSign").val() && form.rememberMe.checked == true)){
  	  params["rememberMe"] = "true";
	}
	if(isSecureCaptchaEnabled('EnableUserLoginRecaptchaV3')){
		grecaptcha.enterprise.ready(function() {
			grecaptcha.enterprise.execute($("#gcSiteKey").val(),{action:form.id}).then(function(token) {
				console.log("token : " + token);
				params["g-recaptcha-response"] = token;
				invokeLoginService(params);
			});
		});
	}else{
		invokeLoginService(params);
	}
}


/**
 * validates the phone number , last name for the Order Status form
 * @param formName to validate the details.
 *
 */
function validateandSubmitOrderStatusForm(formName) {

	var isFormValid = true;
	trimFormData(formName,"orderNum", document.forms[formName]["orderNum"].value);
	trimFormData(formName,"CustomerLastName", document.forms[formName]["CustomerLastName"].value);
	trimFormData(formName,"CustomerPhoneNum", document.forms[formName]["CustomerPhoneNum"].value);

	var orderNum = document.forms[formName]["orderNum"].value;
	var customerLastName = document.forms[formName]["CustomerLastName"].value;
	var customerPhoneNum = document.forms[formName]["CustomerPhoneNum"].value;

	if (orderNum === "") {
		isFormValid = false;
	} else if (customerLastName === "" && customerPhoneNum === "") {
		isFormValid = false;
	}

	if (isFormValid) {
		document.getElementById(formName).submit();
	} else {
		document.getElementById("validationErrMsgArea").classList.remove("hide");
	}
}

/**
 * Send a Ping request to server to reset the inactivity timer.  The client side timer to display the inactivity warning
 * dialog will also be reset.
 */
function resetServerInactivity() {
    $.post({
        url: getAbsoluteURL() + "Ping",
        dataFilter: jsonCommentFilter,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                initializeInactivityWarning();
            } else {
                console.error("Ping service failed");
            }
        },
        error: function(jqXHR, textStatus, error) {
            console.error("Ping service failed");
        }
    });
}

function jsonCommentFilter(str){
	// remove /* */ pair
	str = str.replace('\/\*', '');
    str = str.replace('\*\/', '');

    if (str.indexOf("<!--") != -1) {
        str = stripComment(str, "<!--", "-->");
    }

    if (str.indexOf("<%--") != -1) {
        str = stripComment(str, "<%--", "--%>");
    }

    var json = eval('(' + str + ')');
    return json;
}

/**
 * DOM Shorthand
 */
function byId(r){
    return document.getElementById(r);
}

/**
 * Sends back focus to the first focusable element on tabbing from the last focusable element.
 */
function focusSetter(){
    if($("#MiniCartFocusReceiver1").length)
        $("#MiniCartFocusReceiver1").focus();
    else
        $("#MiniCartFocusReceiver2").focus();
}

/**
 * Sends back focus to the last focusable element on reverse tabbing from the first focusable element.
 *
 * @param {object} event The event triggered from user actions
 */
function determineFocus(event) {
        if(event.shiftKey && event.keyCode === KeyCodes.TAB)
        {
            if(event.srcElement)
            {
                if(event.srcElement.id=="MiniCartFocusReceiver1")
                {
                    $("#WC_MiniShopCartDisplay_link_5").focus();
                    Utils.stopEvent(event);
                }
                else if(event.srcElement.id=="MiniCartFocusReceiver2")
                {
                    $("#MiniCartFocusReceiver2").focus();
                    Utils.stopEvent(event);
                }
            }
            else
            {
                if(event.target.id=="MiniCartFocusReceiver1")
                {
                    $("#WC_MiniShopCartDisplay_link_5").focus();
                    Utils.stopEvent(event);
                }
                else if(event.target.id=="MiniCartFocusReceiver2")
                {
                    $("#MiniCartFocusReceiver2").focus();
                    Utils.stopEvent(event);
                }
            }
        }
}

/**
 * Destroys the existing dialogs with outdated data.
 * @param {string} contentId The identifier of the dialog to destroy. If undefined, the default is 'quick_cart_container'.
 */
function destroyDialog(contentId){
    if(contentId == undefined){
        contentId = 'quick_cart_container';
    }
    //If data has changed, then we should destroy the quick_cart_container dialog and recreate it with latest data
    var dialog = $("#" + contentId).data("wc-WCDialog");
    if (dialog) {
        dialog.destroy();
    }

    if(contentId != undefined && contentId == 'quick_cart_container'){
         dropDownDlg = null;
    } else {
        productAddedDropDownDlg = null;
    }
}

/**
 * Loads the specified URL.
 *
 * @param {string} url The URL of the page to be loaded.
 */
function loadLink(url){
    document.location.href=url;
}

/**
 * Clears the Search term string displayed in Simple Search field.
 */
function clearSearchField() {
    searchText = $("#SimpleSearchForm_SearchTerm").val()
    if(searchText == $("#searchTextHolder").html()){
        $("#SimpleSearchForm_SearchTerm").val("");
    }
    else{
        document.getElementById("SimpleSearchForm_SearchTerm").select();
        showAutoSuggestIfResults();
        autoSuggestHover = false;
    }
}

/**
 * Displays the Search term string in Simple Search field.
 */
function fillSearchField() {
    if ($("#SimpleSearchForm_SearchTerm").val()== "") {
        document.getElementById("SimpleSearchForm_SearchTerm").className = "search_input gray_color";
        $("#SimpleSearchForm_SearchTerm").val($("#searchTextHolder").html());
    }
    // hide the search box results
    if(!autoSuggestHover) {
        showAutoSuggest(false);
    }
}

/**
 * Displays the top dropdown menu, including the category dropdowns and the mini shopping cart.
 */
function showDropDownMenu(){
    $("#header_menu_dropdown").css("display", "block");
    $("#outerCartContainer").css("display", "block");
}

/**
 * Displays the progress bar dialog to indicate a request is currently running.
 * There are certain cases where displaying the progress bar causes problems in Opera,
 * the optional parameter "checkForOpera" is passed to specifically check if the browser used is Opera,
 * if so, do not display the progress bar in these cases.
 *
 * @param {boolean} checkForOpera Indicates whether to check if the browser is Opera or not.
 */
function cursor_wait(checkForOpera, timeoutLimitId) {
    var showPopup = true;

    //Since dijit does not support Opera
    //Some progress bar dialog will be blocked in Opera to avoid error
    if(checkForOpera == true){
        if(Utils.isOpera()){
            showPopup = false;
        }
    }

    //For all other browsers and pages that work with Opera
    //Display the progress bar dialog
    if(showPopup){
        //Delay the progress bar from showing up
    	showProgressBar(timeoutLimitId);
    }
}

/**
 * Helper method for cursor_wait() to display the progress bar pop-up.
 * Displays progress bar, next to the element if the element id was specified in currentId,
 * or defaults to the center of the page if currentId is empty.
 * Progress bar will only be displayed if the submitted request has not been completed.
 * This method is only called implicitly by the cursor_wait() method, which is triggered before a request is submitted.
 */
function showProgressBar(timeoutLimitId){
    //After the delay, if the request is still not finished
    //Then continue and show the progress bar
    //Otherwise, do not execute the following code
    if(!requestSubmitted){
        return;
    }

    displayProgressBar(timeoutLimitId);

}


/**
 * Helper method for showProgressBar() to display the progress bar pop-up.
 * It can also be forced to show the progress bar directly in special cases.
 * The function also displays the progress bar next to the element if the element id was specified in currentId,
 * or defaults to the center of the page if currentId is empty.
 * This method can be called implicitly by the cursor_wait() method or explicitly by itself.
 */
function displayProgressBar(timeoutLimitId) {
	
	var dialog = $('#progress_bar_dialog'); 
	
	if(dialog.length){
		$('#progress_bar_dialog').modal();
		$("#progress_bar_dialog").on("shown.bs.modal", function(){
			$("#progress_bar_dialog").off("shown.bs.modal");
			progressBarCloseable = true;
		});
		var timeout = 10000;
		if($("#"+timeoutLimitId).length > 0 && "" != $("#"+timeoutLimitId).val()) {
		timeout = $("#"+timeoutLimitId).val();
		}
        //Make sure the progress bar dialog goes away after 30 minutes
        //and does not hang if server calls does not return
        //Assuming the longest server calls return before 30 minutes
		progressBarTimeout = setTimeout("cursor_clear(true)", timeout);
    }
}

/**
 * Stores the parent  id of the element (ex: button/link) that triggered the current submitted request.
 * Store the new element id only when no request is currently running.
 *
 * @param {string} id The id of element triggering the submitted request.
 */
function setParentEleId(id) {
	if(!requestSubmitted) {
		this.parentEleId = id;
	}
}

function clearParentEle() {
	this.parentEleId ="";
}


/**
 * Stores the id of the element (ex: button/link) that triggered the current submitted request.
 * Store the new element id only when no request is currently running.
 *
 * @param {string} id The id of element triggering the submitted request.
 */
function setCurrentId(id){
    //If there is no request already submitted, update the id
    if(!requestSubmitted && this.currentId === ""){
        this.currentId = id;
    }
}

/**
 * This function trims the spaces from the pased in word.
 * Delete all pre and trailing spaces around the word.
 *
 * @param {string} inword The word to trim.
 */
function trim(inword)
{
    word = inword.toString();
    var i = 0,
    j = word.length-1;
    while(word.charAt(i) == " ") i++;
    while(word.charAt(j) == " ") j=j-1;
    if (i > j) {
        return word.substring(i,i);
    } else {
        return word.substring(i,j+1);
    }
}

/**
 * Hides the progress bar dialog when the submitted request has completed.
 * Set the visibility of the progress bar dialog to hide from the page.
 */
function cursor_clear(timeout) {
    //Reset the flag
    requestSubmitted = false;
    TSCUtils.publish("requestSubmittedChanged", requestSubmitted);	
	if(timeout){
		if($('#progress_bar_dialog').is(":visible")){
			$('#progress_bar_dialog').modal("hide");			
		}
		progressBarCloseable = false;
	}else {
		if(!progressBarCloseable){
			setTimeout(function(){
				if(progressBarCloseable){					
					$('#progress_bar_dialog').modal("hide");
				}    		
				}, 400);
		}else{
			$('#progress_bar_dialog').modal("hide");
		}
		progressBarCloseable = false;			
	}
    this.currentId="";
}

function escapeXml (str, fullConversion){
    if(fullConversion){
        str = str.replace(/&(?!(quot;|#34;|#034;|#x27;|#39;|#039;))/gm, "&amp;").replace(/</gm, "&lt;").replace(/>/gm, "&gt;");
    }
    str = str.replace(/"/gm, "&#034;").replace(/'/gm, "&#039;");
    return str;
}
/**
 * Checks whether a request can be submitted or not.
 * A new request may only be submitted when no request is currently running.
 * If a request is already running, then the new request will be rejected.
 *
 * @return {boolean} Indicates whether the new request was submitted (true) or rejected (false).
 */
function submitRequest() {
    if(!requestSubmitted) {
        requestSubmitted  = true;
        TSCUtils.publish("requestSubmittedChanged", requestSubmitted);
        return true;
    }
    return false;
}

function resetRequest() {
    requestSubmitted = false;
    TSCUtils.publish("requestSubmittedChanged", requestSubmitted);
}

/**
 * Set the current page to a new URL.
 * Takes a new URL as input and set the current page to it, executing the command in the URL.
 * Used for Non-ajax calls that requires multiple clicks handling.
 *
 * @param {string} newPageLink The URL of the new page to redirect to.
 */
function setPageLocation(newPageLink) {
    //For Handling multiple clicks
    if(!submitRequest()){
        return;
    }

    document.location.href = newPageLink;
}

/**
 * Submits the form parameter.
 * Requires a form element to be passed in and submits form with its inputs.
 * Used for Non-ajax calls that requires multiple clicks handling.
 *
 * @param {element} form The form to be submitted.
 */
function submitSpecifiedForm(form) {	
	var captchaSubmitPageFlag = "";
    if(!submitRequest()){
        return;
    }
	if("Logon"==form.id){			
		if(Logon.logonId.value!=null){
			form.logonId.value=trim(form.logonId.value);
			captchaSubmitPageFlag = "EnableUserLoginRecaptchaV3";
		}
	}
	if("ResetPasswordForm"==form.id){
		clearErrorMessages(form);
		form.logonId.value=trim(form.logonId.value);
		var emailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
		if (!emailformat.test(form.logonId.value)) {
			formatValidationErrorMessage(form.logonId,MessageHelper.messages["ERROR_LOGON_FORMAT_FIELD"]);
		    return;
		}
		captchaSubmitPageFlag = "EnableResetPwdRecaptchaV3";
	}
	
    console.debug("form.action == "+form.action);
    if(isSecureCaptchaEnabled(captchaSubmitPageFlag)){
    	updateFormWithWcCommonRequestParameters(form);
    	addAuthToken(form);
		doSecureFormSubmit(form,form.id);
	}else{
		processAndSubmitForm(form);
	}
}
/**
 * Submits the form parameter(To update password).
 *
 * @param {element} form The form to be submitted.
 */
function updatePasswordForm(form){

	var regex=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
	clearErrorMessages(form);
	$('#logonPasswordLabel').removeClass('hide');
	var complexityMsg = '';
	if($('#complexity').length > 0)
		complexityMsg = $('#complexity').text();
	if(Logon.logonPassword.value!=null && (!(Logon.logonPassword.value.match(regex)) || (complexityMsg != null && complexityMsg.length > 0 && !complexityMsg.includes('Medium') && !complexityMsg.includes('Strong')))) {
		$('#logonPasswordLabel').addClass('hide');
		formatValidationErrorMessage(Logon.logonPassword,MessageHelper.messages["CHANGE_PWD_FORMAT_WRONG"]);
		return;
	}
	else if(Logon.logonPassword.value != null  && Logon.logonPasswordVerify.value != null && Logon.logonPassword.value != Logon.logonPasswordVerify.value){
		$('#logonPasswordLabel').addClass('hide');
		formatValidationErrorMessage(Logon.logonPassword,MessageHelper.messages["PWDREENTER_DO_NOT_MATCH"]);
		return;
	}
	var params = [];
	params.catalogId = form.catalogId.value;
	params.storeId = form.storeId.value;
	params.langId = form.langId.value;
		
	params.logonId = form.logonId.value;
	params.reLogonURL = form.reLogonURL.value;
	params.Relogon = form.Relogon.value;
	params.fromOrderId = form.fromOrderId.value;
	params.toOrderId = form.toOrderId.value;
	params.deleteIfEmpty = form.deleteIfEmpty.value;
	params.createIfEmpty = form.createIfEmpty.value;
	params.calculationUsageId = form.calculationUsageId.value;
	params.updatePrices = form.updatePrices.value;
	params.myAcctMain = form.myAcctMain.value;
	params.logonPassword = form.logonPassword.value;
	params.logonPasswordVerify = form.logonPasswordVerify.value;
	params.logonPasswordOld = form.logonPasswordOld.value;
	params.URL = form.URL.value;
	params.authToken = form.authToken.value;
	
	if(!submitRequest()){
        return;
    }
    cursor_wait();
	wcService.invoke("AjaxTSCRESTUpdatePassword", params);
}
/**
 * Submits the form parameter (To Trigger reset password mail).
 *
 * @param {element} form The form to be submitted.
 */
function resetPasswordFormMail(form){
	var params = [];
	params.catalogId = form.catalogId.value;
	params.storeId = form.storeId.value;
	params.langId = form.langId.value;
	params.state = form.state.value;
	if("ResetPasswordForm"==form.id){
		clearErrorMessages(form);
		$('#logonPasswordLabel').removeClass('hide');
		form.logonId.value=trim(form.logonId.value);
		var emailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
		if (!emailformat.test(form.logonId.value)) {
			formatValidationErrorMessage(form.logonId,MessageHelper.messages["ERROR_LOGON_FORMAT_FIELD"]);
			$('#logonPasswordLabel').addClass('hide');
		    return;
		}
		captchaSubmitPageFlag = "EnableResetPwdRecaptchaV3";
		
		params.logonId = form.logonId.value;
		params.challengeAnswer = form.challengeAnswer.value;
		params.URL = form.URL.value;
		params.errorViewName = form.errorViewName.value;
		params.authToken = form.authToken.value;		
	}   
	if(isSecureCaptchaEnabled(captchaSubmitPageFlag)) {
		updateFormWithWcCommonRequestParameters(form);
    	addAuthToken(form);
		grecaptcha.enterprise.ready(function() {
		grecaptcha.enterprise.execute($("#gcSiteKey").val(),{action:form.id}).then(function(token) {
			console.log("token : " + token);
			params["g-recaptcha-response"] = token;
			wcService.invoke("AjaxTSCRESTResetPasswordMail", params);
			});
		});  
	} 
	else{
		updateFormWithWcCommonRequestParameters(form);
    	addAuthToken(form);
		wcService.invoke("AjaxTSCRESTResetPasswordMail", params);       
    }	
		
} 

/** 
 * Declares an AJAX service to trigger the mail for reset password flow.(To trigger email).
 * @constructor
 */
 wcService.declare({
 	id: "AjaxTSCRESTResetPasswordMail",
 	actionId: "AjaxTSCRESTResetPasswordMail",
 	url: getAbsoluteURL() +"AjaxTSCRESTResetPasswordMail",
 	formId: ""
 		/**
 		 * Success handler , the call redirects from server side .Hence control will not come here. 
 		 **/
 		,successHandler: function(serviceResponse) { 			
 			if($("#isEnableSignInPageRedesigned").length && $("#isEnableSignInPageRedesigned").val() === "true"){
 				setResetPasswordEvent();
 			}
 			else{
 				setEventTrack('forget password');
 			}
 			document.location.href = "ResetPasswordForm?storeId="+WCParamJS.storeId+"&langId="+WCParamJS.langId+"&catalogId="+WCParamJS.catalogId;
 	    }
 		
 		/**
 		 * Error flow handler.
 		 */
 		,failureHandler: function(serviceResponse) {
 			console.log(serviceResponse);
     		document.location.href = "ResetPasswordForm?storeId="+WCParamJS.storeId+"&langId="+WCParamJS.langId+"&catalogId="+WCParamJS.catalogId;
 		}
 });
 /** 
  * Declares an AJAX service to reset the password.
  * @constructor
  */
 wcService.declare({
	 id: "AjaxTSCRESTUpdatePassword",
	 actionId: "AjaxTSCRESTUpdatePassword",
	 url: getAbsoluteURL() +"AjaxTSCRESTUpdatePassword",
	 formId: ""
	 /**
	  * Success handler 
	  **/
	 ,successHandler: function(serviceResponse) {
		 cursor_clear();
		 if (!TSCUtils.isNullObj(serviceResponse.primaryDeliveryZip)) {
			TSCUtils.setCookie("deliveryZipcode", serviceResponse.primaryDeliveryZip, {path : '/'});
		}
		 TSCUtils.setCookie("isRegisteredUser", "Y", {path: '/'});
		 
		 var params = [];
		 params["URL"] = "AccountDashboardView";
		 params["storeId"] = WCParamJS.storeId;
		 params["pageParam"] = "updatePassword";
		 params['responseFormat'] = "json";
		 wcService.invoke("AjaxTSCRESTOrderItemMove", params);
	 }	 		
	 /**
	  * Error flow handler.
	  */
	 ,failureHandler: function(serviceResponse) {
		 console.log(serviceResponse);
		 var failureUrl = window.location.href;
		 if(failureUrl.indexOf("&errorMessage") != -1) {
			 failureUrl = failureUrl.split("&errorMessage")[0];
		 }
		 document.location.href = failureUrl + "&errorMessage=" + serviceResponse.errorMessage;
	 }
 });
 
 /**
  * Declares an AJAX service to move order item
  * 
  * @constructor
  */
 wcService.declare({
 	id : "AjaxTSCRESTOrderItemMove",
 	actionId : "AjaxTSCRESTOrderItemMove",
 	url : getAbsoluteURL() + "AjaxOrderItemMove",
 	formId : ""
 	/**
 	 * Success handler
 	 */
 	,successHandler : function(serviceResponse) {
 		var form = document.forms['Logon'];
 		if( form != null && ((serviceResponse.pageParam == "login" && serviceResponse.invokeOrderCalculate == "true") || serviceResponse.pageParam == "updatePassword") ) {
 			var params = [];
 	 		params["storeId"] = WCParamJS.storeId;
 	 		params["calculationUsageId"] = "-1";
 	 		params["pageParam"] = serviceResponse.pageParam;	
 	 		if(serviceResponse.URL != null) {
 	 			params["URL"] = serviceResponse.URL;
 	 		}
 	 		params["responseFormat"] = "json";
 	 		wcService.invoke("AjaxTSCRESTOrderCalculate", params);
 		} else if(serviceResponse.pageParam == "socialLogin" || (serviceResponse.pageParam == "login" && serviceResponse.invokeOrderCalculate == "false")) {
 			document.location.href = getAbsoluteURL() + "TSCShoppingCartView?storeId="
 				+ WCParamJS.storeId + "&langId=" + WCParamJS.langId
 				+ "&catalogId=" + WCParamJS.catalogId;
 		}	
 	}
 	/**
 	 * Error flow handler.
 	 */
 	,failureHandler : function(serviceResponse) {
 		cursor_clear();
 	}
 });

 /**
  * Declares an AJAX service to calculate order.
  * 
  * @constructor
  */
 wcService.declare({
 	id : "AjaxTSCRESTOrderCalculate",
 	actionId : "AjaxTSCRESTOrderCalculate",
 	url : getAbsoluteURL() + "AjaxTSCRESTOrderCalculate",
 	formId : ""
 	/**
 	 * Success handler
 	 */
 	,successHandler : function(serviceResponse) {
 		var form = document.forms['Logon'];
 		if ($("#miniCartLocalStorageEnableFeatureFlag").val() == "true") {
 			window.aemMinicart.updateCartCount();
 		}
 		if( form != null && serviceResponse.pageParam == "updatePassword") {
 			document.location.href = getAbsoluteURL() + "AccountDashboardView?storeId="
 				+ WCParamJS.storeId + "&langId=" + WCParamJS.langId
 				+ "&catalogId=" + WCParamJS.catalogId;
 		} else if (form != null && serviceResponse.pageParam == "login" && serviceResponse.URL != null) {
 			var redirectUrl = serviceResponse.URL[0];
			 // For remembered user actions which requires login,
			 // redirection to AjaxLogonForm will be done by passing
			 // nextUrl of base page.
 			if(form.nextUrl != null && form.nextUrl.value != "") {
 				redirectUrl = form.nextUrl.value;
 			}
 			redirectUrl = redirectUrl.replace(/amp;/g, "");
 			if ($("#isRestrictExternalLogonRedirectionFeature").val() == "true") {
				var isInternalUrl = isValidUrl(redirectUrl)
				if (isInternalUrl) {
					document.location.href = redirectUrl;
				} else {
						document.location.href = getAbsoluteURL() + "AccountDashboardView?storeId="
		 				+ WCParamJS.storeId + "&langId=" + WCParamJS.langId
		 				+ "&catalogId=" + WCParamJS.catalogId;
					}
			} else {
					document.location.href = redirectUrl;
				}
 			
 			if($("#isPLP").length && $("#isPLP").val() === "true" && window.location.hash !== ""){
 				window.location.reload();
 			}
 		}		
 	}
 	/**
 	 * Error flow handler.
 	 */
 	,failureHandler : function(serviceResponse) {
 		cursor_clear();
 	}
 });
 
 
 function isValidUrl(urlRedirect) {
		var isValidInternalUrl = false;
		try {
			var tempUrl = new URL(urlRedirect);
			var urlHost = tempUrl.host;
			if (urlHost.includes("tractorsupply.com")) {
				isValidInternalUrl = true;
			}
		} catch (err) {
			console.debug("Error occured as redirect Url is not valid Url or it may be internal Url"
							+ err);
			var regExpTester = new RegExp(/^[A-Za-z/]+$/);
			var mainUrl = urlRedirect.substring(0, urlRedirect.indexOf("?"));
			if (regExpTester.test(mainUrl) && urlRedirect.startsWith("/")) {
				isValidInternalUrl = true;
			}
		}
		return isValidInternalUrl;

	}
 
function submitAndValidateForm(form){
	if("Logon"==form.id){

		  var regex=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
			if(Logon.logonPassword.value!=null && !(Logon.logonPassword.value.match(regex))){
				formatValidationErrorMessage(Logon.logonPassword,MessageHelper.messages["PWDREENTER_FORMAT_WRONG"]);
				return;
			}
	}
	console.debug("form.action == "+form.action);
	form.submit();

}
/**
 * Adds token value from captcha as a new form field and Submit form . 
 * @param form
 * @param actionName
 */
function doSecureFormSubmit(form,actionName){
	grecaptcha.enterprise.ready(function() {
		grecaptcha.enterprise.execute($("#gcSiteKey").val(),{action:actionName}).then(function(token) {
			secureToken = token;
			if(typeof form !== 'undefined'){
				var input = document.createElement("input");
				input.setAttribute("type", "hidden");
				input.setAttribute("name", "g-recaptcha-response");
				input.setAttribute("value", token);
				form.appendChild(input);
				form.submit();
			}
		});
	});
}

/**
 * Returns true if captcha feature is enabled for current page .
 * Checks the global flag and then module wise flag. 
 */
function isSecureCaptchaEnabled(moduleFlag) {
	var captchaFlag = false;
	if($("#gcSiteKey") && $("#gcSiteKey").length && $("#gcSiteKey").val()!= null 
			&& typeof grecaptcha !== 'undefined' && grecaptcha != null){
		captchaFlag = true;
	} // global flag
	if(captchaFlag && typeof moduleFlag !== 'undefined' && moduleFlag != null && !($("#" + moduleFlag) && $("#" + moduleFlag).val())){
		captchaFlag = false;
	} // module flag
	return captchaFlag;
}


/**
 * get location information of a given url
 * @param href
 * @returns url
 */
function getLocation(href) {
    var urlHref = href;
    if (!urlHref.match(/^[a-zA-Z]+:\/\//))
    {
        urlHref = 'http://' + urlHref;
    }
     var urlLocation = document.createElement("a");
     urlLocation.href = urlHref;

     return urlLocation;
}

/**
 * If the return URL belongs to a different domain from the current host,
 * return a blank page while go back button is clicked
 * @param url
 * @returns {String}
 */
function getReturnUrl(url) {
    var returnUrl;
    var urlLocation = this.getLocation(decodeURIComponent(url));
    if (urlLocation.hostname === location.hostname)
         returnUrl = url;
    else returnUrl = 'about:blank';
    location.href = returnUrl;
}

/**
 * solve issues in case cache is turned on, sometimes url is cached with
 * http or https portocol while accessed from an https or http protocol later on.
 * In this case we will match the protocol for ajax calls consistent with document.location.href
 * protocol
 * @param url
 * @returns {URL}
 */
function matchUrlProtocol(url){
	if(!stringStartsWith(url, "http")){
		//If it is relative URL, just return it.
		return url;
	}
    var href = document.location.href,
    index = href.lastIndexOf("s", 4),
    newUrl = url,
    urlIndex = newUrl.lastIndexOf("s", 4);
    if (index != -1 && urlIndex == -1){
         newUrl=newUrl.substring(0,4) + "s" + (newUrl.substring(4));
    }
    else if(index == -1 && urlIndex != -1)
    {
        newUrl = newUrl.substring(0,4) + (newUrl.substring(5));
    }
    return newUrl;
}

 /**
  * This function is used to hide an element with the specified id.
  * @param {string} elementId The id of the element to be hidden.
  */

  function hideElementById(elementId){
        $("#" + elementId).css("display", "none");
 }

/**
  * This function is used to display an element with the specified id.
  * @param {string} elementId The id of the element to be displayed.
  */

   function showElementById(elementId){
       $("#" + elementId).css("display", "block");
}

/**
  * This function is used to hide the background image of an element.
  * @param {DomNode} element The node whose background image is to be hidden.
  */
    function hideBackgroundImage(element){
        $(element).css("backgroundImage", 'none');
}

/**
  * This function is used to display the background image of a product onhover.
  * @param {DomNode} element The node for which the product hover background image is to be displayed.
  */

     function showBackgroundImage(element){
        $(element).css("backgroundImage", 'url('+getImageDirectoryPath()+getStyleDirectoryPath()+'product_hover_background.png)');
}
    /**
    * checkIE8Browser checks to see if the browser is IE 8 or less. It then sets correctBrowser to true if it is.
    *
    **/

    function checkIE8Browser() {
       if( Utils.get_IE_version() && Utils.get_IE_version() <= 8 ){
            correctBrowser = true;
       }
   }

    /**
    * ApprovalToolLink provides the appropriate URL if the browser is correct, otherwise displays a message.
    *
    * @param {String} idTag Used to identify the id tag in the jsp that is calling it.
    * @param {String} approvalToolLinkURL This is a URL which is passed from the calling jsp.
    *
    **/

    function ApprovalToolLink(idTag, approvalToolLinkURL) {

        //checks if the browser is IE 8 or less.
        checkIE8Browser();

        if (correctBrowser) {
          RFQwindow=window.open(approvalToolLinkURL);
        }
        else {
          MessageHelper.formErrorHandleClient(idTag,MessageHelper.messages["ERROR_INCORRECT_BROWSER"]); return;
        }
    }


/**
 * Updates view (image/detailed) and starting index of pagination of product display in SetCurrencyPreferenceForm when currency is changed from the drop-down menu.
 * These are later passed as url parameters.
 */

function updateViewAndBeginIndexForCurrencyChange(){
    if($("#fastFinderResultControls").length && document.getElementById('fastFinderResultControls')!='')
    {
        if(document.SetCurrencyPreferenceForm.pageView!=null){
            document.SetCurrencyPreferenceForm.pageView.value = document.FastFinderForm.pageView.value;
        }
        if(document.SetCurrencyPreferenceForm.beginIndex!=null){
            document.SetCurrencyPreferenceForm.beginIndex.value = document.FastFinderForm.beginIndex.value;
        }
    }
    else if($("#CategoryDisplay_Widget").length && document.getElementById('CategoryDisplay_Widget')!='')
    {
        if(wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['pageView'] !== '' && document.SetCurrencyPreferenceForm.pageView!=null){
            document.SetCurrencyPreferenceForm.pageView.value = wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['pageView'];
        }
        if(wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['beginIndex'] !== '' && document.SetCurrencyPreferenceForm.beginIndex!=null){
            document.SetCurrencyPreferenceForm.beginIndex.value = wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['beginIndex'];
        }
    }
    else if($("#Search_Result_Summary").length && document.getElementById('Search_Result_Summary')!='')
    {
        if(wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsView'] !== '' && document.SetCurrencyPreferenceForm.pageView!=null){
            document.SetCurrencyPreferenceForm.pageView.value = wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsView'];
        }
        if(wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsPageNum'] !== '' && document.SetCurrencyPreferenceForm.beginIndex!=null){
            document.SetCurrencyPreferenceForm.beginIndex.value=wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsPageNum']
        }
    }
    else if($("#Search_Result_Summary2").length && document.getElementById('Search_Result_Summary2')!='')
    {
        if(wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsView'] !== '' && document.SetCurrencyPreferenceForm.pageView!=null){
            document.SetCurrencyPreferenceForm.pageView.value = wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsView'];
        }
        if(wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsPageNum'] !== '' && document.SetCurrencyPreferenceForm.beginIndex!=null){
            document.SetCurrencyPreferenceForm.beginIndex.value=wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsPageNum']
        }
    }

    //allow coshopper to change currency. Only used for coshopping
    try {
        if (window.top._ceaCollabDialog!=undefined || window.top._ceaCollabDialog!=null) {
            $('#SetCurrencyPreferenceForm')[0].URL.value=
                    $('#SetCurrencyPreferenceForm')[0].URL.value + "&coshopChangeCurrency=" +
                    $('#currencySelection').find(":selected").text();
        }
    }catch(err) {
        console.log(err);
    }
}


/**
 * Updates view (image/detailed) and starting index of pagination of product display in LanguageSelectionForm when language is changed from the drop-down menu.
 * These are later passed as url parameters.
 */

function updateViewAndBeginIndexForLanguageChange(){
    if($("#fastFinderResultControls").length && document.getElementById('fastFinderResultControls')!='')
    {
        if(document.LanguageSelectionForm.pageView!= null){
            document.LanguageSelectionForm.pageView.value = document.FastFinderForm.pageView.value;
        }
        if(document.LanguageSelectionForm.beginIndex!= null){
            document.LanguageSelectionForm.beginIndex.value = document.FastFinderForm.beginIndex.value;
        }
    }
    else if($("#CategoryDisplay_Widget").length && document.getElementById('CategoryDisplay_Widget')!='')
    {
        if(wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['pageView'] !== '' && document.LanguageSelectionForm.pageView!= null){
            document.LanguageSelectionForm.pageView.value = wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['pageView'];
        }
        if(wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['beginIndex'] !== '' && document.LanguageSelectionForm.beginIndex!= null){
            document.LanguageSelectionForm.beginIndex.value = wcRenderContext.getRenderContextProperties('CategoryDisplay_Context').properties['beginIndex'];
        }
    }
    else if($("#Search_Result_Summary").length && document.getElementById('Search_Result_Summary')!='')
    {
        if(wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsView'] !== '' && document.LanguageSelectionForm.pageView!= null){
            document.LanguageSelectionForm.pageView.value = wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsView'];
        }
        if(wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsPageNum'] !== '' && document.LanguageSelectionForm.beginIndex!= null){
            document.LanguageSelectionForm.beginIndex.value = wcRenderContext.getRenderContextProperties('catalogSearchResultDisplay_Context').properties['searchResultsPageNum'];
        }
    }
    else if($("#Search_Result_Summary2").length && document.getElementById('Search_Result_Summary2')!='')
    {
        if(wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsView'] !== '' && document.LanguageSelectionForm.pageView!= null){
            document.LanguageSelectionForm.pageView.value = wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsView'];
        }
        if(wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsPageNum'] !== '' && document.LanguageSelectionForm.beginIndex!= null){
            document.LanguageSelectionForm.beginIndex.value = wcRenderContext.getRenderContextProperties('contentSearchResultDisplay_Context').properties['searchResultsPageNum'];
        }
    }

    //appending landId to the URL. Only used for coshopping
    try {
        if (window.top._ceaCollabDialog!=undefined || window.top._ceaCollabDialog!=null) {
            $('#LanguageSelectionForm').attr("action",  $("#LanguageSelectionForm").attr("action") + "&langId=" +
                $('#languageSelection').find(":selected").text());
        }
    }catch(err) {
        console.log(err);
    }
}

/**
 * Displays the header links in two lines.
 */
function showHeaderLinksInTwoLines(){
    if($("#header_links").length){
        if($("#header_links").width() > 750){
            $("#header_links1").css("display", "block");
            $("#headerHomeLink").css("display", "none");
            $("#headerShopCartLink").css("display", "none");
        }
        $("#header_links").css("visibility", "visible");
    }
}

/**
  * Displays the header links in one line.
  */
 function showLinksInOneLine(){
    $("#header_links").css("visibility", "visible");
 }

/**
 * Validates if the input value is a non-negative integer using regular expression.
 *
 * @param {String} value The value to validate.
 *
 * @return {Boolean} Indicates if the given value is a non-negative integer.
 */
function isNonNegativeInteger(value){
    var regExpTester = new RegExp(/^\d*$/);
    return (value != null && value !== "" && regExpTester.test(value));
}

/**
* Validates if the input value is a positive integer.
*
* @param {String} value The value to validate.
*
* @return {Boolean} Indicates if the given value is a positive integer.
*/
function isPositiveInteger(value){
    return (isNonNegativeInteger(value) && value != 0);
}

/**
 * This function closes all dialogs on the page.
 */
function closeAllDialogs(){
    $("[data-widget-type='wc.WCDialog']").each(function (i, e) {
        $(e).hide();
    });
}

/**
 * This function store a error key in the cookie. The error key will be used to retrieve error messages.
 * @param {String} errorKey  The key used to retrieve error/warning messages.
 */
function setWarningMessageCookie(errorKey) {
    setCookie("signon_warning_cookie",errorKey, {path: "/", domain: cookieDomain});
}
/**
* This function removes a cookie
* @param {String} name the name of the cookie to be removed.
*/
/*function removeCookie(name) {
    setCookie(name, null, {expires: -1});
}*/
/**
* This function gets a cookie
* @param {String} c the name of the cookie to be get.
*/
function getCookie(c) {
    return TSCUtils.getCookie(c);
}

function deleteOnBehalfRoleCookie(){
    var deleteOnBhealfRoleCookieVal = getCookie("WC_OnBehalf_Role_"+WCParamJS.storeId);

    if(deleteOnBhealfRoleCookieVal != null){
        setCookie("WC_OnBehalf_Role_"+WCParamJS.storeId, null, {expires:-1,path:'/', domain:cookieDomain});
    }
}

/**
* This function gets a cookie by name which starts with the provided string
* @param {String} c the partial name (starts with) of the cookie to be get.
*/
function getCookieName_BeginningWith(c) {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var index = cookies[i].indexOf("="),
        name = cookies[i].substr(0,index);
        name = name.replace(/^\s+|\s+$/g,"");
        if (stringStartsWith(name,c)) {
            return name;
        }
    }
}

/**
* This function checks to see if the string start with the specified set of characters
* @param {String} basestr the string
* @param {String} c the substring to check for
*/
function stringStartsWith(basestr, str) {
    return str.length > 0 && basestr.substring( 0, str.length ) === str
}

/**
 * checks if the store is in preview mode
 * @param {String} contextPathUsed The context path being used by the Store.
 * @param {String} criteria criteria used to check if contextPathUsed is the preview context.
 */
function isStorePreview(contextPathUsed,criteria) {
    return contextPathUsed.indexOf(criteria) > -1;
}

/**
 * checks hides the ESpot info popup window
 * @param {String} The id of the popup dialog
 * @param {String} The browser event
 */
function hideESpotInfoPopup(id,event) {
    if(event!=null && event.type=="keypress" && event.keyCode !== KeyCodes.ESCAPE) {
        return;
    }
    else {
        var quickInfo = $("#" + id);
        if(quickInfo.length) {
            quickInfo.data("wc-WCDialog").close();
        }
    }
}

/**
 * checks shows the ESpot info popup window
 * @param {String} The id of the popup dialog
 * @param {String} The browser event
 */
function showESpotInfoPopup(id, event) {
    if (event != null && event.type == "keypress" && event.keyCode !== KeyCodes.RETURN) {
        return;
    } else {
        if (!parent.checkPopupAllowed()) {
            return;
        }
        var quickInfo = $("#" + id).data("wc-WCDialog");
        if (quickInfo) {
            // Bring this Dialog on top of other dialogs
	        quickInfo.bringToFront(); 
            // Bring this Dialog on top of eSpot overlay button
            quickInfo.bringToFront($(".ESpotInfo"));
            quickInfo.open();    
            quickInfo.reposition();
        }
    }
}

/**
* This function increments the numAjaxRequests counter by 1.
*/
function incrementNumAjaxRequest(){
    if(numAjaxRequests != 'undefined'){
        numAjaxRequests++;
    }
}

/**
* This function decrements the numAjaxRequests counter by 1.
*/
function decrementNumAjaxRequest(){
    if(numAjaxRequests != 'undefined'){
        if(numAjaxRequests != 0){
            numAjaxRequests--;
        }
    }
}

/**
* updateParamObject This function updates the given params object with a key to value pair mapping.
*                   If the toArray value is true, It creates an Array for duplicate entries otherwise it overwrites the old value.
*                   This is useful while making a service call which accepts a few parameters of type array.
*                   This function is used to prepare a a map of parameters which can be passed to XMLHttpRequests.
*                   The keys in this parameter map will be the name of the parameter to send and the value is the corresponding value for each parameter key.
* @param {Object} params The parameters object to add name value pairs to.
* @param {String} key The new key to add.
* @param {String} value The new value to add to the specified key.
* @param {Boolean} toArray Set to true to turn duplicate keys into an array, or false to override previous values for a specified key.
* @param {int} index The index in an array of values for a specified key in which to place a new value.
*
* @return {Object} params A parameters object holding name value pairs.
*
**/
function updateParamObject(params, key, value, toArray, index){

    if(params == null) {
        params = [];
    }
    if(params[key] != null && toArray) {
        if($.isArray(params[key])) {
            //3rd time onwards
            if(index != null && index !== "") {
                //overwrite the old value at specified index
                params[key][index] = value;
            } else {
                params[key].push(value);
            }
        } else {
            //2nd time
            var tmpValue = params[key];
            params[key] = [];
            params[key].push(tmpValue);
            params[key].push(value);
        }
   } else {
       //1st time
       if(index != null && index !== "" && index != -1) {
           //overwrite the old value at specified index
           params[key+"_"+index] = value;
       } else if(index == -1) {
           var i = 1;
           while(params[key + "_" + i] != null) {
               i++;
           }
           params[key + "_" + i] = value;
       } else {
           params[key] = value;
       }
   }
   return params;
 }

/**
 * Show the html element
 *
 * @param {string} id The id of the section to show.
 */
function showSection (id){
    $("#" + id).css("visibility", "visible");
}

/**
 * Hides the html element.
 *
 * @param {string} id The id of the section to hide.
 */
function hideSection (id){
    $("#" + id).css("visibility", "");
}

/**
 * hides the section if the user clicks shift+tab.
 *
 * @param {string} id The id of the div area to hide.
 * @param {event} event The keystroke event entered by the user.
 */
function shiftTabHideSection (id, event){
    if (event.shiftKey && (event.keyCode === KeyCodes.TAB)){
        hideSection(id);
    }
}

/**
 * hides the section if the user clicks tab.
 *
 * @param {string} id The id of the div area to hide.
 * @param {event} event The keystroke event entered by the user.
 */
function tabHideSection (id, event, nextId){
    if (!event.shiftKey && (event.keyCode === KeyCodes.TAB)){
        if(null != nextId){
            $("#" + nextId).focus();
        }
        hideSection(id);
        Utils.stopEvent(event);
    }
}

/**
 * Saves whether the shift and tab keys were pressed or not.  Ignores tab.
 * @param {event} event The event that happened by pressing a key
 */
function saveShiftTabPress(event) {
    if (event.shiftKey == true && event.keyCode === KeyCodes.TAB) {
        tabPressed = true;
    }
}

/**
 * Saves whether the tab key was pressed or not.  Ignores SHIFT-tab.
 * @param {event} event The event that happened by pressing a key
 */
function saveTabPress(event) {
    if (event.shiftKey == false && event.keyCode === KeyCodes.TAB) {
        tabPressed = true;
    }
}
/**
 * Sets the focus to the given form element if a tab or shift-tab was pressed.  Workaround to tabbing from a country dropdown
 * to a dynamic state element that becomes a dropdown when it was a textbox and vice versa.  Defect was Firefox specific.
 * @param {String} formElementId The form element id to set the focus to
 */
function setFocus(formElementId) {
    if (tabPressed) {
        tabPressed = false;
        document.getElementById(formElementId).focus();
    }
}

/**
 * Increase the height of a container due to the addition of a message
 * @param ${String} The id of the container whose height will be increased
 * @param ${Integer} Number of pixels to increase height by
 */
function increaseHeight(containerId, increase) {
    var temp = document.getElementById(containerId).offsetHeight;
    $("#" + containerId).css("height", (temp + increase) + 'px');
}

/**
 * Reload the current page.
 * @param ${forcedUrl} The url which sends user to the expected page.
 */
function redirectToSignOn(forcedUrl) {

	if(typeof(forcedUrl)!='undefined'){
		var currentURL = forcedUrl;
	}else{
		var currentURL = location.href;
	}
	currentURL = "OrderItemMove?continue=1&createIfEmpty=1&updatePrices=0&deleteIfEmpty=*&fromOrderId=*&toOrderId=.&page=&calculationUsageId=-1&URL="+encodeURIComponent("OrderCalculate?URL="+encodeURIComponent(currentURL));
	var finalURL = "LogonForm?myAcctMain=1&storeId="
			+ WCParamJS.storeId + "&catalogId=" + WCParamJS.catalogId
			+ "&langId=" + WCParamJS.langId + "&URL=" + encodeURIComponent(currentURL);
	TSCUtils.setCookie("WC_RedirectToPage_"+WCParamJS.storeId, forcedUrl, {path:'/', domain:"cookieDomain"});			

	try{
		utag.link({
			'page_name' : utag_data['page_name'],
			'link_href' : finalURL + '&cm_sp=Header_Top-_-Account-_-WishList'
		});
		
	}catch(e){
		console.log('error occurred when tagging: ' + e.stack);
	}
	document.location.href = finalURL;

	
	
}

/**
 * Keeps track of the current quick info/compare touch event in tablets
 * @param ${String} link The link of the product detail page
 * @param ${String} newPopup The id of the new product quick info/compare touched
 */
function handlePopup(link,newPopup) {
    if (currentPopup == newPopup){
        document.location.href = link;
    } else {
        currentPopup = newPopup;
    }
}

/**
 * Check to see if the device in use is running the android OS
 * @return {boolean} Indicates whether the device is running android
 */
function isAndroid() {
    if(android == null){
        if(navigator != null){
            if(navigator.userAgent != null){
                var ua = navigator.userAgent.toLowerCase();
                android = ua.indexOf("android") > -1;
            }
        }
    }
    return android;
}

/**
 * Check to see if the device in use is running iOS
 * @return {boolean} Indicates whether the device is running iOS
 */
function isIOS() {
    if(ios == null){
        if(navigator != null){
            if(navigator.userAgent != null){
                var ua = navigator.userAgent.toLowerCase();
                ios = (ua.indexOf("ipad") > -1) || (ua.indexOf("iphone") > -1) || (ua.indexOf("ipod") > -1);
            }
        }
    }
    return ios;
}


/**
* This function highlight all marketing spots and catalog objects in preview mode, overwriting the implementation in site level (StorePreviewerHeader.jsp)
*/
function outlineSpots(){
    $(document.body).addClass('editMode');
    $(".carousel > .content").css("zIndex", 'auto');
    $(".ESpotInfo").css("display", "block" );
    $(".searchScore").css("display", "block" );
    $(".editManagedContent").css("display", "block" );
    $(".genericESpot,.product,.searchResultSpot,.productDetail,.categorySpot").each(function (i, currEl) {
        if ($(currEl).hasClass("emptyESpot")) {
            var elementWidth = $('.ESpotInfo', currEl)[0].offsetWidth + 4,
                elementHeight = $('.ESpotInfo', currEl)[0].offsetHeight + 4;
            $(currEl).attr("_width", $(currEl).css('width'));
            $(currEl).attr("_height", $(currEl).css('height'));
            $(currEl).css({
                'width': +elementWidth + 'px',
                'height': elementHeight + 'px'
            });

        }
        if ($(".borderCaption").length) {
            $(currEl).prepend("<div class='borderCaption'></div>");
        } else {
            $(".borderCaption", currEl).css({
                'display': 'block'
            });
        }
        if (currEl.addEventListener) {
            currEl.addEventListener('mouseover', function (evt) {
                if (!window.parent.frames[0].isSpotsShown()) {
                    return;
                }
                $(".caption").css("display", "none"
                );
                $(".caption", this).first().css({
                    display: "block"
                });
                evt.stopPropagation();
            }, false);
            currEl.addEventListener('mouseout', function (evt) {
                if (!window.parent.frames[0].isSpotsShown()) {
                    return;
                }
                $(".caption", this).css({
                    display: "none"
                });
                evt.stopPropagation();
            }, false);
        } else if (currEl.attachEvent) {
            currEl.onmouseover = (
                (function (currEl) {
                    return (function () {
                        if (!window.parent.frames[0].isSpotsShown()) {
                            return;
                        }
                        $(".caption").css("display", "none");
                        $(".caption", currEl).first().css({
                            display: "block"
                        });
                        window.event.cancelBubble = true;
                    });
                })(currEl)
            );
            currEl.onmouseleave = (
                (function (currEl) {
                    return (function () {
                        if (!window.parent.frames[0].isSpotsShown()) {
                            return;
                        }
                        $(".caption", currEl).css({
                            display: "none"
                        });
                        window.event.cancelBubble = true;

                    });
                })(currEl)
            );
        }
    });
}

/**
* This function un-highlight all marketing spots and catalog objects in preview mode, overwriting the implementation in site level (StorePreviewerHeader.jsp)
*/
function hideSpots(){
    $(document.body).removeClass("editMode");
    $(".carousel > .content").css("zIndex", '');
    $(".ESpotInfo").css("display", "none");
    $(".caption").css("display", "none");
    $(".searchScore").css("display", "none");
    $(".editManagedContent").css("display", "none");
    $(".borderCaption").css("display", "none");
    $(".emptyESpot").each(function (i, e) {
        $(e).css({
            'width': $(e).attr("_width") + 'px'
        });
        $(e).css({
            'height': $(e).attr("_height") + 'px'
        });
    });

}

/**
* This function resets the mini cart cookie values, then continues to log off the shopper.
*/
function logout(url){
    setDeleteCartCookie();
    document.location.href = url;
    removeCookie('firstName');
}

/**
 * This function submits the Language and Currency selection form.
 * @param formName The name of the Language and Currency selection form.
 */
function switchLanguageCurrency(formName) {
    //to get the browser current url
    var browserURL = document.location.href,
    currentLangSEO = '/'+$("#currentLanguageSEO").val()+'/';

    // get rid of anything after pound sign(#) in the URL if it is a SearchDisplay request.
    // search processes the query parameters and cannot handle a redirect URL with pound sign(#)
    if ((browserURL.indexOf('SearchDisplay') !== -1 || browserURL.indexOf('CategoryDisplay') !== -1) && browserURL.indexOf('#') !== -1) {
        var poundTokens = browserURL.split('#');
        browserURL = poundTokens[0];
    }

    //set the form URL to the updated URL with the new language keyword
    //for example: replace /en/ with the new keyword
    var modifiedFormURL = browserURL;
    if (browserURL.indexOf(currentLangSEO) !== -1) {
        if (document.getElementById('langSEO'+document.getElementById('languageSelection').options[document.getElementById('languageSelection').selectedIndex].value)) {
            var newLangSEO = '/'+document.getElementById('langSEO'+document.getElementById('languageSelection').options[document.getElementById('languageSelection').selectedIndex].value).value+'/';
            modifiedFormURL = browserURL.replace(currentLangSEO,newLangSEO);
        }
    }
    //replace langId with the newly selected langId
    if (modifiedFormURL.indexOf('&') !== -1) {
        var tokens = modifiedFormURL.split('&');
        modifiedFormURL = "";
        for (var i=0; i<tokens.length; i++) {
            if (tokens[i].indexOf('langId=') === -1) {
                if (modifiedFormURL === '') {
                    modifiedFormURL = tokens[i];
                } else {
                    modifiedFormURL = modifiedFormURL + "&" + tokens[i];
                }
            } else if (tokens[i].indexOf('langId=') > 0) {
                if (i==0) {
                    //langId is the first token next to ?
                    modifiedFormURL = tokens[0].substring(0,tokens[0].indexOf('langId='));
                } else {
                    modifiedFormURL = modifiedFormURL + "&";
                }
                modifiedFormURL = modifiedFormURL + "langId=" + document.getElementById('languageSelection').options[document.getElementById('languageSelection').selectedIndex].value;
            }
        }
    }
    modifiedFormURL = switchLanguageCurrencyFilter(modifiedFormURL);
    document.forms[formName].URL.value = modifiedFormURL;
    document.forms[formName].languageSelectionHidden.value = document.getElementById('languageSelection').options[document.getElementById('languageSelection').selectedIndex].value;

    //hide pop up and submit the form
    $('#widget_language_and_currency_popup').data("wc-WCDialog").close();
    //delete buyOnBehalfOf cookie if exists
    //if (typeof(GlobalLoginShopOnBehalfJS) != 'undefined' && GlobalLoginShopOnBehalfJS != null ){
    //  GlobalLoginShopOnBehalfJS.deleteBuyerUserNameCookie();
    //}
    if (typeof changeStoreViewLanguage == 'function') {
        changeStoreViewLanguage(document.forms[formName].languageSelectionHidden.value);
    }
    document.getElementById(formName).submit();
}

/**
 * This function checks replaces url in a exclusion list with string from the replacement list
 * @param url The url to process
*/
function switchLanguageCurrencyFilter(url) {
    var filterList = [];

    // Add any new exclude and replace view name in filterList
    // exclude UserRegistrationAdd in URL and replace with UserRegistrationForm?new=Y
    var paramList = [{"key": "registerNew", "value": "Y"}];
    filterList.push({"exclude": "UserRegistrationAdd", "replace": "UserRegistrationForm", "param": paramList});

    for (var i=0; i<filterList.length; i++) {
        if (url.indexOf(filterList[i].exclude) > 0) {
            url = url.replace(filterList[i].exclude, filterList[i].replace);
            if (filterList[i].param != undefined) {
                var urlParamList = filterList[i].param;
                for (var j=0; j<urlParamList.length; j++) {
                    url = appendToURL(url, urlParamList[j].key, urlParamList[j].value, false);
                }
            }
            break;
        }
    }
    return url;
}

/**
 * This function check the <code>url</code> <code>paramterName</code> pair against the URLConfig exclusion list.
 * @param url The url to send request.
 * @param parameterName The name of the parameter to be sent with request.
 * @returns {Boolean} True if the parameter and url is not in an exclusion list, false otherwise.
 */
function isParameterExcluded(url, parameterName){
    try{
    if(typeof URLConfig === 'object'){
        if (typeof URLConfig.excludedURLPatterns === 'object'){
            for ( var urlPatternName in URLConfig.excludedURLPatterns){
                var exclusionConfig = URLConfig.excludedURLPatterns[urlPatternName],
                urlPattern = urlPatternName;
                if(typeof exclusionConfig === 'object'){
                    if(exclusionConfig.urlPattern){
                        urlPattern = exclusionConfig.urlPattern;
                    }
                    console.debug("URL pattern to match : " + urlPattern);
                    urlPattern = new RegExp(urlPattern);

                    if(url.match(urlPattern)){
                        var excludedParametersArray = exclusionConfig.excludedParameters;
                        for(var excludedParameter in excludedParametersArray){
                            if(parametername == excludedParameter){
                                return true;
                            }
                        }
                    }
                }
            }
        } else {
            console.debug("The parameter " + parameterName + " is not excluded");
        }
    } else {
        console.debug("No URLConfig defined.")
    }
    } catch (err){
        console.debug("An error occured while trying to exclude " + err);
    }
return false;
}

/**
 *  The utility function to append name value pair to a URL
 *  @param allowMultipleValues (boolean) Indicates whether a parameterName can have multiple values or not, default value is <code>false</code>.
 *  @returns url The updated url.
 */
function appendToURL(url, parameterName, value, allowMultipleValues){
    allowMultipleValues = (null == allowMultipleValues) ? false : allowMultipleValues;
    var paramPattern = new RegExp(parameterName + "=[^&]+"),
    newParamString = parameterName + "=" + value;
    if ( url.indexOf(newParamString) !== -1 ) {
    } else if (!paramPattern.test(url) || allowMultipleValues){
        if (url.indexOf('?') === -1 ){
            url = url + '?';
        }else{
            url = url + '&';
        }
        url = url + newParamString;
    } else {
        // replace it if the old one does not match with the one in wcCommonRequestParameters, possible caching case?
        url = url.replace(paramPattern, newParamString);
    }
    return url;
}


/**
 * The function append parameters, such as <code>forUserId</code>, in <code>wcCommonRequestParameters</code>
 * as URL parameters to the give URL.
 * @param allowMultipleValues (boolean) Indicates whether a parameterName can have multiple values or not, default value is <code>false</code>
 * @returns url The updated url.
 */
function appendWcCommonRequestParameters(url, allowMultipleValues){
    allowMultipleValues = (null == allowMultipleValues) ? false : allowMultipleValues;
    if(typeof wcCommonRequestParameters === 'object'){
        for(var parameterName in wcCommonRequestParameters){
            if(!isParameterExcluded(url, parameterName)){
                url = appendToURL(url, parameterName, wcCommonRequestParameters[parameterName], allowMultipleValues);
            }
        }
    }
    return url;
}

/**
 *  Update the form the <code>wcCommonRequestParameters</code>.
 *  @param form The form to update.
 */
function updateFormWithWcCommonRequestParameters(form, allowMultipleValues) {
    allowMultipleValues = (null == allowMultipleValues) ? false : allowMultipleValues;
    if(typeof wcCommonRequestParameters === 'object'){
        for(var parameterName in wcCommonRequestParameters){
            if(form.action !== undefined && null !== form.action && !isParameterExcluded(form.action, parameterName) ){
                var exist = false;
                $("input[name=" + "'" + parameterName + "']", form).each(function(i, param){
                    if (param.value == wcCommonRequestParameters[parameterName]){
                        exist = true;
                    } else if (!allowMultipleValues) {
                        form.removeChild(param);
                    }
                });
                if (!exist ) {
                    if (form.method == 'get') {
                        //the parameter in the URL for form get is not read, need to use hidden input.
                        $("<input></input>", {name: parameterName, type:'hidden', value: wcCommonRequestParameters[parameterName]}).appendTo(form);
                    } else {
                        //post action url probably already has this parameter, use 'appendToURL' to handle duplication
                        form.action = appendToURL(form.action, parameterName, wcCommonRequestParameters[parameterName], allowMultipleValues);
                    }
                }
            }
        }
    }
}

function addAuthToken(form){
    try{
        if($("#csrf_authToken").length){
            $("<input></input>", {name: "authToken", type:'hidden', value: $("#csrf_authToken").val()}).appendTo(form);
        } else {
            console.debug("auth token is missing from the HTML DOM");
        }
        return true;
    } catch (err){
        console.debug("An error occured while trying to add authToken to the form..." + err);
    }
    return false;
}

/**
 *  Process the form update and submit. All forms' submission are recommended to be done this function.
 *  @param form The form to submit.
 */
function processAndSubmitForm (form) {
    updateFormWithWcCommonRequestParameters(form);
    addAuthToken(form);
    form.submit();
}


function getCommonParametersQueryString(){
    return "storeId="+WCParamJS.storeId+"&langId="+WCParamJS.langId+"&catalogId="+WCParamJS.catalogId;
}

/**
 *  Handle service requests after user login for these scenarios:
 *  - timeout while trying to execute an Ajax request
 *  - need authentication while trying to execute an Ajax request
 *
 *  The nextUrl is a parameter returned from wc dojo framework when an error has occured when excuting an AJAX action.
 *  It specifies the next Ajax action to be performed after the user has logged on
 **/

    /**
    * This function checks to see if the passed in URL contains a parameter named 'finalView'. If it does, it will construct a new URL
    * using value from 'finalView' paramter as the view name
    * @param {String} myURL The URL to check if it contains finalView param
    */
    function getFinalViewURL(myURL) {
        var finalViewBeginIndex = myURL.indexOf('finalView');

        // check if finalView parameter is passed in the URL
        if (finalViewBeginIndex != -1){

            // index after parameter 'finalView', when it is -1, that means final view is the last paramter in the URL
            var finalViewEndIndex = myURL.indexOf('&', finalViewBeginIndex);
            if (finalViewEndIndex == -1) {
                var finalViewName = myURL.substring(finalViewBeginIndex + 10);
            } else {
                var finalViewName = myURL.substring(finalViewBeginIndex + 10, finalViewEndIndex);
            }
            var originalActionEndIndex = myURL.indexOf('?');

            // firstPartURL is everything after the '?' sign and before finalView parameter
            var firstPartURL = myURL.substring(originalActionEndIndex + 1, finalViewBeginIndex);
            // secondPartURL is everything after finalView parameter
            if (finalViewEndIndex == -1) {
                var secondPartURL = "";
            } else {
                var secondPartURL = myURL.substring(finalViewEndIndex);
            }

            // to make things simple, remove all leading and trailing '&' for firstPartURL and secondPartURL
            if (firstPartURL.charAt(firstPartURL.length - 1) == '&') {
                firstPartURL = firstPartURL.substring(0, firstPartURL.length-1);
            }
            if (firstPartURL.charAt(0) == '&') {
                firstPartURL = firstPartURL.substring(1);
            }
            if (secondPartURL.charAt(secondPartURL.length - 1) == '&') {
                secondPartURL = secondPartURL.substring(0, secondPartURL.length-1);
            }
            if (secondPartURL.charAt(0) == '&') {
                secondPartURL = secondPartURL.substring(1);
            }

            var finalURL = "";
            if (firstPartURL !== "") {
                finalURL = finalViewName + '?' + firstPartURL;
                if (secondPartURL !== "") {
                    finalURL = finalURL + '&' + secondPartURL;
                }
            } else {
                finalURL = finalViewName + '?' + secondPartURL;
            }
            return finalURL;
        } else {
            return "";
        }
    }

    wcService.declare({
        id: "MyAcctAjaxAddOrderItem",
        actionId: "AjaxAddOrderItem",
        url: "",
        formId: ""
        ,successHandler: function(serviceResponse) {
            MessageHelper.hideAndClearMessage();
            MessageHelper.displayStatusMessage(Utils.getLocalizationMessage("SHOPCART_ADDED"));
            setCookie("WC_nextURL_"+WCParamJS.storeId, null, {expires:-1,path:'/', domain:cookieDomain});
            cursor_clear();
        }
        ,failureHandler: function(serviceResponse) {
            if (serviceResponse.errorMessage) {
                if(serviceResponse.errorMessageKey == "_ERR_NO_ELIGIBLE_TRADING"){
                    MessageHelper.displayErrorMessage(Utils.getLocalizationMessage("ERROR_CONTRACT_EXPIRED_GOTO_ORDER"));
                } else {
                    MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
                }
            }
            else {
                 if (serviceResponse.errorMessageKey) {
                    MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
                 }
            }
            cursor_clear();
        }

    });

    wcService.declare({
        id: "MyAcctGenericService",
        actionId: "",
        url: "",
        formId: ""
        ,successHandler: function(serviceResponse) {
            setCookie("WC_nextURL_"+WCParamJS.storeId, null, {expires:-1,path:'/', domain:cookieDomain});
            MessageHelper.hideAndClearMessage();
            finalViewURL = getFinalViewURL(this.url);
            if (finalViewURL !== "") {
                document.location.href = finalViewURL;
            } else {
                MessageHelper.displayStatusMessage(Utils.getLocalizationMessage("MYACCOUNT_ACTION_PERFORMED"));
                cursor_clear();
            }
        }
        ,failureHandler: function(serviceResponse) {
            if (serviceResponse.errorMessage) {
                MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
            }
            else {
                 if (serviceResponse.errorMessageKey) {
                    MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
                 }
            }
            cursor_clear();
        }
    });
    
    function invokeItemAdd(inUrl) {
        wcService.getServiceById("MyAcctAjaxAddOrderItem").url=inUrl;
        var addToCartParams = [];
        // PasswordRequestAuthenticated is required by PasswordRequestCmdImpl - to indicate password is already entered by user. As we are in the my
        // account page, password is already entered and accepted.
        addToCartParams.PasswordRequestAuthenticated = 'TRUE';

        if(!submitRequest()){
            return;
        }
        cursor_wait();
        setCookie("WC_nextURL_"+WCParamJS.storeId, null, {expires:-1,path:'/', domain:cookieDomain});
        wcService.invoke("MyAcctAjaxAddOrderItem", addToCartParams);
    }
    function invokeOtherService(inUrl) {
        wcService.getServiceById("MyAcctGenericService").url=inUrl;
        var params = {PasswordRequestAuthenticated: 'TRUE'};

        if(!submitRequest()){
            return;
        }
        cursor_wait();
        setCookie("WC_nextURL_"+WCParamJS.storeId, null, {expires:-1,path:'/', domain:cookieDomain});
        wcService.invoke("MyAcctGenericService", params);
    }

    /**
     * In order to handle the case that IE handles cookie domain differently from other browsers,
     * Use this centerlized function to handle cookie value assignment and ingore domain prop for cookie
     * creation when domain name is an empty string.
     */
    function setCookie(cookieName, cookieVal, props) {
        TSCUtils.setCookie(cookieName, cookieVal, props);
    }

    function getBaseTextDir() {
        // summary:
        //      returns the value of the "baseTextDirection" preference.
        var btd = getCookie(_BTD_PREFS);
        if (!btd){
            return "";
        }
        return btd;
    }

    function setBaseTextDir(btd) {
        // summary:
        //      Sets the value of the baseTextDirection" preferences.
        var oldItem = getCookie(_BTD_PREFS);
        if (oldItem != btd){
            setCookie(_BTD_PREFS, btd.toLowerCase());
        }
    }

    function isRTLValue(stringValue) {
        for (var ch in stringValue) {
            if (isBidiChar(stringValue.charCodeAt(ch)))
                return true;
            else if(isLatinChar(stringValue.charCodeAt(ch)))
                return false;
        }
        return false;
    }

    function isBidiChar(c)  {
        if (c >= 0x05d0 && c <= 0x05ff)
            return true;
        else if (c >= 0x0600 && c <= 0x065f)
            return true;
        else if (c >= 0x066a && c <= 0x06ef)
            return true;
        else if (c >= 0x06fa && c <= 0x07ff)
            return true;
        else if (c >= 0xfb1d && c <= 0xfdff)
            return true;
        else if (c >= 0xfe70 && c <= 0xfefc)
            return true;
        else
            return false;
    }

    function isLatinChar(c){
        if((c > 64 && c < 91)||(c > 96 && c < 123))
            return true;
        else
            return false;
    }

    function resolveBaseTextDir(value, txtDir) {
        if (txtDir == "auto") {
            if (isRTLValue(value)) {
                return "rtl";
            } else {
                return "ltr";
            }
        }
        else {
            return txtDir;
        }
    }

    function handleTextDirection(requiredElement) {
        var txtDir = getBaseTextDir();
        if (requiredElement != null && txtDir !== "") {
            if (["INPUT", "TEXTAREA"].indexOf(requiredElement.tagName) > -1) {
                $(requiredElement).css("direction", resolveBaseTextDir(requiredElement.value, txtDir));
            }
        }
    }

    function enforceTextDirectionOnPage() {
        var txtDir = getBaseTextDir();
        if (txtDir !== "") {
            $(".bidiAware").each(function(i, element) {
                element.dir = $(element).css("direction", resolveBaseTextDir(element.value || element.text || element.outerText || element.innerHTML, txtDir));
            });
        }
    }

    /**
    * Common failure handler function which can be used with most of the
    * service definitions.
    * This function displays errorMessage present in serviceResponse object.
    * If errorMessage is null, then it displays errorMessageKey.
    */
    function commonServiceFailureHandler(serviceResponse) {
        if (serviceResponse.errorMessage) {
            MessageHelper.displayErrorMessage(serviceResponse.errorMessage);
        } else {
            if (serviceResponse.errorMessageKey) {
                MessageHelper.displayErrorMessage(serviceResponse.errorMessageKey);
            }
        }
        cursor_clear();
    }

    function isChrome(){
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    }

    function isSafari(){
        return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    }

    /**
    * Success handler function used with services which does URL chaining.
    * This function retrieves the next URL in the URLChain and invokes ajax service
    * with appropriate input parameters specified for the URL.
    * If the next URL is specified with requestType = 'GET', then it just
    * redirects the page to nextURL without invoking any further service
    */
    function successHandlerForURLChainingService(serviceResponse){
        console.debug("Success Handler of AjaxCustomServiceForURLChaining", serviceResponse);
        if(this.postRefreshHandlerParameters != null && this.postRefreshHandlerParameters !== ''){
            var nextServiceURL = "";
            // Retrieve the first element from the array...
            nextServiceURLObject = this.postRefreshHandlerParameters.splice(0,1)[0];
            console.debug("nextServiceURLObject ",nextServiceURLObject);

            var params = [];
            if(nextServiceURLObject != null && typeof(nextServiceURLObject) != 'undefined'){
                var nextServiceURL = nextServiceURLObject["URL"],
                requestType = nextServiceURLObject["requestType"],
                requestParams = nextServiceURLObject["requestParams"];
                console.debug("nextServiceURL ", nextServiceURL);
                console.debug("requestType ", requestType);
                console.debug("requestParams ", requestParams);
            }
            if(nextServiceURL !== "") {
                if(requestType == "GET"){
                    if(nextServiceURL.indexOf("?") === -1){
                        nextServiceURL = nextServiceURL + "?" + getCommonParametersQueryString();
                    }
                    for(var key in requestParams){
                        nextServiceURL = nextServiceURL+"&"+ key +"=" + requestParams[key];
                    }
                    document.location.href = nextServiceURL;
                } else {
                    var service = getCustomServiceForURLChaining(nextServiceURL, this.postRefreshHandlerParameters);
                    wcService.invoke(service.getParam("id"), requestParams);
                }
            } else {
                console.debug("End of URL chaining with AjaxCustomServiceForURLChaining service");
            }
        }
    }

    /**
    * This is a common service which can be used to chain 'N' number of service calls.
    *
    * Input parameters to this function are:
    * @url -> The URL for this service definition.
    * @postRefreshHandlerParameters -> JSON object specifying the chained service URLS along with input parameters for each of the service URLs.
    * @formId -> Form that needs to be submitted with the initial service invocation.
    * @return -> wc.service object.
    *
    * Example code:
    *
    * // Define the initial URL
    * initialURL = 'AjaxPersonChangeServiceAddressAdd';
    *
    * // Form to be submitted along with initial request.
    * formId = "addressForm"
    *
    * // Define subsequent set of chained requests
    * var postRefreshHandlerParameters = {};
    * postRefreshHandlerParameters.push({"URL":"AjaxRESTOrderPIDelete", "requestParams":{"piId":"12345"}});
    * postRefreshHandlerParameters.push({"URL":"AjaxRESTOrderItemUpdate", "requestParams":{calculationUsage:"-1,-2,-3,-4,-5,-6,-7", calculateOrder:"1", orderItemId="10001"}});
    * postRefreshHandlerParameters.push({"URL":"DOMOrderShippingBillingView","requestType":"GET", "requestParams":{"payInStore":1}});
    *
    * // Get the service definition.
    * var service = getCustomServiceForURLChaining(initialURL,postRefreshHandlerParameters,formId);
    *
    * // Invoke the service.
    * wcService.invoke(service.id, null);
    *
    * With above sample code, the request chain will be:
    * Request 1: POST : AjaxPersonChangeServiceAddressAdd?<addressForm parameters>
    * Request 2: POST : AjaxRESTOrderPIDelete?piId=12345
    * Request 3: POST : AjaxRESTOrderItemUpdate?calculationUsage=-1,-2,-3,-4,-5,-6,-7&calculateOrder=1&orderItemId=10001
    * Request 4: GET  : DOMOrderShippingBillingView?payInStore=1
    */
    function getCustomServiceForURLChaining(url, postRefreshHandlerParameters,formId){
        var service = wcService.getServiceById('AjaxCustomServiceForURLChaining');

        if(service == null || service == undefined){
            console.debug("Start creating AjaxCustomServiceForURLChaining");
            wcService.declare({
                id: "AjaxCustomServiceForURLChaining",
                actionId: "AjaxCustomServiceForURLChaining",
                url: "",
                formId: "",
                successHandler: successHandlerForURLChainingService,
                failureHandler: commonServiceFailureHandler
            });
            service = wcService.getServiceById('AjaxCustomServiceForURLChaining');
        }

        if(url != null && url != 'undefined'){
            if (!stringStartsWith("url", "http")) {
                service.setUrl(getAbsoluteURL() + url);
            } else {
                service.setUrl(url);
            }
        }
        if(postRefreshHandlerParameters != null && postRefreshHandlerParameters != 'undefined'){
            service.setParam("postRefreshHandlerParameters", postRefreshHandlerParameters);
        } else {
            service.setParam("postRefreshHandlerParameters", null);
        }
        if(formId != null && formId != 'undefined'){
            service.setFormId(formId);
        } else {
            service.setFormId(null);
        }
        console.debug("Return AjaxCustomServiceForURLChaining", service);
        return service;
    }
	
	/**
 * This function parses the query parameters from the url
 */
function getParameterByName(name,url) {
	url = url || location.search;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
/**
 * For showing border color for invalid input fields
 * @param id
 * @return
 */
function applyInvalidErrorMessageStyle(id){
	if(!document.getElementById(id).classList.contains("invalid_input_field"))
		document.getElementById(id).className += " invalid_input_field";
}

/** to decode HTML tags, if its encoded*/
function decodeHTMLElements(str) {
	var txt = document.createElement("textarea");
	txt.innerHTML = str;
	return txt.value;
}
/**
 * Shows the error messages below the corresponding input fields .
 * @param formInput
 * @param MSG
 * @return
 */
function formatValidationErrorMessage(formInput, MSG, orderItemId){
	applyInvalidErrorMessageStyle(formInput.id);
	scrollIntoSpecificSection(formInput.id);
	if($('#isEnableMPCORedesigned').length > 0 && $('#isEnableMPCORedesigned').val() == 'true' && $(formInput).closest("form").attr("name") == "PaymentForm1"){
		if(!$(formInput).hasClass('has-error')){
			$(formInput).addClass('has-error');
		}
		if($(formInput).length > 0){
			formInput.scrollIntoView({block: "center"});
		}
	}
    var inputName = formInput.name;
    // if similar fields present against each orderItem, 
    // which is uniquely identified by its orderItemId
    if (orderItemId != null && orderItemId != undefined) {
           inputName = inputName + orderItemId;
}
    
    document.getElementById(inputName+"ErrMsg").innerHTML = decodeHTMLElements(MSG);
}
/**
 * Shows the error messages in the review error section fr the MPCO billing Address entry form.
 * @param formInput
 * @param MSG
 * @return
 */
function formatBillingFormValidationErrorMessage(formInput, MSG,formId,orderItemId){
	var errorSession = "BillingErrorSession";
	CheckoutAddressHelper.validatingFormField = formInput.name;
	if(formId.includes("shippingAddressCreateFormDiv_1") && null != orderItemId && "" != orderItemId){
		errorSession = "DeliPickupErrorContainer_" + orderItemId;
	}
	if(MSG != ""){
		if(!document.getElementById(formInput.id).classList.contains("has-error")){
			document.getElementById(formInput.id).className += " has-error";
		}
		CheckoutActionsJS.showOrHideMpcoErrorSection(errorSession,formInput,'add',MSG);
	}else{
		if(document.getElementById(formInput.id).classList.contains("has-error")){
			document.getElementById(formInput.id).classList.remove("has-error");
		}
		CheckoutActionsJS.showOrHideMpcoErrorSection(errorSession,formInput,'remove',MSG);
	}
}

/**
 * Shows the error messages below the corresponding input fields .
 * @param formInput
 * @param MSG
 * @return
 */
function validationErrorMessageUserRegistration(formInput, MSG) {
	applyInvalidErrorMessageStyle(formInput.id);
	scrollIntoSpecificSection(formInput.id);
    var inputName = formInput.name;
    $("#"+inputName+"Label").addClass('hide');
    document.getElementById(inputName+"ErrMsg").innerHTML = MSG;
}

/**
 * For clearing the form validation error messages and style.
 * @param form
 * @return
 */
function clearErrorMessages(form, orderItemId)
{
	var formElements = form.elements;
	for(i=0; i<formElements.length; i++) {
		if(formElements[i].classList.contains("invalid_input_field")){
			formElements[i].classList.remove("invalid_input_field");
		}
		if($('#isEnableMPCORedesigned').length > 0 && $('#isEnableMPCORedesigned').val() == 'true' && form.id == "PaymentForm1" && formElements[i].classList.contains("has-error")){
			formElements[i].classList.remove("has-error");
		}
		var inputName = formElements[i].name;
	    // if similar fields present against each orderItem, 
	    // which is uniquely identified by its orderItemId
	    if (orderItemId != null && orderItemId != undefined) {
	           inputName = inputName + orderItemId;
		}
		if(document.getElementById(inputName+"ErrMsg")){
			document.getElementById(inputName+"ErrMsg").innerHTML="";
		}
		
	}
}
/**
 * Shows the error messages below the corresponding input fields div.
 * @param divName
 * @param MSG
 * @return
 */
function formatValidationErrorMessageInDiv(divName,MSG){
	applyInvalidErrorMessageStyle(divName);
	document.getElementById(divName+"ErrMsg").innerHTML = MSG;
}

/**
 * Shows the error messages below the corresponding input fields div.
 * @param divName
 * @param MSG
 * @return
 */
function formatValidationErrorMessageAndScrollToDiv(divName,MSG,offsetVal){
	applyInvalidErrorMessageStyle(divName);
	document.getElementById(divName+"ErrMsg").innerHTML = MSG;
	scrollToView(divName,offsetVal);
}
/**
 * Shows the error messages below the corresponding input fields div and scroll to the div position.
 * offsetVal has been added for mobile scenario in case static header hides error message and hence need to scroll page up further.  
 */
function scrollToView(divToScroll,offsetVal){
	var offset = 0;
	if(!TSCUtils.isEmpty(offsetVal)){
		offset = offsetVal;
	}
	$('html, body').animate({
		scrollTop: $("#"+divToScroll).offset().top-offset
	}, 50);
}


/**
* For showing the status update and error messages.
 * @param message
 * @param type
 * @return
 */
function displayStatusMessages(message,type){
	if(type==0){
		//for success messages
		$("#statusMessage").removeClass().addClass("tsc_success_message");
	}else{
		//for error messages
		$("#statusMessage").removeClass().addClass("tsc_error_message");
	}
	$("#statusMessage").html(message);
	scrollIntoSpecificSection("statusMessage");
}
/**
 * For clearing the status messages.
 * @return
 */
function clearStatusMessage(){
	$('#statusMessage').addClass("hide");
}


/**
 * Changes made for e-Gift Card form validations.
 * Validating Email Address,Verify Email,Sender's Name and Recipient's Name. 
 * @return
 */
function validateEGiftCardRecipientInfo(){
	clearErrorMessages(EmailGiftCardDetailsForm);
	var namePattern = new RegExp(/^[A-z\s\-]+$/);
	var reWhiteSpace = new RegExp(/^\s+$/);
	var emailPattern = new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,8}(?:\.[a-z]{2})?)$/i);
	var dateFormat = new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/((20)[0-9][0-9])$/);
	var validDate = false;
	var errorText= "<span class='sr-only'>Error: </span>" ;
	field = document.EmailGiftCardDetailsForm.datepicker;
	if(!validateEgiftPrice()){
		return false;
	}
	var field = document.EmailGiftCardDetailsForm.gc_recipientEmail;
	if(field.id == "gc_recipientEmail"){
		var email = field.value;
		email = email.trim();
		if (email == "" || email == null || reWhiteSpace.test(email) || !emailPattern.test(email)) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_EMAIL_ADDRESS"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
					return false;
		}		
	}
	field = document.EmailGiftCardDetailsForm.gc_verifyEmail;
	if(field.id == "gc_verifyEmail"){
		var vEmail = field.value;
		vEmail = vEmail.trim();
		if (vEmail == "" || vEmail == null ||reWhiteSpace.test(vEmail) || !emailPattern.test(vEmail) ) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_EMAIL_ADDRESS"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
					return false;
		}
		var email = document.EmailGiftCardDetailsForm.gc_recipientEmail.value;
		email = email.trim();
		email = email.toLowerCase();
		vEmail = vEmail.toLowerCase();
		if (vEmail != email) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_EMAIL_ADDR_DO_NOTMATCH"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
				return false;
		}
	}
	field = document.EmailGiftCardDetailsForm.gc_toName;
	if(field.id == "gc_toName"){
		var toName = field.value;
		toName = toName.trim();
		if (toName == "" || toName == null || reWhiteSpace.test(toName)) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_RECIPIENTS_NAME_Empty"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
				return false;
		}
		if (!(namePattern.test(toName))) {
				formatValidationErrorMessage(field,
						MessageHelper.messages["ERROR_RECIPIENTS_NAME_FORMAT"]);
				return false;
		}
		if (toName.indexOf("_") >= 0) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_RECIPIENTS_NAME_FORMAT"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
				return false;
		}
	}
		field = document.EmailGiftCardDetailsForm.gc_fromName;
		if(field.id == "gc_fromName"){
			var fName = field.value;
			fName = fName.trim();
			if (fName == "" || fName == null || reWhiteSpace.test(fName)) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_SENDERS_NAME_Empty"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
				return false;
			}
			if (!(namePattern.test(fName))) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_SENDERS_NAME_FORMAT"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
				return false;
			}
			if (fName.indexOf("_") >= 0) {
				formatValidationErrorMessage(field,
					MessageHelper.messages["ERROR_SENDERS_NAME_FORMAT"]);
					$( "#"+field.id+"ErrMsg" ).prepend(errorText);
				return false;
			}	
		}
		
		  field = document.EmailGiftCardDetailsForm.pdp_datepicker;
          var dateRegex= new RegExp(/^(0[1-9]||1[0-2])\/(0[1-9]||[1-2][0-9]||3[0-1])\/((20)[0-9][0-9])$/);
          if(field.id == "pdp_datepicker"){
          var deliveryDate = field.value;
          deliveryDate= deliveryDate.trim();
          var lastAvlDate = new Date();
          //Next year current date
          var nextYearDate = new Date(lastAvlDate.setFullYear(lastAvlDate.getFullYear()+1));
          var lastValidDate =  dateFormatter(nextYearDate);
          //Current date
          var currentAvlDate = new Date();
          var currDay = new Date(currentAvlDate.setDate(currentAvlDate.getDate()-1));
          var currentDate =  dateFormatter(currDay);
          //Delivery date
          var selectedDate = new Date(deliveryDate);
          //Verify valid date or not
          validDate = TSCUtils.isValidDate(deliveryDate);
          if (((selectedDate<currentDate) || (selectedDate>lastValidDate)) || !(deliveryDate.match(dateRegex)) || !(validDate)){
			  formatValidationErrorMessage(field, MessageHelper.messages["ERROR_INVALIDDATEFORMAT"]);
			  $( "#"+field.id+"ErrMsg" ).prepend(errorText);
              return false;
          }
        }
        return true;

}
/**
 * For Enabling and disabling the AddtoCart button in quickinfo page.
 * @param
 * @return
 * 
 *  */
function disableEnableAddtoCartButton(){
	var emptyGiftForm = false;
	var eGiftCardformElements = EmailGiftCardDetailsForm.elements;
	for ( var k = 0; k < eGiftCardformElements.length; k++) {
		if ((eGiftCardformElements[k].value == "" || undefined == eGiftCardformElements[k].value)&&
				eGiftCardformElements[k].type != "hidden" &&
				eGiftCardformElements[k].id!="gc_personalizedMessage") {
			emptyGiftForm = true;
			break;
		}
	}
	if (emptyGiftForm) {
		// PDP Add to Cart Button Disabling
		if(TSCUtils.byId("add2CartBtn") && !TSCUtils.hasClassForId("add2CartBtn", "disabled")){
			TSCUtils.byId("add2CartBtn").disabled = true;
			$("#add2CartBtn").addClass("disabled");
			$("#add2CartBtn_stickydesk").addClass("disabled");						
			$("#add2CartBtn_stickydesk").prop("disabled", true);
			$("#add2CartBtn_stickymob").addClass("disabled");						
			$("#add2CartBtn_stickymob").prop("disabled", true);
		}
	} else {
		// PDP Add to Cart Button Enabling
		if(TSCUtils.byId("add2CartBtn") && TSCUtils.hasClassForId("add2CartBtn", "disabled")){
			TSCUtils.byId("add2CartBtn").disabled = false;
			$("#add2CartBtn").removeClass("disabled");
			$("#add2CartBtn_stickydesk").prop("disabled", false);					
			$("#add2CartBtn_stickydesk").removeClass("disabled");
			$("#add2CartBtn_stickymob").prop("disabled", false);					
			$("#add2CartBtn_stickymob").removeClass("disabled");
		}
		
	}
}
/**
 * For displaying the item price in Top.
 * @param Catentry ID
 * @return
 */
function setEGiftCardDisplayPrice(){
	var eGiftPrice = $('#gc_customAmount').val();
	var productId  = $('#eGiftCatalogEntryID').val();
	if(eGiftPrice != null && eGiftPrice !="" && eGiftPrice != undefined && eGiftPrice != "0" ){
		var eGiftDisplayPrice = parseFloat( (eGiftPrice * 100) / 100).toFixed(2);
		if($('#eGiftCustomDisplayPrice_'+productId).length 
					&& eGiftDisplayPrice != 'NaN' && validateEgiftPrice()){//eGiftCard Denomination
			$('#eGiftCustomDisplayPrice_'+productId).html(productDisplayJS.currencySymbol + eGiftDisplayPrice);
			$('#stickynav_offerPrice_'+productId).html( $('#eGiftCustomDisplayPrice_'+productId).html());
			$('#eGiftDefaultDisplayPrice_'+productId).hide();
			$('#eGiftCustomDisplayPrice_'+productId).show();
		}
	}else{
		$('#eGiftCustomDisplayPrice_'+productId).text($('#eGiftDefaultDisplayPrice_'+productId).text());
		$('#stickynav_offerPrice_'+productId).html( $('#eGiftCustomDisplayPrice_'+productId).html());
	}
}
function setEGiftCardDeliveryDate(){

	var dateRegex= new RegExp(/^(0[1-9]||1[0-2])\/(0[1-9]||[1-2][0-9]||3[0-1])\/((20)[0-9][0-9])$/);
	field = document.EmailGiftCardDetailsForm.pdp_datepicker;
	var deliveryDate = field.value;
	deliveryDate= deliveryDate.trim();
	if(field.value ==""){
		var currentDate = new Date();
		$("#pdp_datepicker").datepicker({
			dateFormat: 'mm/dd/yy',
			minDate:0,
			maxDate: '+1y'
			});
		$("#pdp_datepicker").datepicker("setDate", currentDate);
		$("#ui-datepicker-div").addClass("ui-datepicker-container");
	}
	else if(!(deliveryDate.match(dateRegex))){
            formatValidationErrorMessage(field,MessageHelper.messages["ERROR_INVALIDDATEFORMAT"]);
            return false;
			}

}
/**
 * For displaying the Custom Price as User selected drop down value.
 * @param selectedDenomination 
 * @return
 */
function setGiftCardCustomPrice(selectedDenomination){
	if(selectedDenomination != null && selectedDenomination !="" && selectedDenomination != undefined && selectedDenomination != "0" ){
		$('#gc_customAmount').val(parseFloat((selectedDenomination * 100) / 100).toFixed(2));
	}
	if(selectedDenomination != null && selectedDenomination !="" && selectedDenomination != undefined && selectedDenomination == "0"){
		$('#gc_customAmount').val("");
	}
}
/**
 * For validating the custom amount entered and formatting to two decimal places.
 * @param  
 * @return
 */
function validateEgiftPrice(){
	var pricePattern = /^\d*\.{0,1}\d{0,2}$/;
	var field = document.EmailGiftCardDetailsForm.gc_customAmount;
	var customValue = $('#'+field.id).val();
	var gcMinPrice	= $('#EGiftCardMinPrice').val();
	var gcMaxPrice	= $('#EGiftCardMaxPrice').val();
	var productId = $('#eGiftCatalogEntryID').val();
	var errorText= "<span class='sr-only'>Error: </span>" ;
	
	$('#'+field.id).removeClass("invalid_input_field");
	$('#'+field.id + "ErrMsg").html("");
	
	if (!(pricePattern.test(customValue))||"" == customValue || undefined == customValue || null == customValue) {
		formatValidationErrorMessage(TSCUtils.byId(field.id),
				MessageHelper.messages["ERROR_EGC_PRICE_RANGE"]);
		$('#eGiftCustomDisplayPrice_' + productId).text($('#eGiftDefaultDisplayPrice_' + productId).text());
		$('#'+field.id + "ErrMsg").prepend(errorText);
		return false;
	}else{
		customValue	= parseFloat((customValue * 100) / 100).toFixed(2);
		gcMinPrice	= parseFloat((gcMinPrice * 100) / 100).toFixed(2);
		gcMaxPrice	= parseFloat((gcMaxPrice * 100) / 100).toFixed(2);
		if("NaN" == customValue){
			formatValidationErrorMessage(TSCUtils.byId(field.id),
					MessageHelper.messages["ERROR_EGC_PRICE_RANGE"]);
			$('#eGiftCustomDisplayPrice_' + productId).text($('#eGiftDefaultDisplayPrice_' + productId).text());
			$('#'+field.id + "ErrMsg").prepend(errorText);
			return false;
		}else{
			$('#'+field.id).val(customValue);
		}
	}
	if(parseFloat(customValue) < parseFloat(gcMinPrice) || parseFloat(customValue) > parseFloat(gcMaxPrice)){
		formatValidationErrorMessage(TSCUtils.byId(field.id),
				MessageHelper.messages["ERROR_EGC_PRICE_RANGE"]);
		$('#eGiftCustomDisplayPrice_' + productId).text($('#eGiftDefaultDisplayPrice_' + productId).text());
		$('#'+field.id + "ErrMsg").prepend(errorText);
		return false;
	}
	return true;
}
/**
 * For resetting the drop down values as Custom Amount.
 * @param  catalogEntryID
 * @return
 */
function resetDropDown(){
	$('#gc_denomination').val("0");
}
/**
 * For making the custom field as decimal number with two decimal places only.
 * @param el, evt
 * @return
 */

function isNumberKey(el, evt) {
    var charCode = (evt.which) ? evt.which : event.keyCode;
    var number = el.value.split('.');
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    //just one dot allowed
    if(number.length>1 && charCode == 46){
         return false;
    }
    //get the carat position
    var caratPos = getSelectionStart(el);
    var dotPos = el.value.indexOf(".");
    if( caratPos > dotPos && dotPos>-1 && (number[1].length > 1)){
    	if(charCode == 8){//allowing the Backspace
    		return true;
    	}else{
    		return false;
    	}
    }
    return true;
}
/**
 * For getting the carat position.
 * @param o
 * @return
 */
function getSelectionStart(o) {
    if (o.createTextRange) {
        var r = document.selection.createRange().duplicate()
        r.moveEnd('character', o.value.length)
        if (r.text == '') return o.value.length
        return o.value.lastIndexOf(r.text)
    } else return o.selectionStart
}
function validatepMessage(evt) {
	var v=(evt.which) ? evt.which : evt.keyCode;
	if (v == 38 || v == 39 || v == 34 || v == 62 || v == 60 || v == 47){
		return false;
	} else {
		return true;
	}
	}
function GetIEVersion() {
	  var sAgent = window.navigator.userAgent;
	  var Idx = sAgent.indexOf("MSIE");

	  // If IE, return version number.
	  if (Idx > 0) 
	    return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));

	  // If IE 11 then look for Updated user agent string.
	  else if (!!navigator.userAgent.match(/Trident\/7\./)) 
	    return 11;

	  else
	    return 0; //It is not IE
	}
	function pastevalidationpMessage(orderid){
		var pMessage=$('#gc_personalizedMessage_'+orderid).val();
		var formattedMessage=pMessage.replace(restrictedCharRegex, '');
		var sMessage=formattedMessage.replace(smileyRegex, '');		
		$('#gc_personalizedMessage_'+orderid).val(sMessage);
		return true;
	}
	function pastevalidationpMessagePDP(message){
		var pMessage=message.replace(restrictedCharRegex, '');
		var sMessage=pMessage.replace(smileyRegex, '');
		$("#gc_personalizedMessage").val(sMessage);
	}
	function pastevalidationpPromoMessagePDP(message){
		var pMessage=message.replace(restrictedCharRegex, '');
		var sMessage=pMessage.replace(smileyRegex, '');
		$("#gcPromo_personalizedMessage").val(sMessage);
	}
	function check(orderid){
		var pMessage=TSCUtils.byId('gc_personalizedMessage_'+orderid).value;
		var formattedMessage=pMessage.replace(/\\n/g,"<br />");
		$('#msg_'+orderid).val(formattedMessage);
	}
	/**
	 * Function will remove restricted special characters and emojis 
	 * while typing or copy paste for passed input id and update the modified value to passed div id
	 */
	function validateAndClearSpecialCharacters(message,currentDivId,formId){
		var isEnabledAllowSpecialCharsInAddress = $("#isEnabledAllowSpecialCharsInAddress").length ?  $("#isEnabledAllowSpecialCharsInAddress").val() : false;
		var sMessage = message;
		if(isEnabledAllowSpecialCharsInAddress == "true" && typeof formId != 'undefined' && (formId.includes("shippingAddressCreateFormDiv_1") || formId.includes("shippingAddressCreateFormDiv") || formId.includes("BillingAddress") || formId.includes("BillingAddressModal"))){
			validateRestrictedSplChars(message,currentDivId,formId);
		}
		else {
			if(message!="" && message!= null && typeof message != 'undefined' ){
				sMessage = clearSpecialCharacters(clearSpecialCharacters(message,matchRestrictedSymbolsRegex),restrictedAddressCharRegex);
			}
			if (formId != null && formId != "" && typeof formId != 'undefined') {
				$("#" + formId + " :input[id=" + currentDivId + "]").val(sMessage);
			} else {
				$('#'+currentDivId).val(sMessage);
			}
		}
		return sMessage;
	}
		 
	/**
	 * Function will remove restricted special characters and emojis 
	 * while typing or copy paste for passed input id and update the modified value to passed div id
	 */
	function validateRestrictedSplChars(message,currentDivId,formId){
	 
		var regExpTester = new RegExp(restrictedAddressCharRegex);  
		var field = currentDivId;
		var orderItemId = null;
		var form = document.forms[formId]; 	
		CheckoutActionsJS.lastEditedAddLine = true;
		CheckoutActionsJS.isBillingValid = true;
		var isSPCO = ($("#currentPageName").length && $("#currentPageName").val() == "SingleCheckoutPage")? true : false;
		 if(currentDivId.includes('address1')){
			 field = (currentDivId == "address1Billing") ? 'address1Billing' : 'address1';
			if(formId.includes("shippingAddressCreateFormDiv_1")){
				orderItemId = form['orderItemId'].value;
			}
		}
		else if(currentDivId.includes('address2')){
			field = (currentDivId == "address2Billing") ? 'address2Billing' : 'address2';
			if(formId.includes("shippingAddressCreateFormDiv_1")){
				orderItemId = form['orderItemId'].value;
			}
		}
		var specialCharRestErrMsg = ""; 
		if(field == "address2"){
			specialCharRestErrMsg =  MessageHelper.messages["SPECIAL_CHARS_RESTRICTION_ADDR2_REDESIGN"];
		}else{
			specialCharRestErrMsg =  MessageHelper.messages["SPECIAL_CHARS_RESTRICTION_ADDR1_REDESIGN"];
		}
		if(!isSPCO && (form['action'].value == "addNewAddress" || form['action'].value == "editAddress")){
			orderItemId = null;
		}
		if(regExpTester.test(message)){				
			$(form).find('label[for="' + form[field].id + '"]').addClass("hide");
			if(isSPCO){
				if(formId.includes("BillingAddress")){
					CheckoutActionsJS.isBillingValid = false;
				}	
				CheckoutActionsJS.lastEditedAddLine = (field == "address2" || field ==  "address2Billing") ? false : true;
				CheckoutActionsJS.formatValidationErrMsgCheckout(form[field], MessageHelper.messages["CHECKOUT_SPECIAL_CHARS_RESTRICTION"], orderItemId);
				CheckoutActionsJS.setErrorMsgForADACheckout(form,field,orderItemId);
				if(CheckoutActionsJS.isPlaceOrderCTAFeatureEnabled){
						$("#BillingSectionErrorHolder").addClass("hide");				
					}
			}
			else{
				if($("#isEnableMPCORedesigned").length && $("#isEnableMPCORedesigned").val() == "true" && (formId.includes("shippingAddressCreateFormDiv_1") || formId.includes("BillingAddress"))){
					$(form).find('label[for="' + form[field].id + '"]').removeClass("hide");
					formatBillingFormValidationErrorMessage(form[field], specialCharRestErrMsg,formId,orderItemId);
				}else{
					formatValidationErrorMessage(form[field], MessageHelper.messages["CHECKOUT_SPECIAL_CHARS_RESTRICTION"], orderItemId);
				}
			}
		}
		else{		
			if(isSPCO){
				if(formId.includes("BillingAddress")){
					CheckoutActionsJS.isBillingValid = true;
				}
				CheckoutActionsJS.lastEditedAddLine = (field == "address2" || field ==  "address2Billing") ? true : false;
				CheckoutActionsJS.formatValidationErrMsgCheckout(form[field], '', orderItemId);
				$(form).find('label[for="' + form[field].id + '"]').removeClass("hide");
				$(form).find(form[field].id).removeClass('invalid_input_field') ;
			}
			else{
				if($("#isEnableMPCORedesigned").length && $("#isEnableMPCORedesigned").val() == "true" && (formId.includes("shippingAddressCreateFormDiv_1") || formId.includes("BillingAddress"))){
					formatBillingFormValidationErrorMessage(form[field], '',formId,orderItemId);
				}else if(formId.includes("BillingAddress") || formId.includes("BillingAddressModal")){
					if (orderItemId != null && orderItemId != undefined) {
				    	form[field].name = form[field].name + orderItemId;
				    }
				    document.getElementById(form[field].name+"ErrMsg").innerHTML = decodeHTMLElements('');
				    $(form).find(form[field].id).removeClass('invalid_input_field') ;
				    if(document.getElementById(form[field].id).classList.contains("invalid_input_field"))
						document.getElementById(form[field].id).classList.remove("invalid_input_field");
				}
				else{
				formatValidationErrorMessage(form[field], '', orderItemId);
				}
				$(form).find('label[for="' + form[field].id + '"]').removeClass("hide");
			}
		}
	}
	/**
	 * Function will remove emojis 
	 * while typing or copy paste for passed input id and update the modified value to passed field id
	 */
	function validateAndClearEmojis(message, currentFieldId){
		var sMessage = message;
		if(message!="" && message!= null && typeof message != 'undefined' ){
			sMessage = clearSpecialCharacters(message,matchRestrictedSymbolsRegex);
			if(document.getElementById(currentFieldId)!= null && document.getElementById(currentFieldId)!=""){
				document.getElementById(currentFieldId).value=sMessage;
			}
		}
		return sMessage;
	}
	/**
	 * Function will clear matching characters for passed regex pattern and return modified string
	 */
	function clearSpecialCharacters(actualString,regexPatternParam) {
		var filteredString =actualString;
		if(actualString!="" && actualString!= null && typeof actualString != 'undefined'){
			 filteredString = actualString.replace(regexPatternParam,'');
		}
		return filteredString;
	}
 
 Object.keys = Object.keys || function keys(obj) {	  
	 var ret = [];
	 for (var prop in obj) {
		 if (obj.hasOwnProperty(prop)){ 
			 ret.push(prop)
		}
	 }
	  return ret;
 }
  
 Array.isArray = Array.isArray || function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
 };
 
 /**
  * This method decodes the input string's HTML angular braces
  */
 function decodeHTMLtags(inputString){
	 return inputString.replace(/&#60;/g, '<').replace(/&#62;/g, '>').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&#34;/g,'\'').replace(/&#39;/g,'\"');
 }
 /**
  * This method invokes the  call for create/update TaxExempt customer
  * flow . The flow redirects the customer to Ryan Control point portal .
  */
 function applyForTaxExempt(){	 
	   var params = [];
	   params.successURL = location.href;
	   params.timeoutURL = location.href;
	   wcService.invoke("AjaxAddTaxExemption", params);	
 }
 
  function setCommonTrackingParams(partNumber) {
	  	document.getElementById('partNumHidden').value=partNumber;
 		HeaderJs.refkATCObj.product_id[0] = TSCUtils.getValifexists("partNumHidden_"+partNumber);
 		HeaderJs.refkATCObj.product_sku[0] = TSCUtils.getValifexists("childPartNumberHidden_"+partNumber);
 		HeaderJs.refkATCObj.product_price[0] =  TSCUtils.getValifexists("ProductInfoPrice_"+partNumber);	
 		HeaderJs.refkATCObj.source = TSCUtils.getValifexists("data_source");
		HeaderJs.refkATCObj.product_index = TSCUtils.getValifexists("partNumClickIndex_"+partNumber);
		HeaderJs.refkATCObj.search_term_type = TSCUtils.getValifexists("searhTerm_original_or_modified");
		HeaderJs.refkATCObj.keyword = TSCUtils.getValifexists("search_term_search_TI");
 }	 
  function setTeliumParam(partNumber,requestFromVar){
      try {
             setCommonTrackingParams(partNumber);
             var rfkPageType = utag_data.page_type;
             if(!TSCUtils.isNullObj(TSCUtils.getValifexists("prodNameHidden_"+partNumber))){
                           HeaderJs.refkATCObj.product_name[0] =  TSCUtils.getValifexists("prodNameHidden_"+partNumber);
              }
             if( !TSCUtils.isNullObj (requestFromVar) && requestFromVar === 'a2c' ) {
                    if(HeaderJs.refkATCObj.event_name == "") {
                          HeaderJs.refkATCObj.event_name = rfkPageType;
                    }
                    
                     return;
             }
             if (!TSCUtils.isNullObj (rfkPageType)) {
                    HeaderJs.refkATCObj.event_name = rfkPageType;
                    if(rfkPageType == 'home' || rfkPageType == 'category' || rfkPageType == 'product' || rfkPageType == 'cart' ){
                          if(rfkPageType == 'product') {
                                 rfkPageType =       (typeof requestFromVar != 'undefined' && requestFromVar === 'pdp1') ? requestFromVar : "pdp2";
                          } 
                           HeaderJs.refkATCObj.event_name = "rec_"+rfkPageType;
                    }
             } else {
                    //return;
             }
      HeaderJs.refkATCObj.product_sku = [];
      trackClick("product_click");
      } catch(err) {
             console.log("Error setting analytics data");
      }
}


  function trackClick(eventType) {
      try{
             if((eventType === "product_click" || eventType === "a2c") &&  HeaderJs.refkATCObj.event_name === "search"){
                    var sort_applied = SearchBasedNavigationDisplayJS.orderByIndex > 0 ? "Y" : ""; 
                    var filter_applied = SearchBasedNavigationDisplayJS.getEnabledProductFacets()[0].length ? "Y" : "";
                    var category_name = "";
                    var search_page_type = "Keyword Search";
                    if (!TSCUtils.isNullObj($("#lastcategoryname_TI").val())){
                    	category_name =  $("#lastcategoryname_TI").val();
                    	search_page_type = "";
                    } else {
                    	if($("#customSearchPage").length){
                            search_page_type = "Custom Search";                  
	                      } else if ($("#brandSearchPage").length){
	                            search_page_type = "Brand";
	                      } else if (TSCUtils.getParameterByName("isVoice") === "Y"){
	                            search_page_type = "Voice Search";
	                      } else if (TSCUtils.getParameterByName("imgSearch") === "Y"){
	                            search_page_type = "Image Search";
	                      } else if(HeaderJs.refkATCObj.keyword == "**" || HeaderJs.refkATCObj.keyword.indexOf(" | ") > 0 || TSCUtils.getParameterByName("cm_mmc") != "" || 
	                                      TSCUtils.getParameterByName("cm_sp") != "" || TSCUtils.getParameterByName("cm_re") != ""){
	                            search_page_type = "Marketing";
	                      } 
                    }
                    
                    utag.link({
                           "event_type" : eventType,
                           "event_name" : HeaderJs.refkATCObj.event_name,
                           "product_id" : HeaderJs.refkATCObj.product_id,
                           "search_term" : HeaderJs.refkATCObj.keyword,
                           "product_sku": HeaderJs.refkATCObj.product_sku,
                           "product_quantity":HeaderJs.refkATCObj.product_quantity,
                           "product_price" :HeaderJs.refkATCObj.product_price,
                           "data_source" : HeaderJs.refkATCObj.source,
                           "product_index" : HeaderJs.refkATCObj.product_index,
	                      "search_term_type" : HeaderJs.refkATCObj.search_term_type,
	                      "product_name" : HeaderJs.refkATCObj.product_name,
	                      "sort_applied" : sort_applied,
	                      "filter_applied" : filter_applied,
	                      "search_page_type" :search_page_type,
	                      "category_name" : category_name
                    });
             } else {
                    utag.link({
                           "event_type" : eventType,
                           "event_name" : HeaderJs.refkATCObj.event_name,
                           "product_id" : HeaderJs.refkATCObj.product_id,
                           "search_term" : HeaderJs.refkATCObj.keyword,
                           "product_sku": HeaderJs.refkATCObj.product_sku,
                           "product_quantity":HeaderJs.refkATCObj.product_quantity,
                           "product_price" :HeaderJs.refkATCObj.product_price,
                           "data_source" : HeaderJs.refkATCObj.source,
                          "product_index" : HeaderJs.refkATCObj.product_index,
                          "search_term_type" : HeaderJs.refkATCObj.search_term_type,
                          "product_name" : HeaderJs.refkATCObj.product_name
                    });
             }
      } catch (err) {
             console.debug("Err while sharing analytics data");
      }
}

 TSCUtils.subscribe("IMAGE_LAZYLOAD_EVENT", function(){
	 if($('#isPDP').length == 0){
		 var imgMap = TSCUtils.getElementsbyAttr("data-src");
		 for (var i=0; i<imgMap.length; i++) {
			 if(TSCUtils.isElementVisible(imgMap[i])) {
				 imgMap[i].setAttribute('src',imgMap[i].getAttribute('data-src'));
				 imgMap[i].removeAttribute('data-src');
			 }
		 } 
	 }
});
$(function() {
  TSCUtils.publish("IMAGE_LAZYLOAD_EVENT");
});
$(window).scroll(function() {
    TSCUtils.publish("IMAGE_LAZYLOAD_EVENT");
});

function isElemVisibleJQuery(id, offsetVal){
	if($("#"+id).length === 0) {
		return false;
	}
	var TopView = window.pageYOffset ; 
	var BotView = TopView + window.innerHeight;
	// To return true before 100px of the emelment visibility
	offsetVal= !!offsetVal ?  offsetVal: 100;
	var TopElement = $("#"+id).offset().top - offsetVal;
	var BotElement = TopElement + $("#"+id).height();
	return ((BotElement <= BotView) && (TopElement >= TopView));
}
	

function hideOrDisplayDivs (displayAction, query){
	var pageDivs = $(query);
	for ( var i = 0; i < pageDivs.length; i++) {
		if (pageDivs[i] != null) {
			pageDivs[i].style.display=displayAction;
		}
	}
}


function formatProductPrice(priceDisplayed) {
	var formattedPriceDisplay;
	var formattedPriceDisplayFinal;
	var formattedRangePriceDisplay;
	var formattedRangePriceDisplayFinal = "";
	if(priceDisplayed && priceDisplayed != '$NaN'){
		// for range price scenario
		if(priceDisplayed.indexOf('-') != -1){
			if(priceDisplayed.indexOf('$') != -1){
				priceDisplayed = priceDisplayed.replace("$","");
			}
			formattedRangePriceDisplay = priceDisplayed.split("-");
			for(i=0;i<formattedRangePriceDisplay.length;i++){
				if(formattedRangePriceDisplay[i].indexOf('.') != -1){
					formattedPriceDisplay=formattedRangePriceDisplay[i].split(".");
					formattedPriceDisplay[0] = parseInt(formattedPriceDisplay[0]).toLocaleString();
					formattedPriceDisplay = formattedPriceDisplay.join(".");
				}
				else{
					formattedPriceDisplay = parseInt(formattedRangePriceDisplay[i]).toLocaleString();
				}
				if(i==1){
					formattedPriceDisplay = "-$" + formattedPriceDisplay; 
				}	
				formattedRangePriceDisplayFinal += formattedPriceDisplay;
			}
			formattedPriceDisplayFinal = formattedRangePriceDisplayFinal;
		}
		// for single price scenario
		else{
			if(priceDisplayed.indexOf('.') != -1){
				formattedPriceDisplay=priceDisplayed.split(".");
				formattedPriceDisplay[0] = parseInt(formattedPriceDisplay[0]).toLocaleString(); 
				formattedPriceDisplay = formattedPriceDisplay.join(".");
			}
			else{
				formattedPriceDisplay = parseInt(priceDisplayed).toLocaleString();
			}
			formattedPriceDisplayFinal = formattedPriceDisplay;
		}
		return formattedPriceDisplayFinal;	
	}
	else{
		return priceDisplayed;
	}
}

// Function created to get current and next date in format MM/DD/YYYY format
function dateFormatter(date)
{
	var month = '' + (date.getMonth() + 1),day = '' + date.getDate(),year = date.getFullYear();
    if (month.length < 2)
          month = '0' + month;
    if (day.length < 2) 
          day = '0' + day; 
    var dateFormat =month+"/"+day+"/"+year;
    var formattedDate = new Date(dateFormat);
    return formattedDate;

}
/*
 * This method is used to trigger the modal with dynamic contents.
 * eg: 
 * eSpotName = "SHIPPINGRESTRICTION_TOOLTIP"
 * reqESpotParams = "?DM_SubstitutionName1=[statesList]&DM_SubstitutionValue1=TN,AK,HI&DM_marketingSpotBehavior=0";
 * */
function showTscInfoModal (eSpotName, reqESpotParams){
	if(undefined == eSpotName || "" == eSpotName){
		return;
	}		
	var espotParams = "";
	if(undefined != reqESpotParams && "" != reqESpotParams) {
		espotParams = encodeURI(reqESpotParams);
	}
	displayProgressBar();
	$("div#tsc_modal div#tscModalContent").text("");
	var requestURL = location.protocol + "//" + location.host + "/wcs/resources/store/10151/espot/" + eSpotName + espotParams;
	$.ajax({
	    url : requestURL,
	    type : 'GET',
	    cache: false,
	    dataType:'json',
	    success : function(data) { 
			cursor_clear(true);
	    	try {
				$("div#tsc_modal div#tscModalContent").html(data.MarketingSpotData[0].baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText);
				if(!(document.getElementById('disclosure') == null && document.getElementById('finplanDisclosure') == null)){
					$("#disclosure").html(document.getElementById('finplanDisclosure').value);
				}
				var inStoreOrder = TSCUtils.getCookie("inStoreOrder");
			  var stockyardApplyEnabled = TSCUtils.byId("ishiddenStockyardApplyNBuyEnabled").value;
				if(inStoreOrder!="" && inStoreOrder!=null && inStoreOrder!=undefined && stockyardApplyEnabled =="false"){
					$("#applyNowBtn").addClass("hide");
				}else{
					$("#applyNowBtn").removeClass("hide");
				}
			} catch(e){
		    	console.log("error occured when info modal opens::success---> "+e)				
			}
			$('#tsc_modal').modal();
	    },
	    error : function(request,error) {
	    	cursor_clear(true);
	    	try {
	    		var errorResponse = jQuery.parseJSON(request.responseText)
		    	if(errorResponse.errors[0].errorCode == 'CWXFR0223E'){
		    		window.location.reload();
	    		}
	    	} catch(e){
		    	console.log("error occured when info modal opens::failure---> "+e)	
			}
	    }
	});
	
}

/**
 * This method used to track the completed tax exempt registration in cart/billing/tax exempt page.
 */
function tlmTaxExemptOnLoad(){
	 var currentURL = window.location.search;
	 if(currentURL.indexOf('successTXFlow=true') > 0) {
		 CheckoutHelperJS.taxExemptionEventTracking("2");
		 window.history.replaceState(currentURL, "Title", currentURL.replace("&successTXFlow=true", ""));
	 }
}
/**
 * This function is used to display magic link fall back error message.
 */	
function displayMagicLinkFallBackErrorMsg() { 
	 var currentURL = window.location.search;
	 var singlePageCheckout = $("#isSinglePageCheckoutEnabled").val(); 
	 if(currentURL.indexOf('timeoutTXFlow=true') > 0) {
		 $('#cart-error-info, #display_removedMsg').removeClass("hide");
		 $('#cart-error-info').html(MessageHelper.messages["ERROR_RYAN_SERVICE_MSG"]);
		 if(TSCUtils.isNullObj(singlePageCheckout) && singlePageCheckout == 'true'){
			 $('#display_removedMsgContent').html(MessageHelper.messages["ERROR_RYAN_SERVICE_MSG"]);
		 } else {
			 $('#display_removedMsg').html(MessageHelper.messages["ERROR_RYAN_SERVICE_MSG"]);
		 }
		 $('#cart-error-info, #display_removedMsg').html(MessageHelper.messages["ERROR_RYAN_SERVICE_MSG"]);
		 window.history.replaceState(currentURL, "Title", currentURL.replace("&timeoutTXFlow=true", ""));
	 }
}
/**
 * Function to convert string to title case.
 */
function titleCase(str) {
	  return str.toLowerCase().split(' ').map(function(word) {
	    return (word.charAt(0).toUpperCase() + word.slice(1));
	  }).join(' ');
}
/**
 * Function to expand or collapse the section.
 */
function expandOrCollapseSection(elementId){
	if ($("#"+elementId).hasClass("closed")) {
	  	$("#"+elementId).parent().find(".open-close-target").removeClass("hide");
	  	$("#"+elementId).attr("aria-expanded", "true");
	}
	else if (!$("#"+elementId).hasClass("closed")) {
	    $("#"+elementId).parent().find(".open-close-target").addClass("hide");
	    $("#"+elementId).attr("aria-expanded", "false");
	}
	$("#"+elementId).toggleClass("closed");
}
/**
 * Shows the error message and scroll to this div.
 * @param elementId
 * @param errMsg
 * @param type
 * @param value
 */
function displayErrorMessageAndScrollToDiv(elementId, errMsg, type, value){
	if(document.getElementById(elementId) && !TSCUtils.isEmpty(errMsg)){
		if(elementId == 'display_removedMsg'){
			if(!TSCUtils.isNullObj(document.getElementById('display_removedMsgContent'))){
				document.getElementById('display_removedMsgContent').innerHTML = errMsg;
			}else{
				document.getElementById(elementId).innerHTML = errMsg;
			}
		} else {
			document.getElementById(elementId).innerHTML = errMsg;
		}
		if(!TSCUtils.isEmpty(type) && !TSCUtils.isEmpty(value)) {
			if(type == "style") {
				document.getElementById(elementId).style.display = value;
			} else if(type == "class"){
				document.getElementById(elementId).classList.remove(value);
			}
		}
		scrollIntoSpecificSection(elementId);
	}
}
/**
 * Function scroll into specific element section.
 */
function scrollIntoSpecificSection(elementId){
	if(document.getElementById(elementId)){
		document.getElementById(elementId).scrollIntoView({block: "center"});
	}
}
/**
 * Function to limit the number of char for a field
 */
function limitKeypress(event, value, maxLength, currentInstance, form, field) {
    if (value != undefined && TSCUtils.isMobile()) {
    	var startPos = currentInstance.selectionStart;
    	var endPos = currentInstance.selectionEnd;
    	if(value.toString().length >= maxLength 
    			&& event.keyCode != 8 //backspace button
    			&& event.keyCode != 46 // delete button
    			&& (startPos === endPos)){
    		document.forms[form][field].value = value.substring(0, maxLength);
    		event.preventDefault();
			return;
    	}        
    }
}
function setADAInertForModal(inertFlag, currentModalId) {
	
	var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";

	if ((pageType == "PDP" || pageType == "PLP" || pageType == "search") && inertFlag) {
		var inertAttr = document.querySelectorAll('.inertAttr');
		$(inertAttr).each(function(index, element) {
			this.inert = true;
		});
		var inertApplied = false;
		$("#" + currentModalId).find("div.modal-content *").each(
				function(index, element) {
					var currentElement = $(element).attr("tabindex");
					if (undefined != currentElement && currentElement == "-1") {
						$(element).attr("tabindex", "0");
						inertApplied = true;
					}
				});
		if (inertApplied) {
			$("#" + currentModalId).addClass("enableModalAccess");
		}

	} else {
		var inertAttr = document.querySelectorAll('.inertAttr');
		$(inertAttr).each(function(index, element) {
			this.inert = "";
		});
		$("#" + currentModalId).removeClass("enableModalAccess");
	}
}
	
// IOS scroll issue
   $(document).on('touchstart', '.ui-datepicker-next,.ui-datepicker-prev', function (event) {
    event.preventDefault();
    return false;
});
$(window).on('shown.bs.modal', function (e) {
	console.debug("modal id to open:"+e.target.id);
	if(e.target.id != 'progress_bar_dialog'){
		if(isIOS()){
			$('body').attr('data-pos', $(window).scrollTop()); 
			$("html").css("position", "fixed");
		}
		//adding class to identify android tab devices to fix scrolling issue in landscape mode
		if(isAndroid() && !TSCUtils.isMobile()) {
			$("#"+e.target.id).find(".modal-body.generic").addClass("android-tab");
		}
		$("body, html").addClass("modal-open");
        
        if ($("#add2CartBtn").hasClass("viewcart")) {
            $(".modal-body").addClass("has_sub");
        }
		
		if (e.target.id == 'addToCartModal') {	// Timeout for Interstitial modal
	         setTimeout(function () {
	             $('#addToCartModal').modal('hide');
	         },$("#interstitialAutoClose").val());
		} else if (e.target.id == 'fulfillmentModal') {	// Timeout for Fulfillment Status modal
			setTimeout(function () {
	             $('#fulfillmentModal').modal('hide');
	         },$("#fulfillmentAutoClose").val());
		}
		setADAInertForModal(true, e.target.id);
	}
});
$(window).on('hidden.bs.modal', function (e) {
	if(e.target.id != 'progress_bar_dialog'){
		cursor_clear(true);
		if(e.target.id == 'tsc_modal' && $("#parentModalId").length > 0 && "" != trim($("#parentModalId").val())){
			$("#"+$("#parentModalId").val()).removeClass("d-none");
			$("#parentModalId").val("");
		} else {
			$("body, html").removeClass("modal-open");
			if(isIOS()){
				$("html").css("position", "");
				$(window).scrollTop($('body').attr('data-pos'));
			}
		}
		if (e.target.id == 'addToCartModal') {
			CheckoutActionsJS.fromPage = ""; 
		}
		setADAInertForModal(false, e.target.id);		
	}
});

function trimFormData(a, b, c) {
    document.forms[a][b].value = c.trim()
}

/**
 * This method is invoked when user clicks on a category link on header
 * in desktop or mobile
 * @param url
 */
function trackCategoryClick(url){
	try{
		utag.link({
			'link_href' : url
		});
	} catch(e){
		console.log('Error with Manual Link Click tagging');
	}
}
/**
 * Ployfill for endsWith function to make it work on IE.
 */
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

function addAsyncJSSrc(src, callback, async) {
	if (typeof src != 'undefined' && src != '') {
		var jsSrc = [];
		jsSrc['src']= src;
		if (typeof callback == 'undefined') {
			callback = '';
		}
		jsSrc['callback'] = callback;
		if (typeof async == 'undefined') {
			async = true;
		}
		jsSrc['async'] = async;
		asyncJavascriptSource.push(jsSrc);
	}
}

var asyncLoadJS;
asyncLoadJS = function() {
	if (asyncJavascriptSource != null && asyncJavascriptSource.length > 0 && document.readyState=="complete"){
		asyncJavascriptSource.forEach(function(jsFile) {
		  var script = document.createElement('script');
		  script.src = jsFile.src;
		  script.async = jsFile.async;
		  if(jsFile.callback != '') {
			  var callback = jsFile.callback;
			  script.onload = script.onreadystatechange = function() {
				  callback();
				  script.onload = null;
				  script.onreadystatechange = null;
			  };
		  }
		  document.head.appendChild(script);
		});
	}
}

if (document.readyState !== "complete") {
    // The load event fires when all resources have finished loading, which allows detecting gigya elements.
    window.addEventListener("load", asyncLoadJS, false);
} else {
    // No need to add a listener if the document is already loaded, initialize immediately.
	asyncLoadJS();
}

function setPageName(pageType){
	var pageName = "n/a";
	if(pageType == "CategoryPage"){
		pageName = "category page";
	} else if(pageType == "PLP"){
		pageName = "product list page";
	} else if(pageType == "search"){
		var brandSearchPage = $("#brandSearchPage").length > 0 ? $("#brandSearchPage").val() : "";
        if(brandSearchPage == "true") {
            pageName = "brand list page";
        } else {
            pageName = "internal search";
        }
        
    } else if(pageType=="PDP"){
		pageName="product details page";
	} else if(pageType=="HomePage"){
		pageName="home page";
	} else if(pageType=="ShoppingCart"){
		pageName="shopping cart";
	} else if(pageType=="StoreDetailsDisplay"){
		pageName="store details page";
	} else if(pageType=="compare"){
		pageName="product compare";
	} else if(pageType=="Payment"){
		pageName="payment page";
	} else if(pageType=="WishList"){
		pageName="wishlist";
	} else if(pageType=="storeLocator"){
		pageName="store locator";
	} else if(pageType=="confirmation"){
	  	pageName="confirmation page";
	} else if(pageType=="delivery"){
	  	pageName="delivery page";
	} else if(pageType=="confirmation"){
		pageName="confirmation page";
	} else if(pageType=="gift card"){
		pageName="gift card";
	}
	return pageName;
}   
function setLinkClickEvent(linkClickName, moduleName){
	try {
		if(undefined == moduleName){
			var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
			moduleName = setPageName(pageType);
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'link click',
			content : {
				module: moduleName.toLowerCase(),
				name: linkClickName.toLowerCase()
			}
		});
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	} 
}

//save for later adobe analytics - click event
function setSaveForLaterClickEvent(linkClickName,skuNo){
	try {
		if (undefined == skuNo || null == skuNo){
			skuNo = "";
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'link click',
			content : {
				module: 'Save For Later',
				name: linkClickName.toLowerCase(),
				sku : skuNo
			}
		});
	} catch (e){
		console.log('Exception in handling Adobe Analytics for save for later :: '+e.stack);
	} 
}
//save for later adobe analytics - click event- cart add
function setSaveForLaterClickScanItemEvent(linkClickName,skuNo,isScanItemEligible){
	try {
		if (undefined == skuNo || null == skuNo){
			skuNo = "";
		}
		var productType = "";
		if (isScanItemEligible == "true") {
			productType = "scanbased";
		} else {
			productType = "regular";
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'link click',
			content : {
				module: 'Save For Later',
				name: linkClickName.toLowerCase(),
				sku : skuNo,
				productType: productType
			}
		});
	} catch (e){
		console.log('Exception in handling Adobe Analytics for save for later :: '+e.stack);
	} 
}

/*Method to set enrollment status and tier value*/
function setAdobeEventValues(){
		var enrollmentStatus ="";
	 	var tierValue ="";
	 	var enrollStatusTierString="";
	    var loyaltyCookieVal = TSCUtils.getCookie("loyaltyDetails");
		if(!TSCUtils.isNullObj(loyaltyCookieVal)) {
			var loyaltyValues =  loyaltyCookieVal.split('|');
			if(loyaltyValues[0] == 'E') {
				enrollmentStatus ="enrolled-verified";
			}else if(loyaltyValues[0] == 'I') {
				enrollmentStatus ="enrolled-unverified";
			}
			if(loyaltyValues[1] == '0'){
				 tierValue ="not enrolled";
			}else if(loyaltyValues[1] == '1'){
			 tierValue ="neighbor";
			}else if(loyaltyValues[1] == '2'){
			 tierValue ="preferred neighbor";
			}else if(loyaltyValues[1] == '3'){
			 tierValue ="preferred plus";
			}
		  }else{
		  	enrollmentStatus ="unenrolled";
		  	tierValue ="not enrolled";
		 }
		 enrollStatusTierString=enrollmentStatus+'|'+tierValue;
		 return enrollStatusTierString ;
}
function setLoyaltyAnalyticsPageType(){
	try {
			var pageType = $("#pageType").val(); 
			var pageName = "";
			var moduleName = "";
			if(pageType == "ShoppingCart"){
				pageName = "cart";
				moduleName = 'rewards|'+pageName;
			}else{
				//Payment
				pageName = pageType;
				moduleName = 'rewards|'+pageName+' confirmation';
			}
			
			if(pageName == 'cart' || pageName == 'Payment'){
				setLinkClickEvent('Enroll now click',moduleName);
			}
	} catch (e){
		console.log('Exception in setLoyaltyAnalyticsPageType :: '+e.stack);
	} 
}
function setAdobeSocialShareEvent(platform, prodName, shareType){
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'social share',
			social : {      
				platform : platform.toLowerCase(),      
				content : prodName.toLowerCase(),      
				shareType : shareType.toLowerCase()
			}
		});
		console.debug("Adobe social share Event :: "+JSON.stringify(dataLayer));
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	} 
}

function setStoreLocatorStartAnalytics(){
	try {
		window.dataLayer = window.dataLayer || [] ;
		window.dataLayer.push({
		  event : 'locator initiated',
		  locator : {
		      action : 'initiated',
		  }
		}) ; 
		console.debug("Adobe store locator start Event :: "+JSON.stringify(dataLayer));
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	} 
}


/**
 * Common method to track various events in a page
 * @param eventName
 * @returns
 */
function setEventTrack(eventName) {
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event:eventName
		});
		console.debug("Adobe EventTrack Event :: " + JSON.stringify(dataLayer));
	} catch (e) {
		console.log('Exception in handling Adobe Analytics :: ' + e.stack);
	}
}

/**
 * Method to populate Adobe variables in order confirmation page
 * @returns
 */
function setOrderConfirmationAnalytics() {
    try {
        var orderList = $("#ordersList").text();
        orderList = JSON.parse(orderList);
        var item = '[';
        var ogCartAS = TSCUtils.getCookie('og_cart_autoship');
        if (!TSCUtils.isNullObj(ogCartAS)) {
            ogCartAS = JSON.parse(ogCartAS);
        }
		var rcvMobOrdrUpdt = (typeof orderList.paymentInstruction[0].billingAddress.mobilePhone1 != 'undefined') ? true : false;
		var altPkupPersonFlag = false;
		var crossTotalQty = "";
        var crossTotalRevenue = "";
        $.each(orderList.orderItem, function(index, orderItms) {
            if (orderItms.isWarranty == "false" && orderItms.isDonation == "false"  && orderItms.isAssembly == "false") {
				var productName = orderItms.productName.toLowerCase().replace(/[;,|"~]/g, '');
				var partNumber = orderItms.partNumber;
				var brandName = orderItms.brandName;
                var s2sSelected = false;
                if (orderItms.fulfilmentType == 'S2S') {
                    s2sSelected = true;
                }
                if (orderItms.isScanBasedItem == "true"){
                	var productType = "scanbased";
                }else{
                	var productType = "regular";
                }
                var itemLevelDiscountName = "";
                var itemLevelDiscountAmount = parseFloat(0);
                if (!jQuery.isEmptyObject(orderItms.adjustment)) {
					var productDiscount = parseFloat(0);
					var orderDiscount = parseFloat(0);
					$.each(orderItms.adjustment, function(itemIndex, itemAdjustment) {
                        if (typeof itemAdjustment.code != 'undefined' && typeof itemAdjustment.amount != 'undefined') {
							itemLevelDiscountName = itemLevelDiscountName + itemAdjustment.code;
							itemLevelDiscountAmount = itemLevelDiscountAmount + parseFloat(Math.abs(itemAdjustment.amount));
						}
						if (orderItms.adjustment.length > 1 && itemIndex != (orderItms.adjustment.length - 1)) {
							itemLevelDiscountName += ':';
						}
                    });
					itemLevelDiscountAmount = itemLevelDiscountAmount.toFixed(2);
                }
                var recurringOrder = false;
                var recurringQuantity = parseInt(0);
                var recurringRevenue = parseFloat(0);
                if($("#tsc_subsc_"+orderItms.orderItemId).length > 0){
                    recurringOrder = true;
                    recurringQuantity = orderItms.quantity;
                    recurringRevenue = parseFloat(orderItms.unitPrice) * parseFloat(orderItms.quantity);
                    recurringRevenue = recurringRevenue.toFixed(2);
                }
                
                var crossSellQuantity = '\"zero\"';
                var crossSellRevenue = '\"zero\"';
                var activityName = "cross sell n/a";
                var totalPrice = parseFloat(0);
                totalPrice = parseFloat(orderItms.unitPrice) * parseFloat(orderItms.quantity);
                totalPrice = totalPrice.toFixed(2);
                if(null != orderItms.field2 && orderItms.field2.includes("cs_t~")){
                	activityName = orderItms.field2.split("~")[1];
                	crossSellQuantity = parseInt(0);
                	crossSellRevenue = parseFloat(0);
                	
                	crossSellQuantity = orderItms.quantity;
                	crossSellQuantity = parseInt(crossSellQuantity);
                	
                	crossSellRevenue = parseFloat(orderItms.unitPrice);
                	crossSellRevenue = crossSellRevenue.toFixed(2);
                	
                	if(crossTotalQty == ""){
                		crossTotalQty = crossSellQuantity;
                	}
                	else{
                		crossTotalQty = parseInt(crossTotalQty)+parseInt(crossSellQuantity);
                	}
                	if(crossTotalRevenue == ""){
                		crossTotalRevenue = totalPrice;
                	}
                	else{
                		crossTotalRevenue = parseFloat(crossTotalRevenue)+parseFloat(totalPrice);
                		crossTotalRevenue = crossTotalRevenue.toFixed(2);
                	}
                	crossTotalQty = parseInt(crossTotalQty);
                	crossTotalRevenue = parseFloat(crossTotalRevenue);
                }
                
                var purchaseProtectionSelected = false;
                var warrantyRevenue = parseFloat(0);
                if (orderItms.hasWarranty == "true") {
                    if (typeof orderItms.orderItemRel != 'undefined' && typeof orderItms.orderItemRel.childOrderItemId != 'undefined' && orderItms.orderItemRel.childOrderItemId != "") {
                        $.each(orderList.orderItem, function(ordItemIndex, ordItem) {
                            if (ordItem.orderItemId == orderItms.orderItemRel.childOrderItemId) {
                                purchaseProtectionSelected = true;
                                warrantyRevenue = parseFloat(ordItem.unitPrice) * parseFloat(ordItem.quantity);
                                warrantyRevenue = warrantyRevenue.toFixed(2);
                            }
                        });
                    }
                }
                var orderItmCaseEligible="" ;
                if (typeof(orderItms.caseEligible) != 'undefined' && null != orderItms.caseEligible) {
                	orderItmCaseEligible=orderItms.caseEligible;
                }
                if(orderItms.caseSize > 0){
                    var caseRevenue = (parseFloat(orderItms.caseQty)*parseFloat(orderItms.caseSize)*parseFloat(orderItms.unitPrice)).toFixed(2);
                	 item+='{"quantity": '+orderItms.quantity+',"caseQty": '+orderItms.caseQty+',"caseSize": '+orderItms.caseSize+',"caseEligible": '+(orderItmCaseEligible).toString()+',"caseRevenue": '+caseRevenue+',"fulfillment":{"fulfillmentMethod":"'
     				+orderItms.shipModeDescription.toLowerCase()+'","shipToStoreSelected":'+s2sSelected+'},"productInfo":{"productCategoryID":"' +orderItms.ProductCategoryID+'","productID":"'
     				+orderItms.parentPartNumber+'","productName":"'+productName+'","productSKU":"'+partNumber+'","brandName":"'+brandName+'","productType":"'+productType+'"},"price":{"unitPrice":'+
     				orderItms.unitPrice+',"totalPrice":'+totalPrice+'},"productDiscount":{"name":"'+itemLevelDiscountName.toLowerCase()+'","amount":'+parseFloat(itemLevelDiscountAmount) +'},"options":{"purchaseProtectionSelected":'+purchaseProtectionSelected+',"alternatePickupPersonSelected":'+altPkupPersonFlag+','+
     				'"receiveMobileOrderUpdates":'+rcvMobOrdrUpdt+',"recurringRevenue":'+recurringRevenue+',"recurringOrder":'+recurringOrder+',"recurringQuantity":'+recurringQuantity+',"warrantyRevenue":'+warrantyRevenue+',"crossSellRevenue":'+crossSellRevenue+',"crossSellQuantity":'+crossSellQuantity+',"activityName":"'+activityName+'"}},';
                }else{
                	 item+='{"quantity": '+orderItms.quantity+',"caseQty": '+orderItms.caseQty+',"caseSize": '+orderItms.caseSize+',"caseEligible": '+(orderItmCaseEligible).toString()+',"fulfillment":{"fulfillmentMethod":"'
     				+orderItms.shipModeDescription.toLowerCase()+'","shipToStoreSelected":'+s2sSelected+'},"productInfo":{"productCategoryID":"' +orderItms.ProductCategoryID+'","productID":"'
     				+orderItms.parentPartNumber+'","productName":"'+productName+'","productSKU":"'+partNumber+'","brandName":"'+brandName+'","productType":"'+productType+'"},"price":{"unitPrice":'+
     				orderItms.unitPrice+',"totalPrice":'+totalPrice+'},"productDiscount":{"name":"'+itemLevelDiscountName.toLowerCase()+'","amount":'+parseFloat(itemLevelDiscountAmount) +'},"options":{"purchaseProtectionSelected":'+purchaseProtectionSelected+',"alternatePickupPersonSelected":'+altPkupPersonFlag+','+
     				'"receiveMobileOrderUpdates":'+rcvMobOrdrUpdt+',"recurringRevenue":'+recurringRevenue+',"recurringOrder":'+recurringOrder+',"recurringQuantity":'+recurringQuantity+',"warrantyRevenue":'+warrantyRevenue+',"crossSellRevenue":'+crossSellRevenue+',"crossSellQuantity":'+crossSellQuantity+',"activityName":"'+activityName+'"}},';
                } 
               
            }
        });
        item = item.substring(0, item.length - 1);
        item += ']';
		item = JSON.parse(item);
        var savedCard = false;
		if($("#savedCreditCard").length > 0  && $("#savedCreditCard").val() == "true"){
			savedCard = true;
		}
        var guestUser = false;
		if($("#guestUser").length > 0  && $("#guestUser").val() == "true"){
			guestUser = true;
		}
        var totalOrderLevelDiscount = parseFloat(0);
        var lyltyStndrdShipDiscount = parseFloat(0);
        var lyltySameNextDayDiscnt = parseFloat(0);
        var lyltyCouponCodeDiscnt = parseFloat(0);
        var rewardCodePrefix = $("#loyaltyRewardCodePrefix").val();
        if (!jQuery.isEmptyObject(orderList.adjustment)) {
			$.each(orderList.adjustment, function(orderLevelIndex, adjustment) {
				totalOrderLevelDiscount = totalOrderLevelDiscount + parseFloat(adjustment.amount);
				if ((adjustment.code.substring(0,3) == rewardCodePrefix) && (adjustment.usage == "Shipping Adjustment")
						&& typeof adjustment.rewardCode == 'undefined'){

					lyltyStndrdShipDiscount = lyltyStndrdShipDiscount + parseFloat(Math.abs(adjustment.amount));
				}else if ((adjustment.code.substring(0,3) == rewardCodePrefix) && (adjustment.usage == "Shipping Adjustment")){
					lyltySameNextDayDiscnt = lyltySameNextDayDiscnt + parseFloat(Math.abs(adjustment.amount));
				}else if ((adjustment.code.substring(0,3) == rewardCodePrefix) && (adjustment.usage == "Discount") 
						&& (adjustment.displayLevel == "Order")){
					lyltyCouponCodeDiscnt = lyltyCouponCodeDiscnt + parseFloat(Math.abs(adjustment.amount));

				}
			});
			totalOrderLevelDiscount = totalOrderLevelDiscount.toFixed(2);
		}
        var offerCodeApplied = false;
        var offerCodeValue = "";
        var offerCodeAmount = parseFloat(0);
		if (!jQuery.isEmptyObject(orderList.promotionCode)) {
			$.each(orderList.promotionCode, function(OfferIndex, promotionCode) {
				if (!jQuery.isEmptyObject(promotionCode.associatedPromotion)) {
					$.each(promotionCode.associatedPromotion, function(assoindex, associatedPromotion) {
						$.each(orderList.adjustment, function(index1, adjustment) {
							if (adjustment.code.includes(associatedPromotion.promotionId) == true) {
								offerCodeApplied = true;
								offerCodeValue += promotionCode.code;
								var amount = parseFloat(Math.abs(adjustment.amount));
								offerCodeAmount += amount;
							}
						});
					});
				}
				if (orderList.promotionCode.length > 1 && OfferIndex != (orderList.promotionCode.length - 1)) {
					offerCodeValue += ':';
				}
			});
			offerCodeAmount = parseFloat(offerCodeAmount).toFixed(2);
		}
		//Payment
		var payMethodId = "";
		var giftcardAmount = 0;
		$.each(orderList.paymentInstruction, function(payIndex, paymentInstruction) {
			if (typeof paymentInstruction.payMethodId != 'undefined') {
				if(paymentInstruction.payMethodId == 'TSC Card') {
					payMethodId += "TSC Personal Card";
				} else {
					if(paymentInstruction.payMethodId == 'Gift Card' && !isNaN(paymentInstruction.piAmount)){
						giftcardAmount = giftcardAmount + parseFloat(paymentInstruction.piAmount);
					}
					payMethodId += paymentInstruction.payMethodId;
				}
			}
			// Adobe analytics for Apple and Google Pay
			try{
				var protocolData = paymentInstruction.protocolData;
				$.each(protocolData, function(idx,data){
					if(data.name == 'walletType'){
						payMethodId += ':' + data.value;
					}
				});				
			}catch(e){
				console.log("Error occurred while setting walletType in adobe analytics");
			}
			if (orderList.paymentInstruction.length > 1 && payIndex != (orderList.paymentInstruction.length - 1)) {
                payMethodId += ':';
            }
        });
		
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "checkout: confirmation", //event104, purchase
            "purchase": {
                "purchaseID": orderList.orderId, //eVar69, s.purchaseID, ex. 12345abcde
                "total": {
                    "currency": "USD",
                    "salesTax": parseFloat(orderList.totalSalesTax), //event152, ex. 7.50
                    "shipping": parseFloat(orderList.totalShippingCharge), //event151, ex. 10.50
                    "discount": parseFloat(Math.abs(totalOrderLevelDiscount)),  //event153, ex. 5.25
                    "crossTotalQty": crossTotalQty,
                    "crossTotalRevenue": crossTotalRevenue
                },
                "profile": {
                    "address": {
                        "stateProvince": orderList.paymentInstruction[0].billingAddress.state.toLowerCase(), //s.state, ex. new york
                        "postalCode": orderList.paymentInstruction[0].billingAddress.zipCode, //s.zip, ex. 10001
                        "email" : orderList.paymentInstruction[0].billingAddress.email1.toLowerCase(),
        				"firstName" : orderList.paymentInstruction[0].billingAddress.firstName.toLowerCase(),
        				"lastName" : orderList.paymentInstruction[0].billingAddress.lastName.toLowerCase(),
        				"street": orderList.paymentInstruction[0].billingAddress.addressLine[0].toLowerCase(),					
        				"city" : orderList.paymentInstruction[0].billingAddress.city.toLowerCase(),
        				"state" : orderList.paymentInstruction[0].billingAddress.state.toLowerCase(),
        				"zip": orderList.paymentInstruction[0].billingAddress.zipCode,
        				"Phone" : orderList.paymentInstruction[0].billingAddress.phone1	
                    },
                    "guest": guestUser //event57, if false, set as empty string
                },
                "payment": [{
                    "saveCardInfo": savedCard, //event112, if false, set as empty string
                    "paymentMethod": payMethodId.toLowerCase(), //eVar68, payment card name
                    "paymentAmount": parseFloat(orderList.grandTotal), //event175, total payment, ex. 100.00
                    "giftcardAmount": giftcardAmount
                }],
                "offerCode": [{
                    "offerCodeApplied": offerCodeApplied, //if false, set all elements below as 
                    //empty string
                    "offerCodeValue": offerCodeValue.toLowerCase(), //eVar70, promotion code 
                    //value, "GET20OFF"
                    "amount": parseFloat(offerCodeAmount)  //event106, 20.00
                }],
                "item": item,
                "neighborsClub": {
                	"freeStandardDelivery": lyltyStndrdShipDiscount,
                	"sameDayDelivery": lyltySameNextDayDiscnt,
                	"couponCodesApplied": lyltyCouponCodeDiscnt                		
                }               
            }
        });
        if(('sessionStorage' in window) && window.sessionStorage !== null && undefined != window.sessionStorage.getItem('multipleAddressToggled') && null != window.sessionStorage.getItem('multipleAddressToggled')){
			var multipleAddressToggled = window.sessionStorage.getItem('multipleAddressToggled');
			dataLayer.forEach(function(DLObject){
        	    if(null != DLObject.event && undefined != DLObject.event && DLObject.event == "checkout: confirmation"){
        	    	DLObject.purchase["multipleAddressToggled"] = multipleAddressToggled;
        	    }
        	});
			window.sessionStorage.removeItem('multipleAddressToggled');
		}
        console.debug("Adobe Order Confirmation Event :: " + JSON.stringify(dataLayer));
        sessionStorage.removeItem('viewCartLoaded');
        localStorage.removeItem('viewCartLoadedTime');
    } catch (e) {
        console.log('Exception in handling Adobe Analytics :: ' + e.stack);
    }
}
function setMakeMyTscStoreAnalytics(eventName,zipCode,storeName){
	try {
		window.dataLayer = window.dataLayer || [] ;
		window.dataLayer.push({
		  event : eventName.toLowerCase(),
		  locator : {
		    details : {
		        storeName : storeName.toLowerCase(),
		        zip : zipCode.toLowerCase()
		    }
		  }
		}) ;
		console.debug("Adobe Make My Store Event :: "+JSON.stringify(dataLayer));
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	} 
	}
function setFilterExpandedEvent(filterType, filterSection) {
	try {
		if(filterType == "filter expanded") {
			var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
			var pageName = setPageName(pageType);
			window.dataLayer = window.dataLayer || [] ;
			window.dataLayer.push({
				event : filterType,
				filter : {
					expanded : filterSection.toLowerCase(),
					location : pageName.toLowerCase()
				}
			});
			console.debug("Adobe "+filterType+" Event :: "+JSON.stringify(dataLayer));
		}
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}

function setFilterSelectedEvent( expVal, selVal, id){
	try {
		if((undefined != document.getElementById(id) && document.getElementById(id).checked == true) || id == "true"){
			var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
			var pageName = setPageName(pageType);
			window.dataLayer = window.dataLayer || [] ;
			window.dataLayer.push({
			  event : 'filter selected',
			  filter: {
				  expanded: expVal.toLowerCase(),
				  selected: selVal.toLowerCase(),
				  location: pageName.toLowerCase()
				}
			});
			console.debug("Adobe filter selected Event :: "+JSON.stringify(dataLayer));
		}
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}

/**
 * Method to set Adobe Analytics searchcomplete  Events.
 */
function setSearchResultsEvent(subType, searchTerm, numResult){
	try {
		var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
		var parentSKU = "n/a";
		var childSKU = "n/a";
		if(pageType == "PDP") {
			var parentSKU = $("#partNumber_pdp").length > 0 ? $("#partNumber_pdp").val() : "n/a";
			var childSKU = $("#childSKU").length > 0 ? $("#childSKU").val() : "n/a";
		}
		window.dataLayer = window.dataLayer || [] ;
		window.dataLayer.push({
			event : 'search results',
			search: {
			  type: 'internal search',
			  subtype: subType.toLowerCase(),
			  term: searchTerm.toLowerCase(),
			  num_results: parseInt(numResult),
			  product: {
			  	sku : childSKU,
			  	id : parentSKU
			  }
			}
		}); 
		console.debug("Adobe setSearchResultsEvent Event :: "+JSON.stringify(dataLayer));
		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}

function setSubPageViewEvent(nameVal){
    try {
        window.dataLayer = window.dataLayer || [] ;
        window.dataLayer.push({
          event : 'sub page view',
          subpage: {
              name: nameVal.toLowerCase()
            }
        });
        console.debug("Adobe SubPageView Event :: "+JSON.stringify(dataLayer));
    } catch (e){
        console.log('Exception in handling Adobe Analytics :: '+e.stack);

    }

}
function setPfmSessionStorage(pfm){
	
	try {
			var oldPfm = sessionStorage.getItem("pfm");
			var useOldPfm = false;
			if( sessionStorage.getItem("clearPfm") === null && !TSCUtils.isNullObj(oldPfm) &&  oldPfm.endsWith('~target pfm override')) {
			//Check if old pfm value needs to be retained. 
				useOldPfm = true;
			} else {
			// delete 'clearPfm' to override pfm value.
				sessionStorage.removeItem("clearPfm");
			}
			var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
			if((pageType === "PLP" || pageType === "search") && useOldPfm ) {
			//Use oldPfm in this flow
				pfm = oldPfm;
				sessionStorage.setItem("clearPfm", 1);
			}
			if(undefined == pfm){
				pfm = setPageName(pageType);
			}
			sessionStorage.setItem("pfm", pfm);
		} catch (e){
		    console.log('Exception in handling the setPfmSessionStorage :: '+e.stack);
		}	
}


$(document).ready(function(){
	var ActionStatusSignIn=0;
	var ActionStatusSignUp=0;
	
    $("#Logon input").on('click', function(){
    	if(ActionStatusSignIn == 0)
    		{
    		setEventTrack('user login start');
    		ActionStatusSignIn++;
    		} 
    });
    $("#Register input").on('click', function(){
    	if(ActionStatusSignUp == 0)
    		{
    		setEventTrack('user signup start');
    		ActionStatusSignUp++;
    		}
    });
   
    //calling the LoyaltyCookie Check when loyalty feature is enabled
    //cookie is used in different pages to get the loyalty information
    checkLoyaltyCookieValue();  
        
});

/**
 * Method to set Adobe Analytics Success Login Events.
 */
function setSuccessfullLogin(type, serviceResponse){	
	try {
		var isNewSignFlow = (isNewSignInPageEnabled && $("#pageName").val() == "SignInRedesign") ? true : false;		
		var loginType="my account";		
		var url= document.location.href.toLowerCase();
		if(type != null && type != ""){
			loginType=type;
			if(loginType == "facebook" && !isNewSignFlow){
				setEventTrack('login facebook');}
			if(loginType == "google" && !isNewSignFlow){
				setEventTrack('login google');}
		}
		if(url != null && url != "" && url.includes('neighborsclub')) {
			loginType="neighbors club";	}
		var neighborsStatus = false;
		if(undefined != serviceResponse.loyaltyEnrolledCustomer && serviceResponse.loyaltyEnrolledCustomer == "true"){
			neighborsStatus = true;	}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  event: 'user login complete',
		  user :[{
	             profile :[{      
	            	 attributes :{
	                          loginType : loginType,
	                          loginStatus :'logged in',
	                          neighborsClub : neighborsStatus,
	                          userID : serviceResponse.userId
	                          }
	                    }]
	             }]
		}); 
		localStorage.setItem("CurrentUserId", serviceResponse.userId);
		console.debug("Adobe setSuccessfullLogin Event :: "+JSON.stringify(dataLayer));
		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}


/**
 * Method to set Adobe Analytics Failed Login Events.
 */
function setFailedLogin(errorObj){
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  event: 'login error',
		  user :[{
	             profile :[{      
	            	 attributes :{
	            		 loginErrorType :errorObj.errorMessage.toLowerCase(),
	                     loginError :'true',

	                          }
	                    }]
	             }]
		}); 	
		console.debug("Adobe setFailedLogin Event :: "+JSON.stringify(dataLayer));
		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}


/**
 * Method to set Adobe Analytics Success Logout Events.
 */
function setSuccessfullLogout(){
	try {
		window.dataLayer = window.dataLayer || [];
		
		window.dataLayer.push({
		    event: 'user logout',
		    user: [{
		        profile: [{
		            attributes : {
		            	userID : localStorage.getItem('CurrentUserId'),
		      		  loginStatus: 'logged out'
		            }
		        }]
		    }]
		});	
		console.debug("Adobe setSuccessfullLogout Event :: "+JSON.stringify(dataLayer));
		localStorage.removeItem('CurrentUserId');
		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}


function setUserSignupComplete(serviceResponse){
	try {
		var form= document.forms["Register"];

		
		//Setting LoginType(<neighbors club/my account/facebook>)
		var LoginType="my account";
		if(form.loginType != null && form.loginType != "" && form.loginType == "SOCIAL"){
			if(form.provider != null && form.provider != "" && form.provider.value == "facebook"){
				LoginType = form.provider.value;
				}				
			}
		else if(form.action !=null && form.action != ""){
			if(form.action.value == "findcustomer"){
				LoginType="neighbors club";
				}  
			}
		
		//Getting Address Seleced details
		var AddressSelected = false;
		if($('#myDialog').hasClass('show')){
		if(form.addressField1.value != null && form.addressField1.value != "" && form.addressField1.value == "Y"){
			AddressSelected	= true;
				}
			}		
		
		//SMS Notification status
		var SMSFlag=false;
		if(form.receiveSMSNotification != null && form.receiveSMSNotification != "" && form.receiveSMSNotification.value == "true") {
				SMSFlag=true;
			}		
		
		//Fetching User Interests While Registration
		var animalInterest="";	
		var agricultureOption ="";
		var hobbies="";
		
			 if (form.demographics_5 != null && form.demographics_5 != "") {
				 var animalSelected = false;
					for(i=0;i<form.demographics_5.length;i++){
						if(i != 0){
								animalInterest = animalInterest + "|";								
							}
						if(form.demographics_5[i].checked==true){
							animalSelected = true;
							animalInterest = animalInterest +  $("#"+form.demographics_5[i].id).next("label").text().trim();				
						}														
					}
					 if(!animalSelected){
					        animalInterest = "";
					    }
					}
		
			 if(form.demographicFields6 !=null && form.demographicFields6 != ""){
					for(i=0;i<form.demographicFields6.length;i++){
						if(form.demographicFields6[i].checked==true){					
						 agricultureOption =  $("#"+form.demographicFields6[i].id).next("label").text().trim();
						}
					}
			 } 			 
			 
			 if(form.demographics_7 !=null && form.demographics_7 != ""){
				 var hobbiesSelected = false;
					for(i=0;i<form.demographics_7.length;i++){
						if(i != 0){
							hobbies = hobbies + "|";
						}
						if(form.demographics_7[i].checked==true){
							hobbiesSelected = true;
							hobbies = hobbies +  $("#"+form.demographics_7[i].id).next("label").text().trim();
						}
					}
					if(!hobbiesSelected){
						hobbies = "";
				    }
			 }
			 			 	
		
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		    event: 'user signup complete',
		    user: [{ 
		        profile: [{
		            attributes: {
		                loginType: LoginType.toLowerCase(),
		                loginStatus :'logged in',
		                userID : serviceResponse.userId,
		                userSelections:  
		                {
		                	animals : animalInterest.toLowerCase(),
		                	agricultureOption :agricultureOption.toLowerCase(),
		                	hobbies: hobbies.toLowerCase()
		                },
		                addressOptionUsed : AddressSelected, 
		                location :pageTemplType.toLowerCase(),   
		                receiveMobileOrderUpdates : SMSFlag
		            }
		        }]
		    }]
		});

		localStorage.setItem('CurrentUserId',serviceResponse.userId);
		console.debug("Adobe setUserSignupComplete Event :: "+JSON.stringify(dataLayer));
		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}

/**
 * Method to set Adobe Analytics Gift Card Check Balance Events.
 */
function setGCCheckBalance(interaction, accountBalance, sku, cardType, customeAmount) {
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event : 'gift card',
			giftcard : {
				interaction : interaction,
				amount : parseFloat(accountBalance),
				sku : sku,
				type : cardType,
		    customAmount : customeAmount

			}
		});
		console.debug("Adobe setGCCheckBalance Event :: "
				+ JSON.stringify(dataLayer));

	} catch (e) {
		console.log('Exception in handling Adobe Analytics :: ' + e.stack);
	}
}

function setTechDebuggingEvent(ErrStatus,Url){	
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  event: 'tech performance/debugging',
		  debugging : {
		     url : Url,
		     error_404 : ErrStatus
		  }
		}); 
		console.debug("Adobe setTechDebuggingEvent Event :: "+JSON.stringify(dataLayer));		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}		
}

function setTechDebuggingEventFBT(ErrStatus,Url){	
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  event: 'tech performance/debugging',
		  debugging : {
		     url : Url,
		     error_message : ErrStatus
		  }
		}); 
		console.debug("Adobe setTechDebuggingEventFBT Event :: "+JSON.stringify(dataLayer));		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}		
}

function setAdobeWishlistInteraction(interactionVal) { 
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'my account : wishlist',
			wishlist : {
				interaction : interactionVal.toLowerCase(),
			}
		}); 
		console.debug("Adobe wishlist interaction Event :: "+JSON.stringify(dataLayer));
    } catch (e){
        console.log('Exception in handling Adobe Analytics :: '+e.stack);

    }
}

function getRecommendationsParams(parentSkuVal, prodNameVal, activityNameVal, interstitial) {
	try {
		var locationVal = '';
		var pfmVal = 'browse';
		if(interstitial) {
			pfmVal = 'cross-sell in cart interstitial';
			locationVal = 'cart interstitial';
		}
		else {
			var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
			pfmVal = getPfmValue(pageType, pfmVal);
			locationVal = setPageName(pageType);
		}
		setCrossSellEvent(parentSkuVal, prodNameVal, activityNameVal, locationVal);
		setPfmSessionStorage(pfmVal+'~'+activityNameVal.toLowerCase());
	} catch (e){
        console.log('Exception in handling the getRecommendationsParams :: '+e.stack);
    }
}

function setCrossSellEvent(parentSkuVal, prodNameVal, activityNameVal, locationVal) {
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'cross sell',
			cross_sell : {
				location : locationVal.toLowerCase() 
			},
			target: {
			  	activityName: activityNameVal.toLowerCase()
			},
			product:[{					
				productInfo:{
					productID:parentSkuVal.toLowerCase(), 
					productName:prodNameVal.toLowerCase().replace(/[;,|"~]/g, '')
				}
			}]
		}); 
		console.debug("Adobe cross sell Event :: "+JSON.stringify(dataLayer));
    } catch (e){
        console.log('Exception in handling Adobe Analytics setCrossSellEvent :: '+e.stack);

    }
}
function getPLPRecommendationsParams(plpURL, activityNameVal, moduleName) {
	try{
		var locationVal = '';
		var pfmVal = 'browse';
		var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
		//pfmVal = getPfmValue(pageType, pfmVal);
		locationVal = setPageName(pageType);
		setPLPCrossSellEvent(plpURL, activityNameVal, locationVal, moduleName);
		//setPfmSessionStorage(pfmVal+'~'+activityNameVal.toLowerCase());
	} catch(e) {
		console.log('Exception in handling the getPLPRecommendationsParams :: '+e.stack);
	}
}
function setPLPCrossSellEvent(plpURL, activityNameVal, locationVal, moduleName) {
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'cross sell',
			cross_sell : {
				location : locationVal.toLowerCase() 
			},
			target: {
			  	activityName: activityNameVal.toLowerCase()
			},
			category:[{					
				content:{
					url:plpURL, 
					module: moduleName
				}
			}]
		}); 
		console.debug("Adobe cross sell Event :: "+JSON.stringify(dataLayer));
    } catch (e){
        console.log('Exception in handling Adobe Analytics setCrossSellEvent :: '+e.stack);
    }
}
function getPfmValue(pageType, pfmVal){
	try {
	    switch(pageType) {
		    case 'search' : 
		    	pfmVal = "cross-sell on zero search page";
		        break;
		    case 'PDP' :
		    	pfmVal = "cross-sell on pdp";
		        break;
		    case 'ShoppingCart' : 
		    	pfmVal = "cross-sell in cart";
		        break;
		    case 'CategoryPage' : 
		    	pfmVal = "cross-sell on category page";
		        break;
		    case 'HomePage' : 
		    	pfmVal = "cross-sell on homepage";
		        break;
		    case 'PLP' :
		    	pfmVal = "cross-sell on PLP";
		        break;
	    }
	}
    catch (e){
        console.log('Exception in handling Adobe Analytics getPfmValue :: '+e.stack);
    }
	return pfmVal;
}

function setCategoryClickEvent(activityNameVal, categoryName) {
	try {
	    window.dataLayer = window.dataLayer || [] ;
	    window.dataLayer.push({
	      event : 'category rec',
	      target: {
	        activityName: activityNameVal.toLowerCase(),
	        categoryName: categoryName.toLowerCase()
	      }
	    });
	    console.debug("Adobe cross sell Event :: "+JSON.stringify(dataLayer));
	} catch (e){
        console.log('Exception in handling Adobe Analytics setCategoryClickEvent :: '+e.stack);

    }
}
/**
 * The method checks if loyalty cookie exist for the user,if cookie is empty invokes the loyalty service to 
 * get the loyalty info of user and sets the cookie
 * @returns
 */
function checkLoyaltyCookieValue(isLoginFlow) {
	var isLoyaltyInfoPresent = true;
	 if(undefined == isGuest || null == isGuest && isNewSignInPageEnabled){
	    var isGuest =  !(TSCUtils.getCookie('rememberedUser') === 'Y' || !TSCUtils.isNullObj(TSCUtils.getCookie('firstName')));	
	 }
	//loyalty cookie check is done only for registered user
	//the loyalty enrolled status checked at backend 
	//before making the third party service
	if(!isGuest || storeUserType === "R" || (undefined != isLoginFlow &&  isLoginFlow === "Y")) {
		var loyaltyCookieVal = getCookie("loyaltyDetails");
		var isHeroDualVerificationEnabled = $("#isHeroDualVerificationEnabled").length ? $("#isHeroDualVerificationEnabled").val() : 'false';
		var isHeroBenefitFeatureEnabled = $("#isHeroBenefitFeatureEnabled").val();
		var isMilitaryExpirationFlag = $("#isMilitaryExpirationFlag").val();
		var isResponderExpirationFlag = $("#isResponderExpirationFlag").val();
		
		if(TSCUtils.isNullObj(loyaltyCookieVal)){
			isLoyaltyInfoPresent = false;
			var requestURL = location.protocol + "//" + location.host
					+ "/wcs/resources/store/" + WCParamJS.storeId
					+ "/loyalty/customerDetails/leftNav";
		 
			$.ajax({
			    url : requestURL,
			    type : 'GET',
			    cache: false,       
			    dataType: 'json',
			    success : function(serviceResponse) {
					var loyaltyCookieVal; // E|2|1.5
					var enrollStatus = '';
					var expiryDate = '';
					if(!TSCUtils.isNullObj(serviceResponse.loyaltyEnrollStatus)){
						enrollStatus = serviceResponse.loyaltyEnrollStatus;
						if(isHeroDualVerificationEnabled){
							var heroDualCookieList = new Array();
							heroDualCookieList = heroDualCookieUpdate(serviceResponse);
						}
						
						if(enrollStatus === "I" && serviceResponse.loyaltyCustomerFlag) {
							loyaltyCookieVal = enrollStatus;
							// setting tier info for unverified user
							if (!TSCUtils.isNullObj(serviceResponse.loyaltyInfo) && !TSCUtils.isNullObj(serviceResponse.loyaltyInfo.currentTier) 
									&& !TSCUtils.isNullObj(serviceResponse.loyaltyInfo.tierMultiplier)) {
								loyaltyCookieVal = loyaltyCookieVal +'|'+ serviceResponse.loyaltyInfo.currentTier +'|'+ serviceResponse.loyaltyInfo.tierMultiplier;
								if(isHeroBenefitFeatureEnabled == 'true' ){
									if(isHeroDualVerificationEnabled == 'true'){
										if(!TSCUtils.isNullObj(serviceResponse.heroDetails) ){
											for (var i = 0; i < heroDualCookieList.length; i++){
												loyaltyCookieVal = enrollStatus +'|'+ serviceResponse.loyaltyInfo.currentTier +'|'+ serviceResponse.loyaltyInfo.tierMultiplier +'|'+ heroDualCookieList[i];
											}
										}
									}else if(!TSCUtils.isNullObj(serviceResponse.heroStatus)  && !TSCUtils.isNullObj(serviceResponse.verificationDate) 
								    		&& !TSCUtils.isNullObj(serviceResponse.group)){
										loyaltyCookieVal = enrollStatus +'|'+ serviceResponse.loyaltyInfo.currentTier +'|'+ serviceResponse.loyaltyInfo.tierMultiplier +'|'+ serviceResponse.heroStatus +'|'+ serviceResponse.heroExpiry;
									}
							    }
							}
						}else if(enrollStatus === "E" && !TSCUtils.isNullObj(serviceResponse.loyaltyInfo) 
								&& !TSCUtils.isNullObj(serviceResponse.loyaltyInfo.currentTier) && !TSCUtils.isNullObj(serviceResponse.loyaltyInfo.tierMultiplier)){
						    loyaltyCookieVal = enrollStatus +'|'+ serviceResponse.loyaltyInfo.currentTier +'|'+ serviceResponse.loyaltyInfo.tierMultiplier;	
						    if(isHeroBenefitFeatureEnabled == 'true' ){
								if(isHeroDualVerificationEnabled == 'true'){
									if(!TSCUtils.isNullObj(serviceResponse.heroDetails) ){
										for (var i = 0; i < heroDualCookieList.length; i++){
											loyaltyCookieVal = enrollStatus +'|'+ serviceResponse.loyaltyInfo.currentTier +'|'+ serviceResponse.loyaltyInfo.tierMultiplier +'|'+ heroDualCookieList[i];
										}
									}
								}else if(!TSCUtils.isNullObj(serviceResponse.heroStatus)  && !TSCUtils.isNullObj(serviceResponse.verificationDate) 
							    		&& !TSCUtils.isNullObj(serviceResponse.group)){
									loyaltyCookieVal = enrollStatus +'|'+ serviceResponse.loyaltyInfo.currentTier +'|'+ serviceResponse.loyaltyInfo.tierMultiplier +'|'+ serviceResponse.heroStatus +'|'+ serviceResponse.heroExpiry;
								}
								
						    }
						}

						if (typeof loyaltyCookieVal != 'undefined' && loyaltyCookieVal) {
							TSCUtils.setCookie("loyaltyDetails", loyaltyCookieVal, {path:'/'});
							heroVerifiedStatus();
						}
						if(enrollStatus === "E") {
							displayWalletButton();
						}
					}
					var pageType = $('#pageType');
					if(pageType.length > 0){
						pageType = pageType.val();
					}
					if (pageType =='PDP') {
						var rewardCode = TSCUtils.getURLParamFieldValue(location.href, "rc");
						if (enrollStatus === "E" && !TSCUtils.isNullObj(rewardCode) && $("#isMarketRedesignEnabled").val() === 'true' 
								&& typeof TSCLoyaltyMarketJS != 'undefined') {
							// if lands to Market PDP after user login
							$('#isMarketItem').val('Y');
							productDisplayJS.isMarketPDP = true;
							TSCLoyaltyMarketJS.getMarketItemDetails(rewardCode);
						} else {
							// regular PDP
							displayPDPLoyaltyInfo();
						}
					}
					if (typeof TSCLoyaltyService != 'undefined') {
						// display Loyalty specific messages in selected pages
						TSCLoyaltyService.displayLoyaltyMsg();
					}
					cursor_clear();
				},
			    error : function(data) {
			    	cursor_clear();			    	
			    }
			});
		} else {
			var loyaltyValues =  loyaltyCookieVal.split('|');
			if(loyaltyValues[0] === 'E') {
				displayWalletButton();
			}		
			var pageType = $('#pageType');
			if(pageType.length > 0){
				pageType = pageType.val();
			}
			if(pageType =='PDP'){
				displayPDPLoyaltyInfo();
			}
		}
	}
	if (typeof TSCLoyaltyService != 'undefined' && isLoyaltyInfoPresent) {
		// display Loyalty specific messages in selected pages
		TSCLoyaltyService.displayLoyaltyMsg();
	}
}

/**
 * Method to update the heroDualCookie
 */
function heroDualCookieUpdate(serviceResponse) {
	var heroDetails = [];
	var firstCookie = '';
	var cookieDetailsList = new Array();
	heroDetails = serviceResponse.heroDetails;
	if (!TSCUtils.isNullObj(heroDetails)) {
		for (var i = 0; i < heroDetails.length; i++) {
			var group = heroDetails[i].heroGroup;
			var status = heroDetails[i].heroStatus;
			var expiryDate = heroDetails[i].heroExpiry;
			if ("military" == group) {
				var militaryGroupValue = "M";
				var militaryExpiry = expiryDate;
				if (true == status) {
					var militaryVerificationStatus = "Y";
				} else {
					var militaryVerificationStatus = "N";
				}
				firstCookie = militaryGroupValue + '_'+ militaryVerificationStatus + '_' + militaryExpiry;
			} else if ("responder" == group) {
				var responderGroupValue = "R";
				var responderExpiry = expiryDate;
				if (true == status) {
					var responderVerificationStatus = "Y";
				} else {
					var responderVerificationStatus = "N";
				}
				firstCookie = responderGroupValue + '_'+ responderVerificationStatus + '_' + responderExpiry;
			}
			cookieDetailsList.push(firstCookie);
		}
	}
	return cookieDetailsList;
}

/**
 * Method to display wallet button in eligible pages for NC enrolled users
 */
function displayWalletButton() {
	var eligiblePages = ["HomePage", "LoyaltyDashboard", "ShoppingCart", "Payment"];
	var fromPage = $("#pageType").val();
	if($("#billingAndPaymentSinglePage").length && fromPage == 'delivery'){
		fromPage = 'Payment';
	}
	if(eligiblePages.includes(fromPage)) {
		// Show wallet FAB and button.
		$(".launch-wallet").removeClass("hide");
		if(fromPage === "ShoppingCart" || fromPage === "Payment") {
			$(".cart-open-wallet").removeClass("hide");		
		}
	}
}
/**
 * Method to set Adobe Analytics for loyalty Events.
 */
function setNeighborsClubLoyaltyClickEvent(linkClickName, moduleName,
		loyaltyValueObject) {
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event : 'link click',
			content : {
				module : moduleName.toLowerCase(),
				name : linkClickName.toLowerCase()
			},

			neighborsClub : loyaltyValueObject
		});
		console.debug("Adobe NeighborsClubLoyaltyClick Event :: "
				+ JSON.stringify(dataLayer));
	} catch (e) {
		console.log('Exception in handling Adobe Analytics :: ' + e.stack);
	}
}
/**
 * Method to set Adobe Analytics for Error scenario of loyalty Events.
 */
function setTechLoyaltyDebuggingEvent(ErrStatus, Url) {
	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event : 'tech performance/debugging',
			debugging : {
				url : Url,
				error_message : ErrStatus
			}
		});
		console.debug("Adobe setTechLoyaltyDebuggingEvent Event :: "
				+ JSON.stringify(dataLayer));
	} catch (e) {
		console.log('Exception in handling Adobe Analytics :: ' + e.stack);
	}
}
/**
 * Method to check if the current zone set is eligible for Blue Yonder call.
 */
function isZoneEligibleForBY() {
	console.log("Performing Blue Yonder eligibility check for current zone");
	var isZoneEligibleForBY = false;
	var isBYNetworkSplitEnabled = $("#enableBYNetworkSplit").val();
	if(isBYNetworkSplitEnabled === "Y") {
		var zoneID = TSCUtils.getCookie('lpZoneId');
		var eligibleZonesForBY = $("#eligibleZonesForBY").val();
		if(zoneID && eligibleZonesForBY) {
			var eligibleZonesForBYList = eligibleZonesForBY.split(",");
			if(eligibleZonesForBYList.includes(zoneID)) {
				isZoneEligibleForBY = true;
			}
		}
	} else {
		isZoneEligibleForBY = true;
	}
	return isZoneEligibleForBY;
}

/**
 * Method used to identify user of below mentioned category and set the cookie 'birthdayPetName’ for such users.
 * This cookie is used by Adobe to display customized messaging on the header
 * User category :- Registered, NC Enrolled, Verified, PLCC Card Holder w/Pet Profile - Cat/Dog Owner, with Pet Birthday under 30 days away.
 * 
 * This function fetches pet list from rest service for the given user. Returns the list of first 3 pets with birthday in next 30 days. 
 * The list has sorted values in the format '<dateDifference>|<profileId>'.
 * @param userId
 */
function checkPetBirthday(userId) {
	// Pet list rest service url.
    var getProfileListURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/10151/petProfileDetails/petProfileList?userId=" + userId + "&action=List&currentIndex=0&noOfRecords=10";
    var bdayPets = new Array();

	$.ajax({
        url: getProfileListURL,
        type: 'GET',
        cache: false,
        dataType: 'json',
        success: function (serviceResponse) {
        	var petProfileList = serviceResponse.petProfileList;
    		var petCount = 0;

    		if (!TSCUtils.isNullObj(petProfileList) && !TSCUtils.isNullObj(petProfileList.statusVo) && petProfileList.statusVo.code == '200') {
        		var petDetails = petProfileList.petProfileDetails;
        		
        		if (!TSCUtils.isNullObj(petDetails) && !TSCUtils.isNullObj(petDetails.profilesVO)) {
            		var currDate = new Date();
            		
            		TSCUtils.setCookie("petExists","Y");
            		for (var i=0; i<petProfileList.totalRecords; i++) {
            			var profileVO =  petDetails.profilesVO[i];
        				var birthdate = profileVO.birthDate;

        				var year = currDate.getFullYear();
        				var birthMonth = birthdate.substr(4,2)-1;
        				if (currDate.getMonth() == 11 && birthMonth == 0) {	// for December month
        				  year = currDate.getFullYear() + 1;
        				}
        				
        				var birthday = new Date(year,birthdate.substr(4,2)-1,birthdate.substr(6,2),0,0,0);
        				var dateDiff = (birthday.getTime() - currDate.getTime())/(1000*60*60*24);
        				
        				// birthday within next 30 days, including current date 
        				if (dateDiff >= 0 && dateDiff <= 30) {
        					petCount = bdayPets.push(dateDiff + "|" + profileVO.profileId);
        					if (petCount == 3) {	// picks only first 3 matching pets
        						break;
        					}
        				}
         			}
            		if (petCount > 1) {	// sorts the records based on date difference
						bdayPets.sort();
					}
        		}
        	}
			else if( !TSCUtils.isNullObj(petProfileList) && !TSCUtils.isNullObj(petProfileList.statusVo) && TSCUtils.isNullObj(petProfileList.petProfileDetails)){
				TSCUtils.setCookie("petExists","N");
			}
    		console.log("Pets with bday in next 30 days: " + petCount + ", petIds: " + bdayPets);
    		
    		if (bdayPets.length > 0) {
        		getPetProfileBdayDetails(userId,bdayPets);
    		}
    		else {
        		console.log("No pets available with birthday in next 30 days. Remove 'birthdayPetName' cookie if already exists.");
            	TSCUtils.removeCookie("birthdayPetName",{path:"/"});
    		}
        },
        error: function (request, error) {
            console.debug("Error invoking PetProfile API : " + error);
        }
    });
}

/**
 * Function to fetch pet details from rest service for the given 3 or less matching profileIds. 
 * Sets the cookie in the format '<petName>|<animalType>' if the pet belongs to Dog or Cat; being Dog with higher priority.
 * @param userId
 * @param bdayPets
 */
function getPetProfileBdayDetails(userId, bdayPets) {
	var petDetails;
	var profileId;
	var petCount = 0;
	var petDetailsList = new Array();
	
	for (var i=0; i<bdayPets.length; i++) {
		profileId = bdayPets[i].split('|')[1];
		
		// Pet details rest service url.
	    var getProfileDetailsURL = location.protocol + "//" + location.host 
	    		+ "/wcs/resources/store/10151/petProfileDetails/petProfileDetails?userId=" + userId + "&profileId=" + profileId + "&action=Details";

	    $.ajax({
	        url: getProfileDetailsURL,
	        type: 'GET',
	        cache: false,
	        dataType: 'json',
	        success: function (serviceResponse) {
	        	
	        	var animalType = serviceResponse.petProfileList.profilesVO.animalType;
	        	
	        	if (animalType == 'Dog' || animalType == 'Cat') {
	        		var petName = serviceResponse.petProfileList.profilesVO.petName;
                    var profileId = serviceResponse.petProfileList.profilesVO.profileId;
                    var dateDiff;
                    
                    for (var i=0; i<bdayPets.length; i++) {
                        if (bdayPets[i].endsWith(profileId)) {
                            dateDiff = bdayPets[i].split('|')[0];
                            petDetails = dateDiff + ":" + petName + '|' + animalType;
                            petDetailsList.push(petDetails);
                            break;
                        }                        
                    }
	        	}
	        },
	        error: function (request, error) {
	            console.debug("Error invoking get petProfileDetails API");
	        },
	        complete: function (request) {
	        	petCount++;
	        	console.log("petCount: " + petCount + ", petDetailsList.length: " + petDetailsList.length);
	        	
	        	// executes after all pets' (max 3) details API retuned response
	        	if (petCount == bdayPets.length) {
	        		if (petDetailsList.length > 0) {
		        		updatePetBdayCookie(petDetailsList);
	        		}
	        		else {
	        			// delete cookie for RemovePet/UpdatePet scenario
		            	TSCUtils.removeCookie("birthdayPetName",{path:"/"});
			        	console.log("Delete 'birthdayPetName' cookie since no matching pet.");
	        		}
	    		}
	        }
	    });
	}
}

/**
 * Method used to set 'birthdayPetName' cookie with the pet details which has the nearest birthday within next 30 days.
 * If multiple profiles available, then Dog is given priority over Cat. Other pets not considered.
 * @param petDetailsList
 */
function updatePetBdayCookie(petDetailsList) {
    var petDetails;

   if (petDetailsList.length > 1) {
        petDetailsList.sort(function(a, b){return a.split(':')[0] - b.split(':')[0]});
    }
    console.log("PetDetails available for bday : " + petDetailsList[0]);
    var flag = 0;
    for (var i=0; i<petDetailsList.length; i++) {
        var dateDiff = petDetailsList[i].split(':')[0];
        
        petDetails = petDetailsList[i].split(':')[1];
        
        // Birthday message should be priority of dog over cat in case of both pet birthdays are in the same month
        if (petDetails.endsWith('|Dog')) {    // if record is Dog, select and skip remaining
            TSCUtils.setCookie("birthdayPetName", petDetails, {path:'/'});
            console.log("Cookie 'birthdayPetName' set with value : " + petDetails);
            flag = 1;
            break;
        }
    }
    
    // the cookie with value as <petName>|<animalType>
    if(flag==0){
        petDetails = petDetailsList[0].split(':')[1];
        TSCUtils.setCookie("birthdayPetName", petDetails, {path:'/'});
        console.log("Cookie 'birthdayPetName' set with value : " + petDetails);
    }
}

function showProximityPromotionModal (eSpotName){
	if(undefined == eSpotName || "" == eSpotName){
		return;
	}		
	var espotParams = "";
	$("div#proximityPromotionModal div#modalContent").text("");
	var requestURL = location.protocol + "//" + location.host + "/wcs/resources/store/10151/espot/" + eSpotName + espotParams;
	$.ajax({
	    url : requestURL,
	    type : 'GET',
	    cache: false,
	    dataType:'json',
	    success : function(data) { 
	    	try {
	    		$("div#proximityPromotionModal div#modalContent").html(data.MarketingSpotData[0].baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText);
			} catch(e){
		    	console.log("error occured in proximity promotion modal ::success---> "+e);				
			}
			$('#proximityPromotionModal').modal();
	    },
	    error : function(request,error) {
	    	try {
	    		var errorResponse = jQuery.parseJSON(request.responseText);
		    	if(errorResponse.errors[0].errorCode == 'CWXFR0223E'){
		    		window.location.reload();
	    		}
	    	} catch(e){
		    	console.log("error occured in proximity promotion modal ::failure---> "+e);	
			}
	    }
	});
	
}
/** method to load React Fragment*/
function loadReactFragment(id, path){
	$.ajax({
		url: path,
		method: 'GET',
		success : function(data) {
			var ajaxResponse = JSON.parse(data);
			var htmlContent = '<script id="footer-content" type="application/json">'+JSON.stringify(ajaxResponse.footerJSON)+'</script>'+ajaxResponse.html; 
			$('#'+id).html(htmlContent);
		},
		error : function(request,error) {
			console.log('Error loading footer :'+error);
		}
	});
}


/** method to convert a string into titlecase*/
function toTitleCase(str) {
	  return str.replace(
	    /\w*/g,
	    function(txt) {
	      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	    }
	  );
}


/** method to Track various events associated with Save or apply GiftCard to Profile from My Profile page and from Payment Page*/
function setGiftCardToProfileEvents(balance, action){	
	try {
		var EventName = " | your saved payments";
		var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
		if(pageType == "Payment"){
			 EventName = " | checkout payment";
		}
		var dataObject = {};
		if(action == "save"){
			var event = "save a gift card" + EventName;
			if(pageType == "Payment"){
				event = "apply and store gift card" + EventName;
			}
			var currProfileBal = $("#currProfileGCBalance").length > 0 ? parseFloat($("#currProfileGCBalance").val()) : 0.0;
			var cumulativeProfileBal = parseFloat(balance);
			var addedGCAmount = parseFloat(cumulativeProfileBal - currProfileBal);
			$("#currProfileGCBalance").val(cumulativeProfileBal);
			dataObject["event"] = event;
			dataObject["giftCardAmount"] = addedGCAmount;
			dataObject["giftCardBalance"] = cumulativeProfileBal;			
		}
		else if(action == "edit"){
			var event = "edit and apply gift card" + EventName;
			var currProfileBal = $("#currentBal").length > 0 ? parseFloat($("#currentBal").html().replace('$','')) : 0.0;
			var appliedGcAmount = $("#appliedAmount").length > 0 ? parseFloat($("#appliedAmount").html().replace('$','')) : 0.0;
			dataObject["event"] = event;
			dataObject["appliedGiftCardAmount"] = appliedGcAmount;
			dataObject["giftCardBalance"] = currProfileBal;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(
				dataObject		  
		); 
		console.debug("Adobe setGiftCardToProfileEvents Event :: "+JSON.stringify(dataLayer));		
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}		
}
//This gets the category tree on category and PDP page. 
function getCatHierarcy() {
	var counter =0;
	var catList= '';
	if(typeof extendedBreadCrumbJSON !== 'undefined'){
		extendedBreadCrumbJSON[0].forEach(function(node){
			if(counter++ > 0) {
				catList+= '>'
			}
			catList+= node.categoryName;
		});
	}	
	return catList;
}

function getCriteoFilter(dataNode){
	var filterStr = "[(";
	var valueCount = 0;
	if(typeof dataNode.page.pageInfo.applied_filters === 'undefined') return "";
	JSON.parse(dataNode.page.pageInfo.applied_filters).forEach(function(node) {
		filterStr +='"'+node.name+'", "eq", "';
		valueCount = 0;
		node.refinements.forEach(function(refinement){
			if(valueCount ++ >0 ) {
				filterStr += ",";
			}
			filterStr += refinement.value;
		})
		filterStr =+ '"';
	})
	filterStr = ")]";
	return filterStr;
}

/*
 * This method Show or Hide the Card Expiry Info Fields based on the Card Type
 */
function toggleExpiryInfoFields(action) {
	  //Feature Flag Name : EnableToggleExpiryFields
	  var isToggleFeatureEnabled = $("#isEnabledToggleExpiryFields").length > 0 ? $("#isEnabledToggleExpiryFields").val() : false;
	  if(isToggleFeatureEnabled.toString() == "true"){
		  if(action == 'show'){
			  $("#expMonthDiv").removeClass('hide');
		      $("#expYearDiv").removeClass('hide');
		      $("#expiryDateDiv").removeClass('hide');
		  }
		  else{
			  $("#expMonthDiv").addClass('hide');
		      $("#expYearDiv").addClass('hide'); 
		      $("#expiryDateDiv").addClass('hide'); 
		  } 
	  }    
}

function deletePersonalizationCookies() {
    //removing the loyalty cookie on log out
    TSCUtils.removeCookie("loyaltyDetails",{path:"/"});
    //removing the Pet birthday messaging cookie
    TSCUtils.removeCookie("birthdayPetName",{path:"/"});
    //removing the Pet exists cookie
    TSCUtils.removeCookie("petExists",{path:"/"});
    //removing pet subscription cookie
    TSCUtils.removeCookie("petSubscription",{path:"/"});
    //removing NCAPIInvoked cookie
    TSCUtils.removeCookie("isNCAPIInvoked",{path:"/"});
}

function heroVerifiedStatus() {
	var isHeroDualVerificationEnabled = $("#isHeroDualVerificationEnabled").length ? $("#isHeroDualVerificationEnabled").val() : 'false';
	var isHeroBenefitFeatureEnabled = $("#isHeroBenefitFeatureEnabled").val();
	if(isHeroBenefitFeatureEnabled == 'true'){ 
		if(isHeroDualVerificationEnabled == 'true'){ 
			var loyaltyDetails = TSCUtils.getCookie("loyaltyDetails");	
			//var loyaltyDetails = "E|1|2|M_Y_2027-07-20|R_N_2027-07-20";
			if(loyaltyDetails) {
				var loyaltyDetailsValues = loyaltyDetails.split('|');
				var militaryCookieValue = "";
				var militaryExpiredDate = "";
				var responderCookieValue = "";
				var responderExpiredDate = "";
				if(loyaltyDetailsValues.length > 3){
					var firstCookie = loyaltyDetailsValues[3];
					if(firstCookie !== "null" && typeof firstCookie != 'undefined' && firstCookie.trim() !== ""){
						var loyaltyfirstCookies =  firstCookie.split('_');
						var firstCookieValue = loyaltyfirstCookies[0];
						if("M" == firstCookieValue){
							militaryCookieValue = loyaltyfirstCookies[1];
							militaryExpiredDate = loyaltyfirstCookies[2];
						} else if("R" == firstCookieValue){
							responderCookieValue = loyaltyfirstCookies[1];
							responderExpiredDate = loyaltyfirstCookies[2];
						}
					}
				}
				if(loyaltyDetailsValues.length > 4){
					var secondCookie = loyaltyDetailsValues[4];
					if(secondCookie !== "null" && typeof secondCookie != 'undefined' && secondCookie.trim() !== ""){
						var loyaltysecondCookies =  secondCookie.split('_');
						var secondCookieValue = loyaltysecondCookies[0];
						if("M" == secondCookieValue){
							militaryCookieValue = loyaltysecondCookies[1];
							militaryExpiredDate = loyaltysecondCookies[2];
						} else if("R" == secondCookieValue){
							responderCookieValue = loyaltysecondCookies[1];
							responderExpiredDate = loyaltysecondCookies[2];
						}
					}
				}
				if(typeof militaryCookieValue != 'undefined' && militaryCookieValue.trim() != ""
					&& typeof responderCookieValue != 'undefined' && responderCookieValue.trim() != ""){
					if(militaryCookieValue == "Y" && responderCookieValue == "Y"){
						// my profile
		   				if($("#heroDualVerifiedMilitaryMsg").length){
		   					$("#heroDualVerifiedMilitaryMsg").addClass('hide');
		   					$("#heroDualVerifiedResponderMsg").addClass('hide');
			   				$("#heroDualExpiredResponderMsg").addClass('hide');
			   				$("#heroDualExpiredMilitaryMsg").addClass('hide');
			   				$("#white_verify_button").addClass('hide');
			   				$("#dualVerifyMsg").removeClass('hide');
			   				$("#heroBothVerifiedMsg").removeClass('hide');
			   				showHideExpiryDate(militaryExpiredDate,$("#bothMilitaryExpires"),'Military Expires ');
			   				showHideExpiryDate(responderExpiredDate,$("#bothResponderExpires"),'First Responder Expires ');
		   				}
		   				// NC page
		   				if($("#heroVerifiedSuccessResponder").length){
		   					$("#heroVerifiedSuccessResponder").addClass('hide');
		   					$("#heroVerifiedSuccessMilitary").addClass('hide');
		   					$("#heroDualVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessBoth").removeClass('hide');
		   					
		   					$("#heroDualVerifiedSuccessMsg").removeClass('hide');
		   					$("#dualVerifyTile").addClass('hide');
		   				}
					} else if (militaryCookieValue == "Y" && responderCookieValue == "N"){
						updateWhiteButtonAttributes('responder');
						// my profile
		   				if($("#heroDualExpiredResponderMsg").length){
		   					$("#dualVerifyExpiredMsg").addClass('hide');
			   				$("#dualVerifyMsg").removeClass('hide');
			   				$("#heroDualExpiredResponderMsg").removeClass('hide');
			   				showHideExpiryDate(militaryExpiredDate,$("#dualMilitaryExpires"),'Expires ');
			   				$("#heroDualVerifiedResponderMsg").addClass('hide');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessMilitary").removeClass('hide');
		   					
		   					$("#heroDualVerifiedFail").addClass('hide');
		   					$("#heroDualVerifiedSuccessMsg").removeClass('hide');
		   					$("#dualVerifyTile").removeClass('hide');
		   					$("#heroDualExpiredResponder").removeClass('hide');
		   					$("#heroDualVerifiedMilitary").addClass('hide');
		   				}
					} else if (militaryCookieValue == "N" && responderCookieValue == "Y"){
						updateWhiteButtonAttributes('military');
						// my profile
		   				if($("#heroDualExpiredMilitaryMsg").length){
		   					$("#dualVerifyExpiredMsg").addClass('hide');
			   				$("#dualVerifyMsg").removeClass('hide');
			   				$("#heroDualExpiredMilitaryMsg").removeClass('hide');
			   				showHideExpiryDate(responderExpiredDate,$("#dualResponderExpires"),'Expires ');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessResponder").removeClass('hide');
		   					
		   					$("#heroDualVerifiedFail").addClass('hide');
		   					$("#heroDualVerifiedSuccessMsg").removeClass('hide');
		   					$("#dualVerifyTile").removeClass('hide');
		   					$("#heroDualExpiredMilitary").removeClass('hide');
		   					$("#heroDualVerifiedMilitary").addClass('hide');
		   				}
					} else {
						// my profile
		   				if($("#heroDualExpiredMsg").length){
		   					$("#dualVerifyExpiredMsg").removeClass('hide');
			   				$("#heroDualExpiredMsg").removeClass('hide');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").addClass('hide');
		   					
		   					$("#heroDualVerifiedFail").removeClass('hide');
		   					$("#hero_bothExpired").removeClass('hide');
		   				}
					}
				} else if(typeof militaryCookieValue != 'undefined' && militaryCookieValue.trim() != ""){
					if (militaryCookieValue == "Y"){
						updateWhiteButtonAttributes('responder');
						// my profile
		   				if($("#heroDualVerifiedMilitaryMsg").length){
		   					$("#dualVerifyExpiredMsg").addClass('hide');
		   					$("#heroDualVerifiedFail").addClass('hide');
			   				$("#dualVerifyMsg").removeClass('hide');
			   				$("#heroDualVerifiedMilitaryMsg").removeClass('hide');
			   				showHideExpiryDate(militaryExpiredDate,$("#militaryExpires"),'Expires ');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessMilitary").removeClass('hide');
		   					
		   					$("#heroDualVerifiedFail").addClass('hide');
		   					$("#heroDualVerifiedSuccessMsg").removeClass('hide');
		   					$("#dualVerifyTile").removeClass('hide');
		   					$("#heroDualVerifiedMilitary").removeClass('hide');
		   					$("#heroDualVerifiedResponder").addClass('hide');
		   				}
					} else {
						// my profile
		   				if($("#heroSingleExpiredMilitaryMsg").length){
		   					$("#dualVerifyExpiredMsg").removeClass('hide');
			   				$("#heroSingleExpiredMilitaryMsg").removeClass('hide');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").addClass('hide');
		   					
		   					$("#heroDualVerifiedFail").removeClass('hide');
		   					$("#hero_bothExpired").removeClass('hide');
		   				}
					}
				} else if(typeof responderCookieValue != 'undefined' && responderCookieValue.trim() != ""){
					if (responderCookieValue == "Y"){
						updateWhiteButtonAttributes('military');
						// my profile
		   				if($("#heroDualVerifiedMilitaryMsg").length){
		   					$("#dualVerifyExpiredMsg").addClass('hide');
		   					$("#heroDualVerifiedFail").addClass('hide');
			   				$("#dualVerifyMsg").removeClass('hide');
			   				$("#heroDualVerifiedResponderMsg").removeClass('hide');
			   				showHideExpiryDate(responderExpiredDate,$("#responderExpires"),'Expires ');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessResponder").removeClass('hide');
		   					
		   					$("#heroDualVerifiedFail").addClass('hide');
		   					$("#heroDualVerifiedSuccessMsg").removeClass('hide');
		   					$("#dualVerifyTile").removeClass('hide');
		   					$("#heroDualVerifiedMilitary").addClass('hide');
		   					$("#heroDualVerifiedResponder").removeClass('hide');
		   				}
					} else {
						// my profile
		   				if($("#heroSingleExpiredResponderMsg").length){
		   					$("#dualVerifyExpiredMsg").removeClass('hide');
			   				$("#heroSingleExpiredResponderMsg").removeClass('hide');
		   				}
		   				// NC page
		   				if($("#heroDualVerifiedSuccess").length){
		   					$("#heroDualVerifiedSuccess").addClass('hide');
		   					
		   					$("#heroDualVerifiedFail").removeClass('hide');
		   					$("#hero_bothExpired").removeClass('hide');
		   				}
					}
				} else {
	   				if($("#heroDualVerifiedFail").length){
	   					$("#heroDualVerifiedFail").removeClass('hide');
	   				}
	   				if($("#hero_noActive").length){
	   					$("#hero_noActive").removeClass('hide');
	   				}
				}
			} else {
				if($("#hometownHero").length){
					$("#hometownHero").addClass('hide');
				}
			}	
		} else {
			var loyaltyDetails = TSCUtils.getCookie("loyaltyDetails");
			if(loyaltyDetails) {
				var loyaltyDetailsValues =  loyaltyDetails.split('|');
				var heroStatus = loyaltyDetailsValues[3];
		   		var expiredDate = loyaltyDetailsValues[4];
		   		var hyphenExist = hyphenCheck(expiredDate);

		   		if((expiredDate !== "null" && typeof expiredDate != 'undefined' && expiredDate.trim() !== "") && (isMilitaryExpirationFlag || isResponderExpirationFlag ) && heroStatus !=null && loyaltyDetailsValues.length > 3){ 
		   			var currentDate = new Date();
		   			var year = currentDate.getFullYear();
		   			var month = currentDate.getMonth()+1;
		   			if(month <10){
		   				 month = "0"+month;
				   			}
		   			var day = currentDate.getDate();
		   			if(day <10){
		   					day = "0"+day;
		   				}
		   			var formattedCurrentDate = year+month+day;
		   			if (hyphenExist == true) {
						var yearExp = expiredDate.substring(0, 4);
						var monthExp = expiredDate.substring(5, 7);
						var dayExp = expiredDate.substring(8, 10);
					} else {
						var yearExp = expiredDate.substring(0, 4);
						var monthExp = expiredDate.substring(4, 6);
						var dayExp = expiredDate.substring(6, 8);
					}
		   			var formattedExperryMonth = getMonthName(monthExp);
		   			var formattedDayExpiry = formattedExperryMonth +' '+dayExp+ ','+' '+yearExp;
		   			var expiry =MessageHelper.messages["HERO_EXP"];
		   			if(heroStatus == "true" && expiredDate < formattedCurrentDate){	
		   					$("#hometownHero").removeClass('hide');
		   					$("#heroVerifiedFail").removeClass('hide');
		   					$("#heroVerifiedSuccess").addClass('hide');
		   					$("#heroVerifiedSuccessMsg").addClass('hide');
		   				}	
		   			else if(heroStatus == "true" && expiredDate > formattedCurrentDate){
		   					$("#hometownHero").removeClass('hide');
		   					$("#heroVerifiedFail").addClass('hide');
		   				    $("#heroVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessMsg").removeClass('hide');							
		   					$("#heroExpires").removeClass('hide');
		   					$("#heroExpires")[0].innerHTML = expiry + formattedDayExpiry;																		
		   				}	
		   			else if(heroStatus == "false") {
		   				   $("#hometownHero").removeClass('hide');
		   			       $("#heroVerifiedFail").removeClass('hide');
		   				   $("#heroVerifiedSuccess").addClass('hide');
		   				   $("#heroVerifiedSuccessMsg").addClass('hide');
		   				}
		   		}
		   		else if ((expiredDate == "null" || typeof expiredDate === 'undefined' || expiredDate.trim() == "") && heroStatus != null && loyaltyDetailsValues.length > 3){
		   			if(heroStatus == "true"){
		   					$("#hometownHero").removeClass('hide');
		   					$("#heroVerifiedFail").addClass('hide');
		   					$("#heroVerifiedSuccess").removeClass('hide');
		   					$("#heroVerifiedSuccessMsg").removeClass('hide');
		   				 }
		   			else {
		   					$("#hometownHero").removeClass('hide');
		   					$("#heroVerifiedFail").removeClass('hide');
		   					$("#heroVerifiedSuccess").addClass('hide');
		   					$("#heroVerifiedSuccessMsg").addClass('hide');
		   				 }
		   	   }
		   	else{
		   					$("#hometownHero").removeClass('hide');
		   					$("#heroVerifiedFail").removeClass('hide');
		   					$("#heroVerifiedSuccess").addClass('hide');
		   					$("#heroVerifiedSuccessMsg").addClass('hide');
		   		}
		   				
		   				
		}	
	}
		}	
	}
function getFormattedExpiryDate(expiredDate) {
	var yearExp = expiredDate.substring(0, 4);
	var monthExp = expiredDate.substring(5, 7);
	var dayExp = expiredDate.substring(8, 10);
	var formattedExperryMonth = getMonthName(monthExp);
	return formattedExperryMonth +' '+dayExp+ ','+' '+yearExp;
}

function showHideExpiryDate(expiryDate, expiryElement, expiryText){
	if(expiryDate != "null" && typeof expiryDate != 'undefined' && expiryDate != ""){
		expiryElement.html(expiryText + getFormattedExpiryDate(expiryDate));
		expiryElement.removeClass('hide');
	} else {
		expiryElement.addClass('hide');
	}
}

function updateWhiteButtonAttributes(group){
	if($('#white_verify_button').length){
		var onclickVal = $('#white_verify_button').attr('onClick');
		onclickVal = onclickVal.replace('PARAM_DATA_SCOPE',group);
		$('#white_verify_button').attr('onClick', onclickVal);
		$('#white_verify_button').attr('data-analytics-link', group+"|verify with ID.me");
	}
}

function hyphenCheck(expiredDate) {
	regexp = /-/;
	if (regexp.test(expiredDate)) {
		return true;
	} else {
		return false;
	}
}

function showHBStatusModal(pageName) {
	/*
	 React modal will post message as below with status from verify api response 
	 	window.opener.postMessage({"hbVerificationStatus":status}, "*");
	 */
	var isHeroBenefitFeatureEnabled = $("#isHeroBenefitFeatureEnabled").length ? $("#isHeroBenefitFeatureEnabled").val() : 'false';
	var isHeroDualVerificationEnabled = $("#isHeroDualVerificationEnabled").length ? $("#isHeroDualVerificationEnabled").val() : 'false';
	if(isHeroBenefitFeatureEnabled == 'true'){
		if(document.querySelector(".idme-trigger-link.idme-unify-button") != null && ('neighbors club success' == pageName || isHeroDualVerificationEnabled != 'true')){
			var button = document.querySelector(".idme-trigger-link.idme-unify-button");
			button.setAttribute('data-analytics-link', 'verify with ID.me');
			button.setAttribute('data-activitytitle', pageName);
		}
		var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
		var eventer = window[eventMethod];
		var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

		eventer(messageEvent,function(e) {
			var data = e.data; 
			if(e.origin != location.origin || 
					(typeof data === 'undefined' || (typeof data !== 'undefined' && !data.hasOwnProperty("hbVerificationStatus")))){ 
				return;
			}
			console.log('received message from react: '+ data.hbVerificationStatus);
			var status = data.hbVerificationStatus;
			window.dataLayer = window.dataLayer || [];
			if(status == 'success'){
                $('#heroBenefitSuccessModal').modal('show'); 
                heroVerifiedStatus();
				window.dataLayer.push({
                  event: "hero benefits verify success",
                });
			} else if (status == 'UUID_LINKED_WITH_ANOTHER_ACCOUNT'){
				$('#heroBenefitExistingUserErrorModal').modal('show');
				window.dataLayer.push({ 
					event: "error message",
					errorDetails:{
						errorMessage: "this id.me account is already associated with another TSC account",
						errorType: "hero benefits error"
					}
				}); 
			} else if (status == 'ACTIVE_UUID_EXIST'){
				$('#heroBenefitUuidErrorModal').modal('show');
				window.dataLayer.push({ 
					event: "error message",
					errorDetails:{
						errorMessage: "this TSC account is already associated with another id.me account",
						errorType: "hero benefits error"
					}
				}); 
			} else if (status == 'NOT_AUTHORIZED_FOR_QUERY' || status == 'ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWED'){
				$('#heroBenefitSessionExpiryModal').modal('show');
				window.dataLayer.push({ 
					event: "error message",
					errorDetails:{
						errorMessage: "session is expired",
						errorType: "hero benefits error"
					}
				}); 
			} else {
				$('#heroBenefitGenericErrorModal').modal('show');
				window.dataLayer.push({ 
					event: "error message",
					errorDetails:{
						errorMessage: "something went wrong",
						errorType: "hero benefits error"
					}
				}); 
			}
		}, false);
	}
	
}

function getMonthName(monthExp) { 
	var date = new Date();   
	date.setMonth(monthExp - 1);   
	return date.toLocaleString('en-US', { month: 'short' }); 
}
function redirectToSignInPage(redirection){
	var redirectionView = "TSCShoppingCartView";
	var currURL = document.location.href;
	if(undefined != redirection && "" != redirection){
		redirectionView = redirection;
	}
	else{
		if(currURL.includes("TSCSinglePageCheckoutView")){
			redirectionView = "TSCSinglePageCheckoutView";
		}
		else if(currURL.includes("TSCDeliveryPickupDetailsView")){
			redirectionView = "TSCDeliveryPickupDetailsView";
		}
		else if(currURL.includes("TSCPaymentPageView")){
			redirectionView = "TSCPaymentPageView";
		}
	}
	var redirectionURL = "LogonForm?URL=/"+redirectionView+"?storeId="+WCParamJS.storeId+"&langId="+WCParamJS.langId+"&catalogId="+WCParamJS.catalogId+"&myAcctMain=1";
	document.location.href=getAbsoluteURL() + redirectionURL ;
}

function setResetPasswordEvent(){
	try{
		window.dataLayer = window.dataLayer || []; 
		window.dataLayer.push({ 
		  event: 'forget password',
		  loginDetails:{
			  resetPassword: "signin page|sign-in|reset password"
		  }
		}); 
		console.debug("Adobe reset password Event :: "+JSON.stringify(dataLayer));
	} catch (e){
		console.log('Exception in handling Adobe Analytics :: '+e.stack);
	}
}

$(document).ready(function(){
	window.dataLayer = window.dataLayer || [];
	for(i=0; i<window.dataLayer.length; i++ ) {
		if(typeof window.dataLayer[i].event != 'undefined' && window.dataLayer[i].event == "page view") {
			switch(window.dataLayer[i].page.category.pageTemplType){
			case 'search results':
				window.dataLayer[i].page.pageInfo["criteoPageNumber"] = TSCUtils.getParameterByName('beginIndex')/$('#pageSize').val() +1;
				window.dataLayer[i].page.pageInfo["criteoFilter"] = getCriteoFilter(window.dataLayer[i]);
				break;
			case 'product listings' :
				window.dataLayer[i].page.pageInfo["criteoPageNumber"] = TSCUtils.getParameterByName('beginIndex')/$('#pageSize').val() +1;
				window.dataLayer[i].page.pageInfo["criteoFilter"] = getCriteoFilter(window.dataLayer[i]);	
				window.dataLayer[i].page.pageInfo["criteoCatHierarchy"] = getCatHierarcy();
				break;
			case 'product details' :
				window.dataLayer[i].page.pageInfo["criteoCatHierarchy"] = getCatHierarcy();
				break;
			default: break;

			};
		}
	}
});

function showAEMContentFragment (fragmentUrl, contentDivId,requestParam){
	if(undefined == fragmentUrl || "" == fragmentUrl || undefined == contentDivId || "" == contentDivId){
		return;
	}		
	var params = "";
	if(undefined != requestParam && "" != requestParam) {
		params = encodeURI(requestParam);
	}
	
	var requestURL = location.protocol + "//" + location.host+"/" + fragmentUrl + params;
	//requestURL = "https://q3.shop.tractorsupply.com/" + fragmentUrl + params;
	$.ajax({
	    url : requestURL,
	    type : 'GET',
	    cache: false,
	    dataType:'json',
	    success : function(response) { 
			cursor_clear(true);
			var htmlContent = "";
	    	try {
				if(null !=response.data && "" != response.data){
					var data = response.data;
					var items = data.modelList.items;
					if(items.length>0){
						var text = items[0].text;
						htmlContent = text.html;
					}
				}
			} catch(e){
		    	console.log("response data error for "+fragmentUrl+" : "+e)				
			}
			$("#"+contentDivId).html(htmlContent);
	    },
	    error : function(request,error) {
	    	cursor_clear(true); 	
		    	console.log("Aem content url "+fragmentUrl+" unavailable with error: "+error)	
	    }
	});
	
}


/**
 * React will trigger function window.updateGlobalZipcodeInCart(); 
 * from their end  when Global Ship is updated from header .
 * From WCS end cart page will be refreshed through refresh handler .
 * 
 */

window.updateGlobalShipZip = function(newZipCode) {
    var event = new CustomEvent('globalShipZip', {
        detail: { zipcode: newZipCode }
    });
    // Dispatch the event
    if($("#isEnabledGlobalDeliveryZipcode").val() == "true"  && $('#isShoppingCart').val() == "true"){
    window.dispatchEvent(event);
    }
};

window.addEventListener('globalShipZip', function(event) {
    // Global Ship Zipcode received from React on updation of header 
    var globalZipcode = event.detail.zipcode;
    console.log('The global zipcode received from React:', +globalZipcode);
    wcRenderContext.updateRenderContext('ShopCartOrderDetailsAndSummaryDisplayContext',{});
});


/**
 * Triggering Analytics whenever the global ship zip changes  
 * 
 */
function setGbShipZipAnalyticsEvent(page){
	try {
		
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		event: 'deliver to update',
		details : {
		location: page
		}
		}); 

	} catch (e){
		console.log('Exception in handling Adobe Analytics for Global ship zip update:: '+e.stack);
	} 
}

